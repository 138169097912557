<template >
  <div v-if="!commonInfo" class="index-loading"></div>

  <transition v-else>
    <router-view :key="$route.fullPath"></router-view>
  </transition>

  <div class="no-access" v-show="timeZone === -8"><p>Not available in your area!</p></div>

  <nut-drag 
    v-show="firstRechargeAvailable"
    @rechargeBtn="rechargeBtnShow"
    direction="y" 
    class="promotions-link" 
    :style="{ top: '200px', right: '8px' }"
  >
    <div class="btn" @click="toFirstRechargeBonus()">
      <nut-countdown :endTime="firstRechargeDeadline" millisecond format="HH:mm:ss"/>
    </div>
  </nut-drag>


    <!--**************************************** 活动弹框 ****************************************-->
    <!-- <nut-popup pop-class="pop-ab" v-model:visible="abPopup">
    <div class="bd" style="background: none !important;">

      <nut-swiper :init-page="1" :pagination-visible="true"  pagination-color="#426543" auto-play="6000" :is-preventDefault="false">
        <nut-swiper-item  @click="closePopup">
          <img  :src="require('./assets/images/bg904.png')" />
        </nut-swiper-item>
      </nut-swiper>

    </div>
   
  </nut-popup> -->


</template>
<script>
import {reactive, toRefs, onMounted, onBeforeMount} from "vue";
import {useRouter} from "vue-router";
import axios from "axios";
import {getDebugMode, getCountry, demoRestrictionsDate} from "@/assets/js/environment";
import router from './router/index.js';
import {isBlank} from "@/assets/js/commonUtil";
import {getCookie, setCookie} from "@/assets/js/cookieUtil";

export default {
  name: "App",
  data() {
    return {
      commonInfo: null,
      noAccess: false,
      timeZone: '',
      navigatorLanguage: '',
      count: 0, 
      showNotice: false,
      firstRechargeAvailable: false,
      firstRechargeDeadline: null,
      abPopup: false,
    };
  },
  methods: {
    rechargeBtnShow(){

    },
    check() {
      function doCheck(a) {
        (function () {
        }["constructor"]("debugger")());
        doCheck(++a);
      }
      try {
        doCheck(0)
      } catch (err) {
        console.log(err)
      }
    },
    timeZoneStint() {
      let debugMode = getDebugMode();
      if (debugMode === 'false') {
        this.timeZone = new Date().getTimezoneOffset() / 60
        this.navigatorLanguage = navigator.language
        if (
            this.timeZone === -8 ||
            this.navigatorLanguage.indexOf('cn') >= 0 ||
            this.navigatorLanguage.indexOf('CN') >= 0 ||
            this.navigatorLanguage.indexOf('zh') >= 0 ||
            this.navigatorLanguage.indexOf('ZH') >= 0
        ) {
          this.noAccess = true
          this.check()
        }
      }
    },
    toFirstRechargeBonus(){
      router.push('/firstRechargeBonus');
    },
    closePopup(){
      this.abPopup = false; 
      const d = new Date()
      const t = d.getTime() + (1000 * 60 * 60 * 24)
      setCookie('DIWALI_20240321', 'close', t);
    }
  },
  mounted() {
    const d = new Date()
    const startShow = ((d.getMonth() +1) === 3) && (d.getDate() >= 21) && (d.getDate() <= 24)
    if(isBlank(getCookie('DIWALI_20240321')) && startShow){
      this.abPopup = false
    }else{
      this.abPopup = false
    }
  },
  created() {
    //记录来源
    if(document.referrer){
      localStorage.setItem("SOURCE_REFERRER",document.referrer);
    }

    // if (getCountry() === 'in') {
    //   this.showNotice = true
    // }
    this.timeZoneStint()
    demoRestrictionsDate()
    axios({
      url: "/api/system/common",
      method: "get",
    }).then((res) => {
      localStorage.setItem("commonInfo",JSON.stringify(res.data.data));
      this.commonInfo = res.data.data
      if(router.currentRoute.value.name === 'index'){
        if(this.commonInfo.pureMode && localStorage.getItem('token')  === null){
          router.push({ path: '/inviteCoupon' })
        }else{
          //router.push({ path: '/'+ this.commonInfo.promoteGame })
          router.push({ path: '/parity' })
        }
      }
      /* 首充 */
      this.firstRechargeAvailable = this.commonInfo.firstRechargeAvailable && getCountry() === 'br' && document.querySelector('body').getAttribute('s') === 's2'
      const registerTime = new Date(this.commonInfo.registerTime).getTime()
      if(this.firstRechargeAvailable){
        this.firstRechargeDeadline = Date.now() + ( (registerTime + (3600 * 1000 * (this.commonInfo.firstRechargeDuration / 60))) - new Date().getTime());
      }
    });
  },
  beforeCreate() {
    // this.count = 0;
    // let imgs = [
    //   require('./assets/images/fjbz.png'),
    // ]
    // for (let img of imgs) {
    //   let image = new Image();
    //   image.onload = () => {
    //     this.count++;
    //   };
    //   image.src = img;
    // }
  },

  setup() {
    const state = reactive({
      param: [],
    });
    onBeforeMount(() => {

      if (window.location.href.split("?")[1]) {
        let url = window.location.href.split("?")[1];
        let arr = url.split("&");
        for (var i = 0; i < arr.length; i++) {
          state.param[arr[i].split("=")[0]] = arr[i].split("=")[1];
        }
        console.log(state.param.invite)
        //邀请码
        if (state.param.invite) {
          localStorage.setItem("invite", state.param.invite);
        }
        //红包码
        if (state.param.c) {
          localStorage.setItem("shareBonusCode", state.param.c);
          const router = useRouter();
          router.push({
            name: "receiveBonus",
          });
        }
        //我的红包码
        if (state.param.s) {
          localStorage.setItem("shareBonusReceiveCode", state.param.s);
        }
      }
    });
    onMounted(() => {
      window.onbeforeunload = function () {
        var storage = window.localStorage;
        storage.removeItem("invite");
        storage.removeItem("shareBonusCode");
        storage.removeItem("downloadApp");
      };
    });
    return {
      ...toRefs(state),
    };
  },
};
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>



<style scoped>
.Skright-enter-active,
.Skright-leave-active,
.Skleft-enter-active,
.Sklef-leave-active {
  transition: all 600ms;
  transition: 0.5s;
  position: absolute;
  top: 0;
}

.Skright-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.Skright-leave-to {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.Skleft-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.Skleft-leave-to {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.no-access {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30000;
  background: #000;
}

.no-access p {
  text-align: center;
  color: #fff;
  padding-top: 300px;
  font-size: 20px;
}
.app-notice{ position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: url(./assets/images/bg66.png); z-index: 2000; }
.app-notice .app-notice-c{ position: absolute; left:calc(50% -  150px); top: calc(50% -  100px); border-radius:8px;
  color: #fff; width: 300px;  background: #000; }
.app-notice .app-notice-b{ padding: 10px;font-size: 13px;min-height: 150px;}
.app-notice .app-notice-b h4{ font-size: 16px; margin: 10px 0;}
.app-notice-f{ text-align: right; padding: 10px;}
.app-notice-f .btn-t{ margin-right: 5px; border: 0; background: #ffe477; color: #9261b5;  border-radius: 10px;font-size: 10px; 
  padding: 0px 20px; line-height: 20px; opacity: .8;  height: 20px;overflow: hidden; display: inline-block; }
</style>



