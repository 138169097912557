<template>
  <nut-navbar
    :left-show="false"
    :title="$t('AGENT_SUPPORT_PLAN.H1')"
    fixed=true
    class="mb0">
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>

  <h2 class="h2">
    <img :src="require('../assets/images/' + imgUrl + 'bg_p125.jpg')" />
  </h2>
  <div class="plan-main">
    <div class="p1">
      <h3><strong>{{$t('AGENT_SUPPORT_PLAN.MY_SUPPORT_PLAN')}}</strong></h3>
      <ul>
        <li>
          <div>
            <nut-cell>
              <nut-circleprogress :progress="levelPercent" :color="gradientColor" strokeWidth="10">Lv:{{ level }}</nut-circleprogress>
            </nut-cell>
          </div>
          <p>{{$t('AGENT_SUPPORT_PLAN.MY_SUPPORT_PLAN_LEVEL')}}</p>
        </li>
        <li>
          <div>
            <nut-cell>
              <nut-circleprogress :progress="todayEffectiveCountPercent" :color="gradientColor" strokeWidth="10">{{ todayEffectiveCount }}/{{ nextLevelConfig.effectiveCount }}</nut-circleprogress>
            </nut-cell>
          </div>
          <p>{{$t('AGENT_SUPPORT_PLAN.TODAY_EFFECIVE_USERS_AMOUNT')}}</p>
        </li>
        <li>
          <div>
            <nut-cell>
              <nut-circleprogress :progress="todayAffinity1SubBetCommissionPercent" :color="gradientColor" strokeWidth="10">
                <span class="f-12">{{ todayAffinity1SubBetCommission}}</span>
                <div class="br"></div>
                <span class="f-12">{{ nextLevelConfig.affinity1SubBetCommission}}</span>
              </nut-circleprogress>
            </nut-cell>
          </div>
          <p>{{$t('AGENT_SUPPORT_PLAN.TODAY_POINTS_BONUS')}}</p>
        </li>
        <li>
          <div>
            <nut-cell>
              <nut-circleprogress :progress="yesterdayRewardAmountPercent" :color="gradientColor" strokeWidth="10">{{ $filters.currencySymbol(yesterdayRewardAmount) }}</nut-circleprogress>
            </nut-cell>
          </div>
          <p>{{$t('AGENT_SUPPORT_PLAN.YESTERDAY_SUPPORT_REWARD')}}</p>
        </li>
      </ul>
      <div :class="yesterdayRewardAmount > 0 ? 'lk' : 'lk lk-d'" @click="clickRewardAmount">
        {{$t('AGENT_SUPPORT_PLAN.BTN_TEXT')}}
      </div>
    </div>

    <div class="tx-box">
      <p>{{$t('AGENT_SUPPORT_PLAN.INFO_TEXT')}}</p>
    </div>
    
    <span class="f-r">{{ getTodayDate() }}<br/><span>Data updated daily</span></span>
    <h3 class="title">{{$t('AGENT_SUPPORT_PLAN.AGENT_REWAED_TOP')}}</h3>
    <div class="p3">
      <h4><strong>{{$t('AGENT_SUPPORT_PLAN.LEVEL')}} 3</strong></h4>
      
      <table>
        <tr>
          <th>{{$t('AGENT_SUPPORT_PLAN.PHONE')}}</th>
          <th>{{$t('AGENT_SUPPORT_PLAN.ORIGINAL_SYSTEM_REWAED')}}</th>
          <th>{{$t('AGENT_SUPPORT_PLAN.SUPPORT_REWARD')}}</th>
        </tr>
        <tr v-for="(item, index) in rankList[0]" :key="index">
          <td :class="'td-top'+ (index +1)" style="padding-left:30px; text-align: left;">{{ item.dmobile }}</td>
          <td>{{ $filters.currencySymbol(item.effectiveCommission) }}</td>
          <td>{{ $filters.currencySymbol(item.reward) }}</td>
        </tr>
      </table>

      <h4><strong>{{$t('AGENT_SUPPORT_PLAN.LEVEL')}} 2</strong></h4>
      <table>
        <tr>
          <th>{{$t('AGENT_SUPPORT_PLAN.PHONE')}}</th>
          <th>{{$t('AGENT_SUPPORT_PLAN.ORIGINAL_SYSTEM_REWAED')}}</th>
          <th>{{$t('AGENT_SUPPORT_PLAN.SUPPORT_REWARD')}}</th>
        </tr>
        <tr v-for="(item, index) in rankList[1]" :key="index">
          <td style="padding-left:30px;text-align: left;">{{ item.dmobile }}</td>
          <td>{{ $filters.currencySymbol(item.effectiveCommission) }}</td>
          <td>{{ $filters.currencySymbol(item.reward) }}</td>
        </tr>
      </table>

    <h4><strong>{{$t('AGENT_SUPPORT_PLAN.LEVEL')}} 1</strong></h4>
      <table>
        <tr>
          <th>{{$t('AGENT_SUPPORT_PLAN.PHONE')}}</th>
          <th>{{$t('AGENT_SUPPORT_PLAN.ORIGINAL_SYSTEM_REWAED')}}</th>
          <th>{{$t('AGENT_SUPPORT_PLAN.SUPPORT_REWARD')}}</th>
        </tr>
        <tr v-for="(item, index) in rankList[2]" :key="index">
          <td style="padding-left:30px;text-align: left;">{{ item.dmobile }}</td>
          <td>{{ $filters.currencySymbol(item.effectiveCommission) }}</td>
          <td>{{ $filters.currencySymbol(item.reward) }}</td>
        </tr>
        <tr>
          <td style="background:#f3e6dc; color: #987a61;" colspan="4">{{$t('AGENT_SUPPORT_PLAN.ONLY_TOP_5')}}</td>
        </tr>
      </table>
    </div>

    <h3 class="title">{{$t('AGENT_SUPPORT_PLAN.DAILY_REQUIRE')}} & {{$t('AGENT_SUPPORT_PLAN.REWARD')}}</h3>
    <div class="p2">
      <h4><strong>{{$t('AGENT_SUPPORT_PLAN.DAILY_REQUIRE')}}</strong></h4>
      <table>
        <tr>
          <th>{{$t('AGENT_SUPPORT_PLAN.LV')}}</th>
          <th>{{$t('AGENT_SUPPORT_PLAN.EFFECIVE_USERS_AMOUNT')}}</th>
          <th>{{$t('AGENT_SUPPORT_PLAN.POINTS_BONUS')}}</th>
        </tr>
        <tr v-for="(item, index) in configList" :key="index" :class="configList.length - index === level ? 'myLv' : ' '">
          <td style="width:20px">{{ configList.length - index }}</td>
          <td style="width:auto">{{ item.effectiveCount }}+</td>
          <td style="width:auto">₹{{ item.affinity1SubBetCommission }}</td>
        </tr>
      </table>

      <h4><strong>{{$t('AGENT_SUPPORT_PLAN.REWARD')}}</strong></h4>
      <table>
        <tr>
          <th style="width:20px">{{$t('AGENT_SUPPORT_PLAN.LV')}}</th>
          <th style="width:auto">{{$t('AGENT_SUPPORT_PLAN.ORIGINAL_SYSTEM_REWAED')}}</th>
          <th style="width:auto">{{$t('AGENT_SUPPORT_PLAN.SUPPORT_REWARD')}}</th>
          <th style="width:auto">{{$t('AGENT_SUPPORT_PLAN.CONSECUTIVE_DAYS_EXTRA_REWAED')}}</th>
        </tr>
        <tr v-for="(item, index) in configList" :key="index" >
          <td>{{ configList.length - index }}</td>
          <td v-show="index === 0" rowspan="3" >{{$t('AGENT_SUPPORT_PLAN.I_E_Q')}}</td>
          <td>{{$t('AGENT_SUPPORT_PLAN.EFFECIVE_USERS_AMOUNT')}} <span class="red"> * {{ item.rewardCoeff }}</span></td>
          <td v-show="index === 0" rowspan="3">{{$t('AGENT_SUPPORT_PLAN.TOTAL_SUPPORT_REWARD')}}<span class="red">* 0.1</span></td>
        </tr>
      </table>
    </div>

    <h6>OXXOWIN.com All rights reserved.</h6>

    <!--**************************************** 弹框 ****************************************-->
    <nut-popup pop-class="pop-reward-amount" v-model:visible="rewardAmountPopup">
      <div class="bd">
        Please provide your OXXOWIN account phone number and yesterday reward screenshot,
        send email to:
        <br/>
        <strong @click="copyClick('business@oxxowin.com')">business@oxxowin.com</strong>
        <br>
        the customer service staff will handle it for you.
      </div>
      <nut-button type="primary" @click="copyClick('business@oxxowin.com')">Copy Email</nut-button>
    </nut-popup>

</div>

</template>

<script>
import axios from "axios";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import {Toast} from "@nutui/nutui";
import { getPercent } from "@/assets/js/commonUtil";
export default {
  data() {
    return {
      commonInfo: '',
      imgUrl: '',
      rewardAmountPopup: false,
      maxLevel: 3,
      level: '',
      levelPercent: '',
      nextLevelConfig: [],
      todayEffectiveCount: '',
      todayEffectiveCountPercent: '',
      todayAffinity1SubBetCommission: '',
      todayAffinity1SubBetCommissionPercent: '',
      yesterdayRewardAmount: '',
      yesterdayRewardAmountPercent: '',
      configList: [],
      rankList: [],
    }
  },
  created() {
    this.getInviterAssest()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.commonInfo = JSON.parse(localStorage.getItem('commonInfo'))
  },
  methods: {
    getInviterAssest(){
      Toast.loading('Loading...', {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
      axios({
        url: "/api/invite/inviter-assest",
        method: "get"
      }).then((res) => {
        const d = res.data.data
        this.level = d.level
        this.nextLevelConfig = d.nextLevelConfig
        this.todayEffectiveCount = d.todayEffectiveCount
        this.todayAffinity1SubBetCommission = d.todayAffinity1SubBetCommission
        this.yesterdayRewardAmount = d.yesterdayRewardAmount
        this.configList = d.configList
        this.rankList = d.rankList
        this.levelPercent = getPercent(this.maxLevel, d.level)
        this.todayEffectiveCountPercent = getPercent(this.nextLevelConfig.effectiveCount, d.todayEffectiveCount)
        this.todayAffinity1SubBetCommissionPercent = getPercent(this.nextLevelConfig.affinity1SubBetCommission, d.todayAffinity1SubBetCommission)
        this.yesterdayRewardAmountPercent = d.yesterdayRewardAmount > 0 ? '100' : '0'
        Toast.hide("loading")
      }).catch(function (error) {
        console.log(error);
        Toast.hide("loading");
      })
    },
    copyClick(email){
      let cInput = document.createElement("input")
      cInput.value = email
      document.body.appendChild(cInput)
      cInput.select()
      document.execCommand("copy")
      Toast.success('Email address has been Copy')
      document.body.removeChild(cInput)
    },
    clickRewardAmount(){
      if(this.yesterdayRewardAmount <= 0){
        return
      }else{
        this.rewardAmountPopup = true
      }
    },
    getTodayDate() {
      const timestamp = Date.parse(new Date()) - 1000 * 60 * 60 *24;
      const date1 = new Date(timestamp);
      var Y = date1.getFullYear();
      var M = (date1.getMonth() + 1 < 10 ? '0' + (date1.getMonth() + 1) : date1.getMonth() + 1);
      var D = date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate();
      const t = Y + '-' + M + '-' + D;
      return t
    },
  },
  setup() {
    const state = reactive({
    });
    const gradientColor = {
      '0%': '#5a0e7f',
      '100%': '#ffd162'
    };
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
    };
    return {
      gradientColor,
      router,
      state,
      ...methods,
    };
  }

  
};
</script>

<style scoped>
  .h2{ text-align: center; margin: 0; padding: 0; background:#5a0e7f;margin-top: -10px; }
  .plan-main{ max-width: 600px; background: #5a0e7f; padding: 0px 10px 20px 10px;margin:0px auto -10px auto; }
  .plan-main p, .plan-main h4{ margin: 10px 0;}
  .plan-main .f12{ font-size: 12px;}
  .plan-main .f18{ font-size: 18px;}
  .plan-main h3.title{ margin: 0; padding: 0 52px 0 15px; line-height: 32px; height: 25px; color: #5a0e7f; display: inline-block; font-size: 14px; border-radius: 3px 0 0 0;
    background:#fff url(../assets/images/bg150.png) right top no-repeat;background-size:44px auto;}
  .p3 img, .p4 img , .p5 img, .h2 img { width: 100%; margin: 0;}
  .tx-box p{ color: #fff; opacity: .5; line-height: 14px; text-align:left; padding: 0 10px; font-size: 12px;}
  .plan-main h6{ text-align: center; color: #fff; margin: 10px 0 0px 0; opacity: .3; font-size: 14px; font-weight: 300;}

  .p1{padding: 10px 10px 20px 10px;border-radius:10px; background:#fff url(../assets/images/bg149.png) center center no-repeat;background-size:100% auto;}
  .p1 h3{ text-align: center;font-size: 16px; margin: 10px 0 30px 0; }
  .p1 h3 strong{ border-bottom:2px solid #5a0e7f; padding-bottom: 6px;color: #5a0e7f;}
  .p1 ul{ margin-top: 10px; display: flex; text-align: center;}
  .p1 li{ width: 25%; text-align: center;}
  .p1 li p{ padding: 0; font-size: 10px; }
  .p1 li .nut-cell{ width: 65px; height: 65px !important; max-height: 65px; padding:0px; margin: 0 auto;box-shadow:0 0 0 #edeef1 !important; 
    justify-content: center;background: #fff; border-radius: 34px;}
  .p1 li .nut-cell .nut-circleprogress{ width: 70px !important; height: 70px !important; min-width: 70px !important;min-height: 70px !important;}
  .p1 .lk{ height: 30px; line-height: 30px; text-align: center; color: #fff; background: #bc5000; border-radius:30px; width: 280px; margin: 0 auto;
    background: linear-gradient(315deg, #5a0e7f 0%, #ffd162 100%);}
  .p1 .lk-d{  background: linear-gradient(315deg, #d2d2d2 0%, #d2d2d2 100%);}
  .p2{ background: #fff; padding: 10px; border-radius:0px 10px 10px 10px; margin-bottom: 10px;}
  .p2 h4{ text-align: center;font-size: 12px; margin: 0 auto -1px auto; background: #cb783a; color: #fff;
    display: inline-block; border-radius: 5px 5px 0 0;  padding: 5px 0; width: 100%;
    background: linear-gradient(315deg, #5a0e7f 0%, #ffd162 100%);}
  .p2 h4 strong{  padding-bottom: 3px;color: #fff; font-weight: 300;}
  .p2 table{ border: 1px solid #d6c291; font-size: 12px;border-radius:5px; width: 100%; margin-bottom: 20px;}
  .p2 table tr{border: 1px solid #d6c291;}
  .p2 table th{border: 1px solid #d6c291; font-weight: 300; background: #fff; line-height: 14px; padding: 5px 0; color: #333; border-radius:5px;}
  .p2 table td{border: 1px solid #d6c291;line-height: 14px;text-align: center;background: #fff; border-radius:5px; padding: 5px; color:#333;}
  .p2 table .red{ color: #bc5000; font-weight: 600;}

  .p3{ background: #fff; padding: 10px; border-radius:0px 10px 10px 10px; margin-bottom: 10px; min-height: 100px;} 
  .p3 h4{ text-align: center;font-size: 14px; margin: 0 auto 1px auto; background: #ac0c72; color: #fff;
    display: inline-block; border-radius: 5px; padding: 5px 0; width: 100%;
    background: linear-gradient(315deg, #ac0c72 0%, #5a0e7f 100%);}
  .p3 h4 strong{  padding-bottom: 3px;color: #fff; font-weight: 300;}
  .p3 table{ border: 1px solid #fff; font-size: 12px;border-radius:5px; width: 100%; margin-bottom: 0px;}
  .p3 table tr{border: 1px solid #fff;}
  .p3 table th{border: 1px solid #fff; font-weight: 300; background: #e7b89b; line-height: 14px; padding: 5px 0; color: #7a573a; border-radius:5px;}
  .p3 table td{border: 1px solid #fff;line-height: 14px;text-align: center;background: #e6d1c0; border-radius:5px; padding: 5px; color:#7a573a;}
  .p3 .td-top1{ background:#e6d1c0 url(../assets/images/bg58.png) 5px center no-repeat;background-size:20px auto; color: rgb(78, 0, 0);}
  .p3 .td-top2{ background:#e6d1c0 url(../assets/images/bg58b.png) 5px center no-repeat;background-size:20px auto;color: rgb(78, 0, 0);}
  .p3 .td-top3{ background:#e6d1c0 url(../assets/images/bg58c.png) 5px center no-repeat;background-size:20px auto;color: rgb(78, 0, 0);}
  .p2 table tr.myLv td{ background: #d7efb7; color: #436c0e;}
  .f-r{ float:right; line-height: 12px; color: rgb(249, 255, 202);  margin-right: 10px;font-size: 10px; margin-top: 0px; text-align: right;}
  .f-r span{opacity: .4; }
</style>
<style>
 .p1 li .nut-cell .nut-circleprogress-text{ top:52%; color: #5a0e7f !important; font-weight: 600;  font-size: 16px; }
 .p1 li .nut-cell .nut-circleprogress-text .f-12{font-size: 12px;}
 .pop-reward-amount{
  padding: 0px !important;
  width: 80% !important;
  min-height: 100px;
  top: 50%;
  overflow-y:hidden !important;
  background: transparent !important;  text-align: center;
}
.pop-reward-amount .nut-button--round{height: 30px !important; margin-top:5px;}
.pop-reward-amount .bd{ margin: 0; padding: 0 5px; border-radius: 5px;padding: 20px; min-height: 100px; line-height: 20px;
  background:#fff url(../assets/images/bg149.png) center center no-repeat;background-size:150% auto;}
.pop-reward-amount .pop-ab .img100{ width: 100%; margin: 0;}
.pop-reward-amount strong{ color: #bc5000; font-weight: 600;}
</style>
