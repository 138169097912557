/**
 * 注册
 * @param inviteCode
 */
export function eventTrackingRegister(inviteCode) {
    console.log("[eventTrackingRegister] " + inviteCode);

    //套壳App
    shellAppEvent('register', inviteCode);

    //tiktok
    try {
        window.bb88.onEvent("signup", JSON.stringify(
            {
                "user_id": inviteCode,
            }
        ));
    } catch (e) {
        console.log("eventTrackingRegister exception: ", e)
    }

    //源头上包
    try {
        window.jsBridge.postMessage("register", JSON.stringify(
            {
                "user_id": inviteCode,
            }
        ));
    } catch (e) {
        console.log("eventTrackingPay[yuantou] exception: ", e)
    }
}

/**
 * 登录
 * @param inviteCode
 */
export function eventTrackingLogin(inviteCode) {
    console.log("[eventTrackingLogin] " + inviteCode);

    //tiktok
    try {
        window.bb88.onEvent("login", JSON.stringify(
            {
                "user_id": inviteCode,  //用户唯一标识
            }
        ));
    } catch (e) {
        console.log("eventTrackingLogin exception: ", e)
    }
}

/**
 * 付费
 * @param inviteCode
 * @param amount
 */
export function eventTrackingPay(inviteCode, amount, firstTime) {
    console.log("[eventTrackingPay] " + inviteCode + "," + amount + "," + firstTime);

    //套壳App
    shellAppEvent('play', inviteCode);

    //tiktok
    try {
        window.bb88.onEvent("pay", JSON.stringify(
            {
                "user_id": inviteCode,
                "amount": amount
            }
        ));
    } catch (e) {
        console.log("eventTrackingPay exception: ", e)
    }

    //源头上包
    try {
        if (firstTime) {
            window.jsBridge.postMessage("firstrecharge", JSON.stringify(
                {
                    "user_id": inviteCode,
                    "amount": amount
                }
            ));
        } else {
            window.jsBridge.postMessage("recharge", JSON.stringify(
                {
                    "user_id": inviteCode,
                    "amount": amount
                }
            ));
        }
    } catch (e) {
        console.log("eventTrackingPay[yuantou] exception: ", e)
    }
}

/**
 * 提现
 * @param inviteCode
 * @param amount
 */
export function eventTrackingWithdraw(inviteCode, amount) {
    console.log("[eventTrackingWithdraw] " + inviteCode + "," + amount);

    //源头上包
    try {
        window.jsBridge.postMessage("withdrawOrderSuccess", JSON.stringify(
            {
                "user_id": inviteCode,
                "amount": amount
            }
        ));
    } catch (e) {
        console.log("eventTrackingPay[yuantou] exception: ", e)
    }
}

function shellAppEvent(ename, uid) {
    //eslint-disable-next-line
    if (window['luck_m'] && window['luck_m']['onEvent_andironsBranch'] && luck_m.onEvent_andironsBranch != null) {
        //eslint-disable-next-line
        luck_m.onEvent_andironsBranch(ename, uid);
    }
}
