<template>
  <div>
  <nut-navbar
    :left-show="false"
    @on-click-icon="iconClick"
    class="mb0 nav-icon-right nut-navbar--fixed top-desc"
    :title="$t('recharge.h1')"
    titIcon="horizontal"
    fixed=true
  >
  </nut-navbar>

    <div class="recharge-bd balanceVue">
      <!-- <div v-show=" imgUrl === 'in/EN/S1/'" 
        class="holi-text" 
        style="color: #ffd200; margin: 20px 10px -5px 10px;text-align: center;border: 1px solid #f60;border-radius: 5px;padding: 5px;background: #542200;">
        📣📣📣 Holi Recharge Bonus 3/8-3/15 🥳🥳🥳
      </div> -->


      <div class="top-text">
        <strong class="">{{$t('recharge.balance')}}:</strong>
        <span class="f-24">{{ $filters.currencySymbol(availableAmount) }}</span>
      </div>
  
      <div class="recharge-ls">

        <div
          class="recharge-btn"
          @click="rechargeBtnClick(item)"
          :class="{ active: item.defaultPlan }"
          v-for="(item, index) in plans"
          :key="index"
        >
          <strong>{{ $filters.currencySymbol(item.rechargeAmount) }}</strong>
          <span class="hot" v-show="item.hot">&nbsp;</span>
          <p v-show="item.giftAmount">+{{ $filters.currencySymbol(item.giftAmount) }}</p>
        </div>

      </div>
      <nut-form class="label-w70">
        <nut-form-item :label="$t('recharge.amount')">
          <input
            class="nut-input-text"
            :placeholder="$t('recharge.amountPlaceholder')"
            type="text"
            v-model="rechargeInputText"
            v-on:input="inputChange"
            :readonly="!allowCustomAmount"
          />
          <p class="p-info" v-show="rechargeInputText > 0">
            +{{ $filters.currencySymbol(channelList.giftAmount) }}
          </p>
        </nut-form-item>

        <nut-form-item :label="$t('recharge.receipt')">
          <input
            v-show="rechargeInputText > 0"
            class="nut-input-text"
            :placeholder="$t('recharge.receiptPlaceholder')"
            type="text"
            readonly="true"
            v-model="channelList.receiptAmount"
          />
        </nut-form-item>
        <nut-form-item :label="$t('recharge.channel')">
          <nut-radiogroup direction="horizontal" v-model="radio" class="radio-list">
            <nut-radio
              :id= "index === 0 ? 'radioaActive' : ''"
              :label="item.id"
              v-for="(item, index) in channelList.channelRoutes"
              :key="index"
              v-bind:c-type="item.type"
              :class="item.lastSuccess ? 'last-success' : ''"
              @click="channelType(item.id)"
              :checked="{ checked: index === 1 }"
            >
              {{ item.typeName }}
            </nut-radio>

          </nut-radiogroup>
        </nut-form-item>
      </nut-form>

      

      <div class="login-btn m10">
        <nut-button block type="info" @click="rechargeApply" :disabled="rechargeing">
          <nut-icon v-if="rechargeing" name="loading" size="25" style="margin-top: 15px;"></nut-icon>
          <span v-else>{{$t('global.recharge')}}</span>
        </nut-button>
      </div>

      <nut-noticebar 
          direction='vertical' 
          :list="othersInfo" 
          :speed='10' 
          :standTime='2000' 
          :closeMode="true" 
          :color="`#ff7e00`">
      </nut-noticebar>
       
      <div class="bt-tx">
        <p><strong>{{$t('withdrawal.description')}}</strong></p>
        <p>{{$t('recharge.tx11')}}<strong>{{$t('bankCard.email')}}:{{ eMail }} </strong> {{$t('recharge.tx12')}}</p>
        <p>{{$t('recharge.tx13')}}'<strong style="color:#1e246d" class="text-color" @click="vipPopupClick">{{$t('recharge.tx14')}}</strong>'</p>
        <p>{{$t('recharge.tx15')}}</p>
      </div>
      <nut-popup pop-class="popclass pop-h200" closeable round close-on-click-overlay='false' :style="{ padding: '20px 40px',width:'70%' }" 
        v-model:visible="showPopup" :z-index="100" @close="popClose">
        <p>{{$t('recharge.pop')}}</p>
        <div>
          <span>Secure</span>
          <span>Fast</span>
        </div>
      </nut-popup>


    </div>

    <nut-popup pop-class="pop-vip" v-model:visible="vipPopup" :z-index="100">
      <VipRules :myLevel="myLv" :popTitle="popTitle"></VipRules>
    </nut-popup>

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>
  </div>

  <div class="tg-btn">
    <img src="../assets/images/20230601/img41.png"  @click="telegramClick" />
  </div>


  <form id="payForm" name="payForm" method="post" hidden></form>

</template>

<script>
import { reactive, toRefs, onMounted, watch, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import VipRules from "../components/VipRules2.vue";
import { getCountry,getLastTimeGameName } from "@/assets/js/environment";
import {getRootUrl} from "@/assets/js/commonUtil";

export default {
  components: {
    VipRules: VipRules,
    tabbar: TabbarMain,
  },
  data(){
    return{
      country:'',
      template:'',
      eMail:'',
      imgUrl:'',
      commonInfo:'',
      telegramChannelUrl:'',
      i18n:{
        loading: this.$t('global.loading'),
        networkError: this.$t('global.networkError'),
        failSelect: this.$t('recharge.failSelect'),
        failEnter: this.$t('recharge.failEnter'),
        success: this.$t('recharge.success'),
        popTitle_1: this.$t('recharge.popTitle_1'),
        popTitle_2: this.$t('recharge.popTitle_2'),
        successfully: this.$t('global.successfully'),
        rechargeBtnText: this.$t('global.rechargeBtnText')
      }
    }
  },
  created() {
    this.country = getCountry()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.template = document.querySelector('body').getAttribute('s')
    if(window.localStorage.getItem('commonInfo')){
      this.commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
      this.eMail = this.commonInfo.serviceEmail
      this.telegramChannelUrl = this.commonInfo.telegramChannelUrl
    }else{
      axios.get("/api/system/common", {}).then((response)=> {
        this.eMail = response.data.data.serviceEmail
        this.telegramChannelUrl = response.data.data.telegramChannelUrl
      })
    }
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      vipPopup:false,
      popTitle:'',
      allowCustomAmount:true,
      showPopup: false,
      radio: 0,
      tabbarActiveIndex: 0,
      availableAmount: 0,
      availableAmount2: 0,
      rechargeInputText: 0,
      channelRouteId: 0,
      purpose: null,
      plans: [],
      myLv: "",
      channelList: [],
      othersInfo: [],
      othersInfoList: [],
      rechargeing:false,
      formData: [],
      formUrl:''
    });
    watch(
      () => state.rechargeInputText,
      () => {
        if (state.rechargeInputText > 0) {
          axios({
            url: "/api/fund/recharge/prepare",
            method: "get",
            params: { rechargeAmount: state.rechargeInputText },
          }).then((res) => {
            state.channelList = res.data.data;
          });
        }
      }
    );

    const router = useRouter();
    const methods = {
      vipPopupClick(){
        state.vipPopup = true; 
        state.popTitle = _data.data.i18n.popTitle_1
      },
      telegramClick(){
        window.open(_data.data.telegramChannelUrl, "_self");
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      iconClick() {
        router.push({
          name: "rechargeHistoryList",
        });
      },
      rechargeBtnClick(t) {
        this.rechargeInputText = t.rechargeAmount;
        state.channelRouteId = 0;
        this.plans.forEach(function (obj) {
          obj.defaultPlan = false;
        });
        t.defaultPlan = !t.defaultPlan;
        methods.radioaActiveClick()
      },
      radioaActiveClick() {
        var e = document.createEvent("MouseEvents");
        e.initEvent("click", true, true);
        document.getElementById("radioaActive").dispatchEvent(e);
      },
      channelType(id){
        state.channelRouteId = id
      },
      submitPost(){
        console.log(111)
      },
      rechargeApply() {
        if (state.channelRouteId === 0) {
          Toast.fail(_data.data.i18n.failSelect);
          return;
        }
        if (state.rechargeInputText <= 0) {
          Toast.fail(_data.data.i18n.failEnter);
          return;
        }
        if (state.rechargeInputText === '') {
          Toast.fail(_data.data.i18n.failEnter);
          return;
        }
        state.rechargeing = true;
        //const res = await axios
        axios
          .post("/api/fund/recharge/apply", {
            applyAmount: state.rechargeInputText,
            channelRouteId: state.channelRouteId,
            purpose: state.purpose,
            returnUrl: `${getRootUrl()}/rechargeHistoryList`
          })
          .then((response) => {
            if (response.data.code === 0) {
              if(response.data.data.type === "redirect"){
                state.showPopup = true
                window.open(response.data.data.redirectUrl,"_self");
              }else if(response.data.data.type === "form"){
                  state.formData = response.data.data.formParams
                  state.formUrl = response.data.data.formUrl
                  var f = document.getElementById("payForm")
                  f.setAttribute("action",state.formUrl);
                  for (const key of response.data.data.formParams.keys()) {
                      const n = response.data.data.formParams[key].name
                      const v = response.data.data.formParams[key].value
                      if (n != 'formUrl' && n != 'formRequestMethod') {
                          var i = document.createElement("input")
                          i.setAttribute("name",n);
                          i.setAttribute("value",v);
                          f.appendChild(i)
                      }
                  }
                  document.forms['payForm'].submit()
              }
              state.rechargeing = false;
            } else {
              if (response.data.msg != undefined) {
                Toast.fail(response.data.msg);
              } else {
                Toast.fail("error!");
              }
            }
          })
          .catch(function (error) {
            state.rechargeing = false;
            console.log(error);
            Toast.fail(_data.data.i18n.networkError);
          });
          //state.rechargeing = false;
      },
      popClose(){
        router.push({
          name: "rechargeHistoryList",
        });
      },
    };

    onMounted(() => {
      state.purpose = getLastTimeGameName('recharge')
      methods.textToast(_data.data.i18n.loading);
      axios
        .get("/api/fund/recharge/page", {})
        .then(function (response) {
          state.allowCustomAmount = response.data.data.allowCustomAmount
          state.availableAmount =
            response.data.data.fundAccount.availableAmount;
          state.plans = response.data.data.plans;
          state.myLv = response.data.data.vip.level;
          state.availableAmount2 = state.availableAmount <= 100 ? 0 : state.availableAmount -100
          for (let i = 0; i < response.data.data.plans.length; i++) {
            let j = response.data.data.plans[i];
            if (j.defaultPlan === true) {
              state.rechargeInputText = j.rechargeAmount;
            }
          }
          for(let v of response.data.data.othersInfo){	
            let text = v.mobile + ' ' + _data.data.i18n.successfully + ' ' + _data.data.i18n.rechargeBtnText+ ': ' + v.amount
            state.othersInfoList.push(text.toString())
          }
          state.othersInfo = state.othersInfoList
          setTimeout(() => {
            var e = document.createEvent("MouseEvents");
            e.initEvent("click", true, true);
            document.getElementById("radioaActive").dispatchEvent(e);
          }, 500);
          Toast.hide("loading");
        })
        .catch(function (error) {
          Toast.hide("loading");
          console.log(error);
        });
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>
<style scoped>
.f-24{color: #fd0;font-size: 24px; font-weight: 500; margin-left: 3px;}
.p-info{  position:absolute; right: 20px; top:-3px; height: 30px;line-height: 30px; padding-left: 20px; color:#940d01;
  background: url(../assets/images/20230601/img15.png) 0 center no-repeat;background-size: 15px auto;}
.p-info .r{ float: right;}
.recharge-bd {
  background: url(../assets/images/bg05.png) 0 0px no-repeat;
  background-size: 100% 300px;
  min-height: 500px;
  margin-top: -5px;
  padding-top: 45px;
  padding-bottom: 25px;
}




.top-text {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin: 0;
  padding: 20px 0 10px 0;
}
.top-text strong {
  font-weight: 300;
  text-align: center;
  padding-top: 10px;
  opacity: .5;
}
.top-text .nut-countup {
  display: flex;
  align-items: center;
  width: auto !important;
  color: #ffdd00;
  font-size: 24px;
}
.recharge-ls {
  padding: 5px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.recharge-btn {
  width: 29%;
  margin: 5px;
  background: #fff;
  align-items: flex-start;
  text-align: center;
  border-radius: 3px 3px 7px 3px;
  position: relative;
  height: 50px;
}
.recharge-btn p {position: absolute; left: 0; right: -8px;bottom: 0;margin: 0; 
    line-height: 28px;color: #940d01;text-align: right;padding-right: 30px; height: 22px; overflow: hidden;
    background: url(../assets/images/20230601/img14.png) right center no-repeat;background-size: auto 22px;}

.recharge-btn.active p { background: url(../assets/images/20230601/img14.png) right center no-repeat;background-size: auto 22px;color: #940d01;}



.recharge-btn strong {
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #9f6c1a;
}


.recharge-btn.active {
  background: #d0ae76;
  background: linear-gradient(315deg, #3a013b 0%, #d0ae76 100%);
}
.recharge-btn.active strong {
  color: #fff;
  font-weight: 600;
}


.nut-form-item__body__slots .nut-input-text{ font-size: 18px; line-height: 30px;}

</style>
<style>

.label-w70 .nut-form-item__label { width: 70px !important;}
.label-w70 .nut-cell{ padding:13px 5px;}

.last-success .nut-radio__label{ background:url(../assets/images/bg512.png) right center no-repeat !important; 
background-size: 50px auto !important; padding-right: 55px !important;}
</style>
