<template>
  <div>
    <nut-navbar
      :left-show="false"
      :title="$t('taskReward.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    </nut-navbar>
    <div class="signin-task" v-if="dailyGrowthList.length > 0">
      <div class="bd">
        <div class="dayls">
          <div class="d"  
            :class="{
              'complete': item.day < dailyGrowthCurrent.day,
              'active': item.day === dailyGrowthCurrent.day,
            }"
            v-for="(item, index) in dailyGrowthList" :key="index" :title="item.title" :conditionType="item.conditionType">
            <h5>{{$t('taskReward.day')}}{{item.day}}</h5>
            <p v-show="item.day <= dailyGrowthCurrent.day">{{ $filters.currencySymbol(item.rewardAmount) }}</p>
            <p v-show="item.day > dailyGrowthCurrent.day">?</p>
          </div>
        </div>
        <div class="btn">
          <nut-button v-if="dailyGrowthCurrent.status==='locked'" block type="warning" @click="dailyGrowthClick" disabled>{{$t('taskReward.dailyQuestLocked')}}</nut-button>
          <nut-button v-if="dailyGrowthCurrent.status==='processing'" block type="warning" @click="dailyGrowthClick">{{$t('taskReward.dailyQuest')}}</nut-button>
          <nut-button v-if="dailyGrowthCurrent.status==='wait_receive'" block type="warning" @click="dailyGrowthClick">{{$t('taskReward.dailyQuestCompleted')}}</nut-button>
          <nut-button v-if="dailyGrowthCurrent.status==='received'" block type="warning" @click="dailyGrowthClick">{{$t('taskReward.dailyQuestFinished')}}</nut-button>
        </div>
      </div>
    </div>
    <div v-else style="height:80px"></div>

    <nut-popup
      pop-class="popclass pop-signin"
      v-model:visible="showBasic"
      :z-index="100">
      <div class="pop-t">
        <h4><span>{{$t('taskReward.day')}} {{currentDay}}</span></h4>
        <h4>{{ title }}</h4>
        <!--h4 style="color: #722400;font-size: 14px;">{{ conditionText }}</!--h4 -->
        <p>
          <strong>{{ $filters.currencySymbol(rewardAmount) }}</strong>
        </p>
      </div>
      <div class="pop-b">
        <span style="font-size:12px;display: block;">{{dailyGrowthCurrent.progress}}% {{$t('taskReward.complete')}}</span>
        <nut-button v-if="dailyGrowthCurrent.status === 'processing'" block type="warning" @click="goToTaskUrl(conditionType)">{{$t('taskReward.completeTask')}}</nut-button>
        <nut-button v-if="dailyGrowthCurrent.status === 'wait_receive'" block type="warning" @click="receiveAward(rewardAmount)">{{$t('taskReward.receiveReward')}}</nut-button>
        <nut-button v-if="dailyGrowthCurrent.status === 'received'" block type="info"  disabled=ture>{{$t('taskReward.received')}}</nut-button>
      </div>
    </nut-popup>

    <!-- 第一次分享任务 -->
    <div class="share-first-time">
      <h3>{{$t('taskReward.shareLifafaTitle2')}}</h3>
      <p>{{$t('taskReward.shareLifafaText')}}</p>
      <div class="amount">{{ $filters.currencySymbol(shareBonusTaskReward) }}</div>
      <div v-if="shareBonusTaskStatus === 'processing'" class="btn" @click="shareBonusTask"><span>{{$t('taskReward.completeTask')}}</span></div>
      <div v-else-if="shareBonusTaskStatus === 'wait_receive'" class="btn" @click="shareBonusReceive"><span>{{$t('taskReward.receive')}}</span></div>
      <div v-else-if="shareBonusTaskStatus === 'received'" class="btn d"><span>{{$t('taskReward.received')}}</span></div>
    </div>

    <div class="d-app" v-show="appTaskStatus === 'forbid' || appTaskStatus === 'can_receive'">
      <h4>
        {{$t('global.DOWNLOAD_APP')}}
      </h4>
      <p>{{$t('global.DOWNLOAD_APP_TEXT')}}</p>
      <div class="btnbox">
        <nut-button
          size="small"
          v-show="appTaskStatus === 'forbid'"
          type="info"
          @click="downloadApp"
          plain
          >{{$t('global.DOWNLOAD_APP_BTN')}}</nut-button>
        <nut-button
          size="small"
          v-show="appTaskStatus === 'can_receive'"
          @click="downloadReceiveReward(appTaskReward)"
          type="primary"
          >{{$t('taskReward.receiveReward')}}</nut-button>
      </div>
      <div class="b" v-show="appTaskStatus === 'forbid'">{{$t('global.DOWNLOAD_APP_TEXT4')}}</div>
      <div class="l">{{ $filters.currencySymbol(appTaskReward) }}</div>
    </div>

    <h4 class="ls-h4">{{$t('taskReward.missionTarget')}}</h4>

    <div class="task-list">
      <div
        class="task-item"
        v-for="(item, index) in regularTaskList"
        :key="index"
        :taskType="item.taskType"
        :conditionValue="item.conditionValue"
      >
        <div class="task-flex">
          <div class="l">
            <strong>{{ $filters.currencySymbol(item.rewardAmount) }}</strong>
            <div class="b">
              <nut-progress
                :percentage="item.progress"
                size="small"
                :stroke-color="bgc"
              />
            </div>
          </div>
          <div class="r">
            <h5>{{ item.taskTitle }}</h5>
            <p>{{ item.entryTitle }}</p>
            <nut-button
              size="small"
              v-show="item.status === 'processing'"
              type="info"
              @click="goToTaskUrl(item.conditionType)"
              plain
              >{{$t('taskReward.toTask')}}</nut-button
            >
            <nut-button
              size="small"
              v-show="item.status === 'wait_receive'"
              type="primary"
              @click="
                taskReceive(item.serialNum, item.taskId, item.rewardAmount)
              "
              >{{$t('taskReward.receiveReward')}}</nut-button
            >

            <nut-button
              size="small"
              v-show="item.status === 'received'"
              type="primary"
              disabled
              >{{$t('taskReward.received')}}</nut-button
            >
          </div>
        </div>

        
      </div>
    </div>

    <nut-popup
      position="top"
      :z-index="100"
      :style="{ height: '220px', background:'#fff' }"
      @closed="topPopclose" 
      v-model:visible="taskPop"
    >
      <div class="pop-top">
        <h4>{{$t('taskReward.getBn')}}</h4>
        <p>
          <strong></strong><strong>{{ $filters.currencySymbol(taskAmount) }}</strong>
        </p>
      </div>
    </nut-popup>

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import TabbarMain from "../components/TabbarMain.vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import { getLastTimeGameName,getCountry } from "@/assets/js/environment";
import { getCommonInfoAttr } from "@/assets/js/commonInfo";

export default {
  data() {
    return {
      country: '',
      template:'',
      imgUrl:'',
      dailyGrowthList: [], //每日成长任务列表
      dailyGrowthCurrent: [], //每日成长任务信息
      dailySignList: {}, //每日签到任务列表
      dailySignCurrent: [], //每日签到任务信息
      regularTaskList: [], //常规任务列表
      dailyShareBonusCode:'', //每日红包code
      shareBonusTaskStatus:'', //每日红包任务状态
      shareBonusTaskReward:'', //每日红包任务金额
      baseRewardAmount: '',
      extraRewardAmount: '',
      title: '',
      rewardAmount: '',
      conditionType: '',
      conditionText:'',
      currentDay:'',
      appTaskReward: '', //下载app任务 奖励金额
      appTaskStatus: '', //下载app任务 forbid=不可领取 can_receive=可领取  received=已领取
    };
  },
  components: {
    tabbar: TabbarMain,
  },
  methods: {
    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },
    getTaskList() {
      this.textToast(this.$t('global.loading'));
      axios
        .get("/api/task", {})
        .then((response) => {
          this.dailyGrowthList = response.data.data.dailyGrowthList;
          this.dailyGrowthCurrent = response.data.data.dailyGrowthCurrent;
          this.dailySignList = response.data.data.dailySignList;
          this.regularTaskList = response.data.data.regularTaskList;
          this.currentDay = this.dailyGrowthCurrent.day
          this.dailyShareBonusCode = response.data.data.dailyShareBonusCode
          this.shareBonusTaskStatus = response.data.data.shareBonusTaskStatus
          this.shareBonusTaskReward = response.data.data.shareBonusTaskReward
          if(response.data.data.appTaskReward){
            this.appTaskReward = response.data.data.appTaskReward
            this.appTaskStatus = response.data.data.appTaskStatus
          }
          if(this.dailySignList.length > 0){
            this.dailySignCurrent = response.data.data.dailySignCurrent;
            this.dailySignList = response.data.data.dailySignList;
            let i = this.dailySignCurrent.toSignDay
            this.baseRewardAmount = response.data.data.dailySignList[i].baseRewardAmount
            this.extraRewardAmount = response.data.data.dailySignList[i].extraRewardAmount
          }

          let ii = this.dailyGrowthCurrent.day-1
          this.title =  response.data.data.dailyGrowthList[ii].title
          this.rewardAmount =  response.data.data.dailyGrowthList[ii].rewardAmount
          this.conditionType =  response.data.data.dailyGrowthList[ii].conditionType
          //let text = this.conditionType.replace(/_/g," ")
          //this.conditionText = text.substring(0,1).toUpperCase() + text.substring(1) + ' ' + response.data.data.dailyGrowthList[ii].conditionValue
          switch (this.conditionType) {
            case "daily_recharge_times":
              this.conditionText = 'Daily recharge '+ response.data.data.dailyGrowthList[ii].conditionValue +' times'   //当日充值次数达标 x 次
              break;
            case "daily_recharge_amount":
              this.conditionText = 'Daily recharge amount '+ response.data.data.dailyGrowthList[ii].conditionValue  //当日充值金额达标 x 元
              break;
            case "daily_bet_times":
              this.conditionText = 'Daily game '+ response.data.data.dailyGrowthList[ii].conditionValue +' times' //当日下注次数达标 x 次
              break;
            case "daily_bet_amount":
              this.conditionText = 'Daily input amount'+ response.data.data.dailyGrowthList[ii].conditionValue //当日下注金额达标 x 元
              break;
            case "daily_win_times":
              this.conditionText = 'Daily win '+ response.data.data.dailyGrowthList[ii].conditionValue +' times' //当日赢利次数达标 x 次
              break;
            case "daily_win_amount":
              this.conditionText = 'Daily win amount '+ response.data.data.dailyGrowthList[ii].conditionValue //当日赢利金额达标 x 元
              break;
          }

          Toast.hide("loading");
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
      })
    },
    shareBonusTask(){
      this.$router.push("/receiveBonus?c="+this.dailyShareBonusCode);
    },
    shareBonusReceive(){
      axios({
        url: "/api/task/share-bonus/receive-reward",
        method: "post"
      })
        .then((res) => {
          if (res.data.code === 2000) {
            Toast.fail(res.data.msg);
          } else {
            this.taskReceivePop(this.shareBonusTaskReward);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  created() {
    this.country = getCountry()
    this.template = document.querySelector('body').getAttribute('s')
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.getTaskList();
  },
  setup() {
    const state = reactive({
      bgc: "#2b4968",
      showBasic: false,
      taskPop: false,
      taskAmount: 0,
      startFlag: false,
      tabbarActiveIndex: 3,
    });
    const router = useRouter();
    const methods = {
      downloadApp(){
        window.open(getCommonInfoAttr('appDownloadUrl'))
      },
      downloadReceiveReward(amount){
        state.taskAmount = amount
        axios
          .post("/api/task/app/receive-reward", {})
          .then((res) => {
            console.log(res);
            state.taskPop = true;
            setTimeout(() => {
              state.startFlag = false;
              state.taskPop = false;
            }, 1500)
          })
          .catch(function (error) {
            console.log(error);
          })
      },
      receiveAward(amount){
        state.taskAmount = amount
        axios
          .post("/api/task/daily-growth/receive-reward", {})
          .then((res) => {
            console.log(res);
            state.taskPop = true;
            setTimeout(() => {
              state.startFlag = false;
              state.taskPop = false;
            }, 1500)
          })
          .catch(function (error) {
            console.log(error);
          })
      },
      goToTaskUrl(type) {
        switch (type) {
          case "first_recharge":
            router.push({ name: "rechargeBalance" }); //首次充值
            break;
          case "recharge_times":
            router.push({ name: "rechargeBalance" }); //充值次数达标
            break;
          case "recharge_amount":
            router.push({ name: "rechargeBalance" }); //充值金额达标
            break;
          case "first_invite":
            router.push({ name: "agentPromotion" }); //首次邀请
            break;
          case "invite_count":
            router.push({ name: "agentPromotion" }); //邀请人数达标
            break;
          case "first_effective_invite":
            router.push({ name: "agentPromotion" }); 
            break;
          case "effective_invite_count":
            router.push({ name: "agentPromotion" });
            break;
          case "first_withdraw":
            router.push({
              path: 'withdrawalBalance',
              query: {
                amountType: 'coin'
              }
            })
            break;
          case "daily_recharge_times":
            router.push({ name: "rechargeBalance" }); //当日充值次数达标
            break;
          case "daily_recharge_amount":
            router.push({ name: "rechargeBalance" }); //当日充值金额达标
            break;
          default:
            router.push({ path: '/'+ getLastTimeGameName() });
        }
      },

      taskReceive(num, id, amount) {
        axios({
          url: "/api/task/receive-reward",
          method: "post",
          params: { serialNum: num, taskId: id }
        })
          .then((res) => {
            if (res.data.code === 2000) {
              Toast.fail(res.data.msg);
            } else {
              this.taskReceivePop(amount);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      taskSignClick(a1, a2){
        const amount = a2 > 0 ? a1 + a2 : a1
        axios.post("/api/task/daily-sign/sign", {})
        .then((response)=> {
              if(response.data.code === 0){
                this.taskReceivePop(amount);
              }else{
                Toast.fail("error!");
              }
        })
        .catch(function (error) {
          console.log(error);
          Toast.fail("Network anomaly!");
        });
      },
      taskReceivePop(amount) {
        state.taskAmount = amount;
        state.startFlag = true;
        state.taskPop = true;
        setTimeout(() => {
          state.startFlag = false;
          state.taskPop = false;
        }, 1500);
      },
      topPopclose(){
        router.go(0);
      },
      // 查看任务弹窗
      dailyGrowthClick(){
        state.startFlag = true;
        state.showBasic = true;
      }
    };
    const showBasicClick = () => {
      state.startFlag = true;
      state.showBasic = true;
    };
    const hideBasicClick = () => {
      state.showBasic = false;
      router.push({ path: '/'+ getLastTimeGameName() });
    };
    return {
      ...toRefs(state),
      ...methods,
      showBasicClick,
      hideBasicClick,
    };
  },
};
</script>

<style scoped>

.pop-top {
  background: url(../assets/images/20230601/img32.png) center 50px no-repeat;
  height: auto; width: 100%; padding-top: 105px;
  background-size: 140px auto; text-align: center;
}
.pop-top h4 {
  padding-top: 0px;
  margin-bottom: 0;
  font-size: 18px;
}
.pop-top p {padding-top: 10; margin: 0;}
.pop-top p strong {
  font-size: 36px;
  color: #fb7d26;
  font-weight: 500;
}
.popup-top{background:#000 !important}
.popup-top {
  overflow: hidden;}






.signin-task {
  background:#0a115b url(../assets/images/bg20.jpg) center 0 no-repeat;
  background-size: 420px auto;
  margin-top: 40px;
  padding-top: 85px;
}

.signin-task .bd {
  padding: 10px 0 30px 0;
}
.signin-task .dayls {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 98%;
  margin-bottom: 10px;
  margin-left: 1%;
}
.signin-task .dayls .d {
  background: #fcecb8;
  align-items: flex-start;
  text-align: center;
  margin: 1px 0px 1px 1px;
  padding-bottom: 3px;
  font-size: 10px;
  width: 14%;
  overflow: hidden;
  flex-grow: 1;
}
.signin-task .dayls .d:last-child {
  flex-grow: 2;
  flex-grow: 1;
  background: #fff3b2;
}

.signin-task .dayls .d:last-child p {
  background: url(../assets/images/bg11.png) left 2px no-repeat;
  background-size: 18px 18px;
  padding-left: 20px;
}

.signin-task .dayls .d h5 {
  margin: 0;
  padding: 0;
  line-height: 26px;
  font-weight: 300;
  background:url(../assets/images/bg51.png) 0 0 repeat-x; background-size: auto 100% ;
  color: #552400;
}
.signin-task .dayls .d p {
  padding-left: 18px;
  line-height: 32px;
  margin: 0;
  color: #bf4019;
  display: inline;
  background: url(../assets/images/bg194.png) left center no-repeat;
  background-size: 14px;
  font-size: 14px;
}

.signin-task .dayls .d.active {
  background: #fc9353;
}
.signin-task .dayls .d.active h5 {
  background:url(../assets/images/bg50.png) 0 0 repeat-x; background-size: auto 100% ;
  color: #fff000;
  font-weight: 600;
}
.signin-task .dayls .d.active p {
  color: #fff;
  font-weight: 600;
}

.signin-task .dayls .complete {
  background: #fcffcd url(../assets/images/bg37.png) right bottom no-repeat;
  background-size: 25px 25px;
  opacity: 1;
}
.signin-task .dayls .complete h5 {
  background:url(../assets/images/bg51.png) 0 0 repeat-x; background-size: auto 100% ;
  color: #552400;
}
.signin-task .btn {
  margin: 10px auto;
  width: 70%;
}

.ls-h4 {
  margin: 0 0 20px 0;
  line-height: 40px;
  padding: 0 0 0 40px;
  font-size: 15px;
  background: url(../assets/images/20230601/img29.png) 14px 10px no-repeat; background-size: 20px auto;
}
.task-list {
  margin: -20px 10px 10px 10px;
  box-shadow: 0 1px 7px #eee !important;
  border-radius: 10px;
  padding-bottom: 80px;
}

/* 签到 */
.share-first-time{background: #f6d769 url(../assets/images/20230601/img10.png) center 140px; background-size: 160%;
  box-shadow: 0 1px 10px #946a19 !important; 
  margin: -25px 10px 10px 10px;
  border-radius: 10px; min-height: 102px; padding: 10px 10px 0 20px;
  position: relative;}

  .share-first-time h3{line-height: 24px; font-size: 14px; margin: 0;}
  .share-first-time p{ line-height: 13px; font-size: 10px;margin: 0; color: #802e0b;}
  .share-first-time .btn{ position: absolute; bottom: 5px; right: 10px; height: 30px; width: 155px; line-height: 28px; text-align: right;
    background: url(../assets/images/20230601/img17.png) 0 0 no-repeat; background-size: auto 30px; color: #fff;}
  .share-first-time .btn span{display: inline-block;width: 81px;text-align: center; font-size: 12px;} 

  .share-first-time .btn.d{ opacity: .5;}

  .share-first-time .amount{position: absolute; bottom: 5px; left: 20px; height: 26px; width: 80px; text-align: center;line-height: 26px; 
    color: #802e0b; font-size: 20px; font-weight: 600; background: #fbc95b; border: 1px solid #802e0b; border-radius:3px;}
    

.task-list .task-item:last-child {
  border-bottom: 0px;
}
.task-list:last-child {
  margin: 0px 10px 10px 10px;
  padding-bottom: 70px;
}
.task-list.pb90 {
  padding-bottom: 90px;
}

.task-flex {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.task-flex .l {
  width: 92px; text-align: center;padding-top: 20px;}
.task-flex .l strong { font-weight: 600; font-size: 26px; line-height: 40px; color: #2b4968;}
.task-flex .r h5 {
  margin: 5px 0;
  padding: 0;
  font-size: 14px;
}
.task-flex .r p {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  color: #ac8b5a;
}
.task-flex .r {
  width: calc(100% - 110px);
  text-align: left;
}
.task-flex .r strong {
  font-size: 18px;
  padding: 5px 0;
  color: #bf4019;
}
.task-flex .r .nut-button{ position: absolute; right: 0px; bottom: 10px; border-radius:5px !important;}


.task-list .task-item {
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  background:#fff url(../assets/images/20230601/img16b.png) 0px center no-repeat;
  background-size: auto 100%;
  height: 83px; overflow: hidden;
  border-radius:5px;
}
.task-item .b {
  margin: 10px 0;
}
.task-item .b2 .nut-indicator {
  margin: 10px 0 0 50px;
}
.task-item .b2 .r {
  text-align: right;
  margin-top: 5px;
}
.nut-countup {
  width: auto;
  color: #940e05 !important;
}

.pop-signin .pop-t {
  height: 135px;
  padding-top: 20px;
  text-align: center;
  color: #fff;
}
.pop-signin .pop-t h4 {
  margin: 0;
  font-weight: 400;
  line-height: 28px;
  font-size: 20px;
}
.pop-signin .pop-t h4 span{
  font-size: 16px;
  color: #fff;
  opacity: .6;
}
.pop-signin .pop-t p {
  margin: 0;
  padding: 0;
}
.pop-signin .pop-t p strong {
  font-size: 36px;
  color: #ffe922;
  font-weight: 500;
  background-size: 35px auto;
}
.pop-signin .pop-b {
  background: #fff;
  height: 70px;
  border-radius: 0 0 12px 12px;
  padding: 20px;
  text-align: center;
}
.pop-signin .pop-b span{ margin-bottom:10px;  color: #24263d ;}
</style>

<style>
.task-item .nut-progress .nut-progress-insidetext {
  background: #1e246d !important;
}
.nut-progress-outer {
  margin-top: 4px;
}
.nut-progress .nut-progress-outer {
  background-color: #eaf2f8 !important;
}
.nut-progress .nut-progress-text {
  color: #1e246d !important;
  opacity: 0.5;
  padding: 0 !important;
}
.nut-countup .run-number {
  font-weight: 500 !important;
}
.pop-signin {
  width: 300px;
  padding: 0px;
  background: url(../assets/images/bg18.png) 0 0 no-repeat;
  background-size: 100% auto;
  background-color: transparent !important;
}
.task-item .b2 .nut-indicator .nut-indicator--dot {
  width: 30px;
  height: 30px;
  background: #fff url(../assets/images/20230601/img30.png);background-size: 30px;
}
.task-item .b2 .nut-indicator .nut-indicator--number {
  background-color: #004b9f !important;
  box-shadow: 0 0 1px 1px #004b9f !important;
  width: 30px; height: 30px; line-height: 30px; font-size: 20px;
}
.nut-button--small{ height: 28px;line-height: 12px !important;padding: 0 5px; min-width: 100px;}
</style>
