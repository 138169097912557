<template>
<div>
  <nut-navbar
    :left-show="false"
    :title="$t('agentGrowth.h1')"
    fixed=true
    class="mb0"
  >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>
  

  <div class="agentList">
    <div class="agentList-title">
      <h2>{{ $t('agentGrowth.h1_titile') }}</h2>
      <p class="p-info">
        {{$t('agentGrowth.bn1')}}{{maxNum}}{{$t('agentGrowth.bn2')}}{{maxRewardAmount}}{{$t('agentGrowth.bn3')}}
      </p>
    </div>

    
    <div :class="vipLevel === index+1 ? 'agentList-item active' : 'agentList-item'" v-for="(item, index) in entries" :key="index"  :taskId="item.taskId" :taskType="item.taskType">
      <div class="item-t lv1">
          <h3>{{item.entryTitle}}</h3>
          <span class="l">{{index+1}}</span>
          <span class="r" v-if="vipLevel > index+1">{{$t('taskReward.received')}}</span>
          <span class="r" v-if="vipLevel <= index+1">{{ $filters.currencySymbol(item.rewardAmount) }}</span>
      </div>
      <div class="item-b" >
        <div v-if="vipLevel === index+1">
          <!--<h4>{{item.taskTitle}} <span class="r">{{$t('agentGrowth.target')}}:{{ item.conditionValue }}</span></h4 -->
          <h4>{{$t('agentGrowth.h4_1')}}</h4>
          <p>{{$t('agentGrowth.h4_item1_1')}} <strong>{{processing.conditionValue}}</strong> {{$t('agentGrowth.h4_item1_2')}}</p>
          <h4>{{$t('agentGrowth.h4_2')}}</h4>
          <p>{{$t('agentGrowth.h4_item2_1')}} <strong>{{processing.conditionValue}}</strong>{{$t('agentGrowth.h4_item2_2')}}<strong>{{ $filters.currencySymbol(processing.rewardAmount) }}</strong> {{$t('agentGrowth.h4_item2_3')}}</p>

          <nut-progress
              :percentage="processing.progress"
              size="small"
              :stroke-color="bgc"
              style="margin:10px 0;width: 106%;"
            />
          <nut-button v-if="processing.status === 'processing'" block type="info" @click="goToTaskUrl(processing.conditionType)">{{$t('taskReward.completeTask')}}</nut-button>
          <nut-button v-if="processing.status === 'wait_receive'" block type="info" @click="receiveAward(processing.taskId, processing.serialNum ,processing.rewardAmount)">{{$t('taskReward.receiveReward')}}</nut-button>
          <nut-button v-if="processing.status === 'received'" block type="info" disabled=ture>{{$t('taskReward.received')}}</nut-button>
        </div>
      </div>
    </div>
    <nut-backtop></nut-backtop>
  </div>
  <nut-popup
      position="top"
      :z-index="100"
      :style="{ height: '21%', background:'#fff' }"
      @closed="topPopclose" 
      v-model:visible="taskPop"
    >
      <div class="pop-top">
        <h4>{{$t('taskReward.getBn')}}</h4>
        <p>
          <strong>+</strong><strong>{{ $filters.currencySymbol(taskAmount) }}</strong>
        </p>
      </div>
  </nut-popup>

</div>
</template>

<script>
import { reactive,toRefs,onMounted,getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import { getLastTimeGameName } from "@/assets/js/environment";

export default {
  data(){
    return{
      imgUrl:'',
      i18n:{
        loading: this.$t('global.loading'),
      }
    }
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      vipLevel:'',
      entries:[],
      processing:[],
      bgc: "#0a115c",
      taskPop: false,
      taskAmount: 0,
      startFlag: false,
      maxNum: '',
      maxRewardAmount: '',
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      receiveAward(id,num, amount){
        axios({
          url: "/api/task/receive-reward",
          method: "post",
          params: { taskId: id, serialNum: num }
        })
        .then((res) => {
          if (res.data.code === 2000) {
            Toast.fail(res.data.msg);
          } else {
            this.taskReceivePop(amount);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      taskReceivePop(a) {
        state.taskAmount = a;
        state.startFlag = true;
        state.taskPop = true;
        setTimeout(() => {
          state.startFlag = false;
          state.taskPop = false;
          location.reload()
        }, 1500);
      },
      goToTaskUrl(type) {
        switch (type) {
          case "first_recharge":
            router.push({ name: "rechargeBalance" }); //首次充值
            break;
          case "recharge_times":
            router.push({ name: "rechargeBalance" }); //充值次数达标
            break;
          case "recharge_amount":
            router.push({ name: "rechargeBalance" }); //充值金额达标
            break;
          case "first_invite":
            router.push({ name: "agentPromotion" }); //首次邀请
            break;
          case "invite_count":
            router.push({ name: "agentPromotion" }); //邀请人数达标
            break;
          case "first_withdraw":       //首次提现
            router.push({
              path: 'withdrawalBalance',
              query: {
                amountType: 'cash'
              }
            })
            break;
          case "first_effective_invite":
            router.push({ name: "agentPromotion" }); 
            break;
          case "effective_invite_count":
            router.push({ name: "agentPromotion" }); 
            break;
          default:
            router.push({ path: '/'+ getLastTimeGameName() });
        }
      }
    };

    onMounted(() => {
      methods.textToast(_data.data.i18n.loading);
      axios.get("/api/task/agent-growth", {}).then(function (res) {
          state.entries = res.data.data.entries
          state.processing = res.data.data.processing
          state.vipLevel = res.data.data.processing.serialNum
          state.maxNum = res.data.data.entries.length
          state.maxRewardAmount = res.data.data.entries[state.maxNum -1].rewardAmount
          Toast.hide("loading");
        }).catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.agentList {
  padding: 40px 10px;
  padding-bottom: 20px;
  background: #1b1c29 url(../assets/images/bg49.png) repeat-x;
  min-height: 700px;
  margin-top: -5px;
}
.agentList-title{background: url(../assets/images/20230601/img10.png) center 240px; background-size: 160%; padding: 10px 20px;}
.agentList-title h2{ text-align: center; font-size: 30px; font-weight: 600; color: #ffb93d; line-height: 40px; padding:0;}
.agentList-title .p-info{ padding: 0; line-height: 18px; color: #fff0b5;  margin-bottom: 10px; }
.agentList-title .p-info strong{ font-size: 20px; }
.img-w100{ width:100% ;}
.nut-navbar__left .nut-icon { color: #fff !important;}
.agentList-item{ margin-bottom: 15px;opacity: .5;}
.agentList-item.active{ opacity: 1;}
.agentList-item .item-t{ height: 60px;  background: #fff9e0; border-radius:5px; position: relative;box-shadow: 0 1px 20px #252c7f }
.agentList-item .item-t.lv1{background:#fff9e0 url(../assets/images/bg17-2.png) 5px center no-repeat; background-size:45px; }
.agentList-item .item-t h3{margin:0px 0 0 60px; font-weight: 300;padding-right: 67px; line-height: 20px; padding-top: 19px;}
.agentList-item .item-t span.r{ position: absolute;  line-height: 20px;color: #d23d0e; font-size: 22px;
  right: 8px;top: 8px; background: #ffedcd; padding: 10px;border-radius: 10px;}
.agentList-item .item-t span.l{ position: absolute; left: 13px; top:0; line-height: 56px; color: #fff; font-size: 20px;width: 30px;text-align: center;}
.agentList-item .item-b{ background: #fff;border-radius:0 0 5px 5px;  margin:-10px 0 0 0;box-shadow: 0 1px 7px #252c7f; padding: 20px 10px 10px 10px; }
.agentList-item .item-b h4{ padding: 5px 0px; margin: 5px 0; line-height: 20px;}
.agentList-item .item-b h4 .r{ float:right; font-weight: 400; }
.agentList-item .item-b p{padding: 0; margin: 5px 0; line-height: 20px;}

.nut-popup.popup-top{background:#ffe26f !important}
.popup-top {
  overflow: hidden;
}
.pop-top {
  background: url(../assets/images/bg80.jpg) -10px 40px no-repeat;
  height: 165px;
  background-size: 161px auto;
}
.pop-top h4 {
  padding-left: 130px;
  padding-top: 25px;
  margin-bottom: 0;
  font-size: 18px;
}
.pop-top p {
  padding-left: 130px;
  padding-top: 0;
  margin: 0;
}
.pop-top p strong {
  font-size: 36px;
  color: #940e05;
  font-weight: 500;
}
</style>

<style>
</style>
