<template>
  <div class="parity2">
    <p class="gg-info">{{ $t('global.slogan1') }}</p>

    <div class="rg-main ">

      <!--**************************************** 页头 ****************************************-->
      <div :class="topScroll ? 'rg-hd bg' : 'rg-hd'">
        <div class="c">
          <span class="title">{{ $t('PARITY.H1') }}</span>
          <span class="l" v-show="me.availableAmount !== 0">{{ $filters.currencySymbol(me.availableAmount) }}</span>
          <span class="r" @click="showForecaster = true"><i>?</i></span>
        </div>
      </div>
      
      <div :class="'rg-top topbg' + currentRoom.id">

        <!--**************************************** 房间名 ****************************************-->
        <div class="room-tabs">
          <div class="nav">
            <span class="a">Parity2</span>
            <span @click="changeRoom()">xxx1</span>
            <span @click="changeRoom()">xxx2</span>
          </div>
        </div>

        <!--**************************************** 倒计时面板 ****************************************-->
        <div class="hd-time ">
          <div class="c-3">
            <strong v-show="currentRoomGame.stage === this.stageEnum.bet">
              <nut-countdown :endTime="currentRoomGame.betDeadline" millisecond format="mm:ss"
                style="font-size:36px;color: #46c6d1;" />
            </strong>
            <strong v-show="currentRoomGame.stage === this.stageEnum.wait">
              <nut-countdown :endTime="currentRoomGame.waitDeadline" millisecond format="mm:ss"
                style="font-size:36px; color: #ffc745;" />
            </strong>

            <strong v-show="currentRoomGame.pause">
              <span style="font-size:24px;  color: #ffa6a6">{{ $t('game.paused') }}</span>
            </strong>

            <h6 v-if="currentRoomGame.stage === this.stageEnum.bet">{{ $t('PARITY.SELECT_TIME') }}</h6>
            <h6 v-else-if="currentRoomGame.stage === this.stageEnum.wait">{{ $t('PARITY.RESULT_TIME2') }}</h6>
            <h6 v-else>{{ $t('PARITY.PAUSED_TIME') }}</h6>
          </div>
        </div>

        <!--**************************************** 本局编号 ****************************************-->
        <div class="gm-number">
          <span>{{ getSerialNumPrefix(currentRoomGame.serialNum) }}<strong>{{
            getSerialNumPostfix(currentRoomGame.serialNum) }}</strong></span>
        </div>

        <!--**************************************** 我的下注 ****************************************-->
        <div class="timer-box mybet-box">
          <div class="countdown">
            <div class="c-3 c-3-left">
              <h6 v-show="myBet.totalBetAmount > 0">{{ $t('PARITY.MY_CHOICE') }}</h6>
              <ul v-show="myBet.totalBetAmount > 0">
                <li v-for="play in this.myBet.currentPlays" :key="play.id" :class="getBetTargetClass(play.betTarget)">
                  <strong>{{ getBetTargetContent(play.betTarget) }}</strong>
                </li>
              </ul>
            </div>

            <div class="c-3 c-3-right">
              <h6 v-show="myBet.totalBetAmount > 0">{{ $t('PARITY.MY_POINT') }}</h6>
              <span v-show="myBet.totalBetAmount > 0" class="c-y">{{ $filters.currencySymbol(myBet.totalBetAmount)
              }}</span>
            </div>
          </div>
        </div>

      </div>

      <div class="rg-body">

        <!--**************************************** 下注目标 ****************************************-->
        <div class="bet-container">
          <h5>{{ $t('PARITY.JOIN_COLOR') }}</h5>
          <ul class="c-3" style="margin-top: 10px;">
            <li class="g">
              <div v-show="currentRoomGame.stage === stageEnum.wait || me.vipLevel < currentRoom.enterVipLevel"
                class="disabled">
                <p v-show="me.vipLevel < currentRoom.enterVipLevel">Vip{{ currentRoom.enterVipLevel }}+ Unlock</p>
                <p v-show="me.vipLevel < currentRoom.enterVipLevel">&nbsp;</p>
              </div>
              <div class="p15" @click="callUpBetPanel(betColorEnum.green)">
                <strong>{{ $t('PARITY.JOIN_GREEN') }}</strong>
                <h5>1:2</h5>
              </div>

            </li>
            <li class="v">
              <div v-show="currentRoomGame.stage === stageEnum.wait || me.vipLevel < currentRoom.advancedVipLevel"
                class="disabled">
                <p v-show="me.vipLevel < currentRoom.advancedVipLevel">Vip{{ currentRoom.advancedVipLevel }}+ Unlock</p>
                <p v-show="me.vipLevel < currentRoom.advancedVipLevel" @click="vipPopupClick">&nbsp;</p>
              </div>
              <div class="p15" @click="callUpBetPanel(betColorEnum.violet)">
                <strong>{{ $t('PARITY.JOIN_VIOLET') }}</strong>
                <h5>1:4.5</h5>
              </div>
            </li>
            <li class="r">
              <div v-show="currentRoomGame.stage === stageEnum.wait || me.vipLevel < currentRoom.enterVipLevel"
                class="disabled">
                <p v-show="me.vipLevel < currentRoom.enterVipLevel">Vip{{ currentRoom.enterVipLevel }}+ Unlock</p>
                <p v-show="me.vipLevel < currentRoom.enterVipLevel">&nbsp;</p>
              </div>
              <div class="p15" @click="callUpBetPanel(betColorEnum.red)">
                <strong>{{ $t('PARITY.JOIN_RED') }}</strong>
                <h5>1:2</h5>
              </div>

            </li>
          </ul>
          <h5>{{ $t('PARITY.JOIN_NUMBER') }} 1:9</h5>
          <div class="c-5-div">
            <ul class="c-5">
              <li class="v v-0" @click="callUpBetPanel('0')"><strong>0</strong></li>
              <li class="g" @click="callUpBetPanel('1')"><strong>1</strong></li>
              <li class="r" @click="callUpBetPanel('2')"><strong>2</strong></li>
              <li class="g" @click="callUpBetPanel('3')"><strong>3</strong></li>
              <li class="r" @click="callUpBetPanel('4')"><strong>4</strong></li>
              <li class="v v-5" @click="callUpBetPanel('5')"><strong>5</strong></li>
              <li class="r" @click="callUpBetPanel('6')"><strong>6</strong></li>
              <li class="g" @click="callUpBetPanel('7')"><strong>7</strong></li>
              <li class="r" @click="callUpBetPanel('8')"><strong>8</strong></li>
              <li class="g" @click="callUpBetPanel('9')"><strong>9</strong></li>
            </ul>
            <div v-show="currentRoomGame.stage === stageEnum.wait || me.vipLevel < currentRoom.advancedVipLevel"
              class="disabled">
              <p v-show="me.vipLevel < currentRoom.advancedVipLevel">Vip{{ currentRoom.advancedVipLevel }}+ Unlock</p>
              <p v-show="me.vipLevel < currentRoom.advancedVipLevel" @click="vipPopupClick"> </p>
            </div>
          </div>
        </div>


        <!--**************************************** 连胜面板 ****************************************-->
        <div class="streak-box">
          <div class="t">
            <span>{{ $t('PARITY.Winning_Streak') }} : <strong>{{ me.streak }}</strong></span>
            <span v-if="(me.streak < currentRoomGame.streakExp.length) && (me.vipLevel !== 0)">{{ me.nextStreak }} {{
              $t('PARITY.more_wins_will_get') }} <strong>{{ $filters.currencySymbol(me.nextBonus) }}</strong></span>
            <span @click="streakRule"><i>?</i></span>
          </div>
          <div class="c">
            <ul>
              <li v-for="(s, index) in currentRoomGame.streakExp" :key="index" :class="streakClass(index, s)">
                <span>{{ index + 1 }}</span>
                <strong v-if="s > 0">{{ $filters.currencySymbol(s) }}</strong>
              </li>
            </ul>
          </div>
          <div class="bb" v-if="me.vipLevel < 4">Only For VIP4+</div>
          <!-- <div class="b" v-if="me.vipLevel === 0"></div> -->
        </div>


        <!--**************************************** 分析Tab 选项 ****************************************-->
        <div class="tabs tabs-3">
          <strong :class="resultTabActive === 1 ? 'a' : ''" @click="resultTabClick(1)">{{ $t('PARITY.RECORD') }}</strong>
          <strong :class="resultTabActive === 2 ? 'a' : ''" @click="resultTabClick(2)">{{ $t('PARITY.CONTINUOUS') }}</strong>
          <strong :class="resultTabActive === 3 ? 'a' : ''" @click="resultTabClick(3)">{{ $t('PARITY.PROBABILITY') }}</strong>
        </div>

        <div class="result-announcement">

          <!--**************************************** 历史记录 ****************************************-->
          <div v-show="resultTabActive === 1">
            <!--            <h5>Record&lt;!&ndash;<span class="r">more > </span>&ndash;&gt;</h5>-->
            <ul class="c-10">
              <li v-for="history in currentRoomGame.histories" :key="history.serialNum"
                :class="getResultClass(history.result)">
                <span>{{ history.shortSerialNum }}</span><strong>{{ history.result }}</strong>
              </li>
              <li class="x"><span>{{ getSerialNumPostfix(currentRoomGame.serialNum) }}</span><strong>?</strong></li>
            </ul>
          </div>

          <!--**************************************** 斩龙 ****************************************-->
          <div v-show="resultTabActive === 2">
            <ul class="ul-continuous">
              <li v-for="(continuous, index) in currentRoomGame.continuousList" :key="index"
                :class="getContinuousClass(continuous.type)">
                <h6 v-if="continuous.type === continuousTypeEnum.rv">R/V</h6>
                <h6 v-if="continuous.type === continuousTypeEnum.gv">G/V</h6>
                <div v-for="(continuousHistory, index) in continuous.histories" :key="index"
                  :class="getContinuousHistoryClass(continuousHistory.result)">
                  <strong>
                    {{ continuousHistory.result }}
                    <!--                    <i>New</i>-->
                  </strong>
                </div>
              </li>
            </ul>
          </div>

          <!--**************************************** 结果分布 ****************************************-->
          <div v-show="resultTabActive === 3">

            <div class="trend-main">
              <div class="trend-left">
                <svg width="100%" height="100%" style="position: absolute; top: 0px; left: 0px;">
                  <path v-for="(item, index) in resultNumData" :key="index" :d="item" fill="#7a248b" stroke="#7a248b"
                    stroke-width="1"></path>
                </svg>
                <div class="ball-ls" v-for="(item, index) in historyGame.list" :key="index">
                  <h6>{{ item.shortSerialNum }}</h6>
                  <strong><span :class="item.result === '1' ? 'g' : ''">1</span></strong>
                  <strong><span :class="item.result === '2' ? 'r' : ''">2</span></strong>
                  <strong><span :class="item.result === '3' ? 'g' : ''">3</span></strong>
                  <strong><span :class="item.result === '4' ? 'r' : ''">4</span></strong>
                  <strong><span :class="item.result === '5' ? 'v-5' : ''">5</span></strong>
                  <strong><span :class="item.result === '6' ? 'r' : ''">6</span></strong>
                  <strong><span :class="item.result === '7' ? 'g' : ''">7</span></strong>
                  <strong><span :class="item.result === '8' ? 'r' : ''">8</span></strong>
                  <strong><span :class="item.result === '9' ? 'g' : ''">9</span></strong>
                  <strong><span :class="item.result === '0' ? 'v-0' : ''">0</span></strong>
                </div>
              </div>
              <div class="trend-right">
                <svg width="100%" height="100%" style="position: absolute; top: 0px; left: 0px;">
                  <path v-for="(item, index) in resultColorData" :key="index" :d="item" fill="#7a248b" stroke="#7a248b"
                    stroke-width="1"></path>
                </svg>
                <div class="ball-ls" v-for="(item, index) in historyGame.list" :key="index">
                  <strong v-if="item.result === '1'"><span class="g">G</span></strong>
                  <strong v-else-if="item.result === '3'"><span class="g">G</span></strong>
                  <strong v-else-if="item.result === '7'"><span class="g">G</span></strong>
                  <strong v-else-if="item.result === '9'"><span class="g">G</span></strong>
                  <strong v-else><span>G</span></strong>
                  <strong v-if="item.result === '5'"><span class="v-5">V</span></strong>
                  <strong v-else-if="item.result === '0'"><span class="v-0">V</span></strong>
                  <strong v-else><span>V</span></strong>
                  <strong v-if="item.result === '2'"><span class="r">R</span></strong>
                  <strong v-else-if="item.result === '4'"><span class="r">R</span></strong>
                  <strong v-else-if="item.result === '6'"><span class="r">R</span></strong>
                  <strong v-else-if="item.result === '8'"><span class="r">R</span></strong>
                  <strong v-else><span>R</span></strong>
                </div>
              </div>
            </div>

            <div class="more-btn" v-show="historyGame.hasNext" @click="showMoreResult">{{ $t('PARITY.NEXT_PAGE') }} >>
            </div>

          </div>

          <!--**************************************** vip规则 升级vip 按钮 ****************************************-->
          <div v-show="rechargeBtnBox" class="recharge-btn-box">
            <nut-button size="small" plain type="info" style="min-width: 105px;height: 25px;padding:0;"
              @click="vipPopupClick">
              {{ $t('home.vipDescription') }}
            </nut-button>
            <nut-button size="small" type="info" class="ml5" style="min-width: 105px;height: 25px;padding:0;"
              @click="rechargeClick()">
              {{ $t('PARITY.UPGRADE') }}
            </nut-button>
          </div>
          
        </div>

        <!--**************************************** 订单Tab 选项 ****************************************-->
        <div class="tabs tabs-2">
          <strong :class="orderTabActive ? 'a' : ''" @click="playersList">{{ $t('PARITY.EVERYONE_ORDER') }}</strong>
          <strong :class="orderTabActive ? '' : 'a'" @click="myHistoryList">{{ $t('PARITY.MY_ORDER') }}</strong>
        </div>
        
        <div class="result-announcement">
          <div class="g-players">

            <!--**************************************** 所有玩家的订单 ****************************************-->
            <ul v-show="orderTabActive">
              <li>
                <span class="l" style="width:130px;">{{ $t('PARITY.USER') }}</span>
                <span class="l" style="width:70px;">{{ $t('PARITY.SELECT') }}</span>
                <span class="r">{{ $t('PARITY.POINT') }}</span>
              </li>
              <li v-for="(item, index) in usersBetList" :key="index">
                <span class="l" style="width:130px"><img
                    :src="require('../assets/images/' + imgUrl + 'avatar/' + item.avatar.slice(item.avatar.length - 6))" />{{
                      item.mobile }}</span>

                <span class="l" style="width:70px;">
                  <div v-if="item.betType === 'number' && item.betTarget !== '0' && item.betTarget !== '5'"
                    :class="item.betTarget === '1' || item.betTarget === '3' || item.betTarget === '7' || item.betTarget === '9' ? 'dots g' : 'dots r'">
                    <strong>{{ item.betTarget }}</strong>
                  </div>
                  <div v-if="item.betType === 'number' && item.betTarget === '0'" class="dots v-0">
                    <strong>{{ item.betTarget }}</strong>
                  </div>
                  <div v-if="item.betType === 'number' && item.betTarget === '5'" class="dots v-5">
                    <strong>{{ item.betTarget }}</strong>
                  </div>
                  <div v-if="item.betType === 'color' && item.betTarget === 'Green'" class="dots g">
                    <strong>G</strong>
                  </div>
                  <div v-if="item.betType === 'color' && item.betTarget === 'Violet'" class="dots v">
                    <strong>V</strong>
                  </div>
                  <div v-if="item.betType === 'color' && item.betTarget === 'Red'" class="dots r">
                    <strong>R</strong>
                  </div>
                </span>
                <span class="r" style="color: #834a00;">{{ $filters.currencySymbol(item.originBetAmount) }}</span>
              </li>
            </ul>

            <!--**************************************** 我的订单 ****************************************-->
            <ul v-show="!orderTabActive">
              <li>
                <span class="l" style="width:110px;">{{ $t('PARITY.SERIAL_NUM') }}</span>
                <span class="l" style="width:40px;">{{ $t('PARITY.POINT') }}</span>
                <span class="l" style="width:40px;">{{ $t('PARITY.SELECT') }}</span>
                <span class="l" style="width:40px;">{{ $t('PARITY.RESULT') }}</span>
                <span class="r">{{ $t('PARITY.AMOUNT') }}</span>
              </li>

              <li v-for="myPlay in myPlays" :key="myPlay.id" class="mb10">
                <span class="l" style="width:110px">{{ myPlay.serialNum }}</span>
                <span class="l" style="width:40px">{{ $filters.currencySymbol(myPlay.originBetAmount) }}</span>
                <span class="l" style="width:40px">
                  <div :class="'dots ' + getBetTargetClass(myPlay.betTarget)">
                    <strong>{{ getBetTargetContent(myPlay.betTarget) }}</strong>
                  </div>
                </span>
                <span class="l" style="width:40px;">
                  <div :class="'dots ' + getResultClass(myPlay.result)">
                    <strong v-if="getResultClass(myPlay.result) !== 'x'">{{ myPlay.result }}</strong>
                    <strong v-else>?</strong>
                  </div>
                </span>
                <span v-if="myPlay.win" class="r" style="color: #015c00;">+ {{ $filters.currencySymbol(myPlay.winAmount)
                }}</span>
                <span v-else class="r" style="color: #015c00;">&nbsp;&nbsp;</span>
                <div v-if="myPlay.btcPrice > 0" class="ls-w100">
                  {{ $t('PARITY.RESULT_TIME') }}: {{ myPlay.btcResultTime }} &nbsp; &nbsp; {{ $t('PARITY.BTC_PRICE') }}:
                  <strong>${{ myPlay.btcPrice }}</strong>
                </div>
              </li>
              <li style="text-align: center;" @click="myPlayList">{{ $t('game.more') }} >></li>

            </ul>

          </div>
        </div>
      </div>


      <!--**************************************** 进场遮罩 ****************************************-->
      <nut-overlay v-model:visible="overlayShow" :close-on-click-overlay="false" :z-index="2000"
        overlay-class="overlay-div overlay-bg0">
      </nut-overlay>

    </div>

    <!--**************************************** 玩法说明 ****************************************-->
    <nut-popup round v-model:visible="showForecaster" pop-class="pop-forecaster parity2">
      <div class="pop-forecaster-bd">
        <ul class="tabs">
          <li @click="tabsClick(); tabs1 = true;" :class="tabs1 ? 'a' : ''">{{ $t('gameRulesParity.h1') }}</li>
          <li @click="tabsClick(); tabs2 = true;" :class="tabs2 ? 'a' : ''">{{ $t('PARITY.INVESTMENT_SKILLS') }}</li>
          <li @click="tabsClick(); tabs3 = true;" :class="tabs3 ? 'a' : ''">{{ $t('PARITY.RESULTS_ORIGIN') }}</li>
        </ul>
        <div class="tab-bd">
          <div class="tabs-item" v-show="tabs1">
            <dl class="play-rule">
              <dt>{{ $t('gameRulesParity.dt1') }}</dt>
              <dd>{{ $t('gameRulesParity.dd1_1') }}</dd>
              <dd>{{ $t('gameRulesParity.dd1_2') }}</dd>
              <dd style="color:#077942">{{ $t('gameRulesParity.dd1_3') }}</dd>
              <dd>{{ $t('gameRulesParity.dd1_4') }}</dd>
              <dd style="color:#be3816">{{ $t('gameRulesParity.dd1_5') }}</dd>
              <dd>{{ $t('gameRulesParity.dd1_6') }}</dd>
              <dd style="color:#4844b4">{{ $t('gameRulesParity.dd1_7') }}</dd>
              <dd>{{ $t('gameRulesParity.dd1_8') }}</dd>
              <dd>{{ $t('gameRulesParity.dd1_9') }}</dd>
              <dd>{{ $t('gameRulesParity.dd1_10') }}</dd>

              <dt>{{ $t('gameRulesParity.dt2') }}</dt>
              <dd>{{ $t('gameRulesParity.dd2_1') }}</dd>
              <dd>{{ $t('gameRulesParity.dd2_2') }}</dd>
              <dd style="color:#077942">{{ $t('gameRulesParity.dd2_3') }}</dd>
              <dd>{{ $t('gameRulesParity.dd2_4') }}</dd>
              <dd style="color:#be3816">{{ $t('gameRulesParity.dd2_5') }}</dd>
              <dd>{{ $t('gameRulesParity.dd2_6') }}</dd>
              <dd style="color:#4844b4">{{ $t('gameRulesParity.dd2_7') }}</dd>
              <dd>{{ $t('gameRulesParity.dd2_8') }}</dd>
              <dd>{{ $t('gameRulesParity.dd2_9') }}</dd>
              <dd>{{ $t('gameRulesParity.dd2_10') }}</dd>
            </dl>
          </div>

          <div class="tabs-item" v-show="tabs2">
            <h4>{{ $t('PARITY.INVESTMENT_SKILLS') }}</h4>
            <p>{{ $t('PARITY.INVESTMENT_SKILLS_TEXT_1') }}</p>
            <br />

            <p><strong>{{ $t('PARITY.INVESTMENT_SKILLS_TEXT_2') }}</strong></p>
            <p>{{ $t('PARITY.INVESTMENT_SKILLS_TEXT_3') }}</p>
            <p>{{ $t('PARITY.INVESTMENT_SKILLS_TEXT_4') }}</p>

            <table>
              <tr>
                <th rowspan="2" style="width:80px; background: #f5d2b8;">{{ $t('PARITY.INITIAL_BALANCE') }}</th>
                <th colspan="6">{{ $t('PARITY.INVESTMENT_PLAN') }}</th>
                <th rowspan="2" style="width:80px; background: #f5d2b8;">{{ $t('PARITY.TOTAL_ASSETS') }}</th>
              </tr>
              <tr>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
                <th>...</th>
              </tr>
              <tr>
                <td>{{ commonInfo.currencySymbol }}10</td>
                <td>{{ commonInfo.currencySymbol }}10</td>
                <td>{{ commonInfo.currencySymbol }}30</td>
                <td>{{ commonInfo.currencySymbol }}90</td>
                <td>{{ commonInfo.currencySymbol }}270</td>
                <td>{{ commonInfo.currencySymbol }}810</td>
                <td>...</td>
                <td>{{ commonInfo.currencySymbol }}1210</td>
              </tr>
              <tr>
                <td>{{ commonInfo.currencySymbol }}50</td>
                <td>{{ commonInfo.currencySymbol }}50</td>
                <td>{{ commonInfo.currencySymbol }}150</td>
                <td>{{ commonInfo.currencySymbol }}450</td>
                <td>{{ commonInfo.currencySymbol }}1350</td>
                <td>{{ commonInfo.currencySymbol }}4050</td>
                <td>...</td>
                <td>{{ commonInfo.currencySymbol }}6050</td>
              </tr>
              <tr>
                <td>{{ commonInfo.currencySymbol }}100</td>
                <td>{{ commonInfo.currencySymbol }}100</td>
                <td>{{ commonInfo.currencySymbol }}300</td>
                <td>{{ commonInfo.currencySymbol }}900</td>
                <td>{{ commonInfo.currencySymbol }}2700</td>
                <td>{{ commonInfo.currencySymbol }}8100</td>
                <td>...</td>
                <td>{{ commonInfo.currencySymbol }}12100</td>
              </tr>
            </table>
            <br />
            <p><strong>{{ $t('PARITY.INVESTMENT_SKILLS_TEXT_5') }}</strong></p>
            <p>{{ $t('PARITY.INVESTMENT_SKILLS_TEXT_6') }}</p>
            <p @click="telegramClick()" style="color:#2c3c78">{{ $t('PARITY.INVESTMENT_SKILLS_TEXT_7') }}</p>

          </div>

          <div class="tabs-item" v-show="tabs3">
            <h4>{{ $t('gameRulesParity.dt3') }}</h4>
            <p>{{ $t('gameRulesParity.dd3_1') }}{{ $t('gameRulesParity.dd3_2') }}</p>
          </div>
        </div>
      </div>
    </nut-popup>

    <!--**************************************** 下注面板 ****************************************-->
    <nut-popup position="bottom" closeable round v-model:visible="showBetPanel" pop-class="betPanelBox"
      @close="bettingPanelClose">
      <div class="betting-panel" :class="bettingPanelBG">
        <h2>{{ $t('PARITY.JOIN') }} <strong class="c-g">{{ myBet.betTarget }}</strong>
          <span v-if="myBet.betTarget === 'Red' || myBet.betTarget === 'Green'" class="btn-f12">1:2</span>
          <span v-else-if="myBet.betTarget === 'Violet'" class="btn-f12">1:4.5</span>
          <span v-else class="btn-f12">1:9</span>
        </h2>
        <div class="panel-hd">
          <div class="l">
            <span>{{ $t('PARITY.BALANCE') }}:</span>
            <br />
            <strong>{{ $filters.currencySymbol(parseAmount(me.availableAmount)) }}</strong>
            <nut-button size="small" type="primary" @click="rechargeClick" style="min-winth:70px">
              {{ $t('home.recharge') }}
            </nut-button>
          </div>
          <div class="r">
            <li v-if="calculateFee(myBet.betAmount)">
              <span>{{ $t('PARITY.FEE') }}({{ currentRoom.feeRatio }}%):</span>
              <strong>{{ calculateFee(myBet.betAmount) }}</strong>
            </li>
            <li v-if="calculateActualBetAmount(myBet.betAmount)"><span>{{ $t('PARITY.AMOUNT') }}:</span><strong>{{
              calculateActualBetAmount(myBet.betAmount) }}</strong></li>
          </div>
        </div>

        <div class="panel-bd">
          <h6>{{ $t('PARITY.CONTRACT_POINT') }}</h6>

          <div class="btnbox">
            <button v-for="(item, index) in currentRoom.recommendBetAmountList" :key="index" class="btn"
              @click="btnBetClick(item)"
              :disabled="this.currentRoomGame.stage !== stageEnum.bet || (myBet.nextBetAmount !== null && myBet.nextBetAmount !== item)">{{
                item }}
            </button>
            <button class="btn" @click="btnBetClick(me.availableAmount)"
              :disabled="this.currentRoomGame.stage !== stageEnum.bet || myBet.nextBetAmount !== null">{{ $t('PARITY.ALL')
              }}
            </button>
          </div>
          <h6>{{ $t('PARITY.NUMBER') }} <span v-show="myBet.nextBetAmount" class="c">( {{ $t('PARITY.VIP1_INPUT_FREELY')
          }} )</span></h6>
          <div class="inpbox">
            <button class="btn-bet btn-sub" @click="btnSubtract"
              :disabled="this.currentRoomGame.stage !== stageEnum.bet || myBet.nextBetAmount !== null">
              -
            </button>
            <input v-model="myBet.betAmount" class="inp-bet" type="number" @click="showKeyBoard" />
            <button class="btn-bet btn-plus" @click="btnAddition"
              :disabled="this.currentRoomGame.stage !== stageEnum.bet || myBet.nextBetAmount !== null">
              +
            </button>
          </div>

          <div v-if="myBet.nextBetAmount && rookieSteps !== null" class="popover">
            <h6>{{ $t('PARITY.VIP0_ROOKIE_GUIDE') }}</h6>
            <p>{{ $t('PARITY.FOLLOW_ADVICE_AND_YOU_WIN_FINALLY') }}</p>
            <table>
              <tr>
                <th>{{ $t('PARITY.INPUT') }}</th>
                <th>{{ $t('PARITY.LOSE') }}</th>
                <th>{{ $t('PARITY.WIN') }}</th>
              </tr>
              <tr>
                <td>{{ rookieSteps[0] }}</td>
                <td>-{{ rookieSteps[0] }}</td>
                <td>{{ rookieSteps[0] }}*2-{{ rookieSteps[0] }} = <strong>{{ rookieSteps[0] * 2 - rookieSteps[0]
                }}</strong></td>
              </tr>
              <tr>
                <td>{{ rookieSteps[1] }}</td>
                <td>-{{ rookieSteps[1] }}</td>
                <td>{{ rookieSteps[1] }}*2-{{ rookieSteps[1] }}-{{ rookieSteps[0] }} = <strong>{{ rookieSteps[1] * 2 -
                  rookieSteps[1] - rookieSteps[0] }}</strong></td>
              </tr>
              <tr>
                <td>{{ rookieSteps[2] }}</td>
                <td>-{{ rookieSteps[2] }}</td>
                <td>{{ rookieSteps[2] }}*2-{{ rookieSteps[2] }}-{{ rookieSteps[1] }}-{{ rookieSteps[0] }} = <strong>{{
                  rookieSteps[2] * 2 - rookieSteps[2] - rookieSteps[1] - rookieSteps[0] }}</strong></td>
              </tr>
            </table>
            <p class="r">{{ $t('PARITY.SO_THIS_ROUND_SHOULD_INPUT') }} <strong>{{ myBet.nextBetAmount }}</strong></p>
          </div>

          <div class="btn-confirm">
            <nut-button @click="bet" block type="info" class="btn-bg-g"
              :disabled="this.currentRoomGame.stage !== stageEnum.bet">
              <nut-icon v-if="myBet.betting" name="loading" size="25" style="margin-top: 15px;"></nut-icon>
              <span v-else>{{ $t('PARITY.CONFIRM') }} {{ $filters.currencySymbol(myBet.betAmount) }}</span>
            </nut-button>
          </div>
        </div>
      </div>


    </nut-popup>

    <!--**************************************** 结算面板 ****************************************-->
    <nut-popup pop-class="pop-inventory" v-model:visible="showSettlePanel" @close="closeSettlePanel">
      <div :class="settle.totalWinAmount > 0 ? 'main win' : 'main'">
        <div class="hd">
          <strong :class="getResultClass(settle.result)">
            {{ settle.result }}
          </strong>
        </div>
        <ul>
          <li><span>{{ $t('PARITY.PERIOD') }}</span><strong>{{ settle.serialNum }}</strong></li>
          <li v-show="settle.btcPrice > 0"><span>{{ $t('PARITY.RESULT_TIME') }}</span><strong>{{ settle.btcResultTime
          }}</strong></li>
          <li v-show="settle.btcPrice > 0"><span>{{ $t('PARITY.BTC_PRICE') }}</span><strong>${{ settle.btcPrice
          }}</strong></li>
          <li>
            <span>{{ $t('PARITY.WIN') }}</span>
            <strong v-if="settle.totalWinAmount > 0" class="c-g">+{{ $filters.currencySymbol(settle.totalWinAmount)
            }}</strong>
            <strong v-else class="c-r">+{{ $filters.currencySymbol(settle.totalWinAmount) }}</strong>
          </li>
          <li v-if="me.streak > 0" class="streak-li">
            <span>{{ $t('PARITY.streak') }}</span><strong>{{ me.streak }}</strong>
          </li>
          <li v-if="currentRoomGame.streakExp[me.streak - 1] > 0" class="streak-li2">
            <span>{{ $t('PARITY.streakBonus') }}</span><strong>{{
              $filters.currencySymbol(currentRoomGame.streakExp[me.streak - 1]) }}</strong>
          </li>
        </ul>
        <div class="bd">
          <div v-for="play in settle.plays" :key="play.id" class="bd-c">
            <ol>
              <li>
                <span>{{ $t('PARITY.SELECT') }}</span>
                <strong><span :class="getBetTargetClass(play.betTarget)">
                    {{ getBetTargetContent(play.betTarget) }}
                  </span></strong>
              </li>
              <li><span>{{ $t('PARITY.POINT') }}</span><strong>{{ $filters.currencySymbol(play.originBetAmount)
              }}</strong></li>
              <li>
                <span>{{ $t('PARITY.WIN') }}</span>
                <strong v-if="play.winAmount > 0" class="c-g">+{{ $filters.currencySymbol(play.winAmount) }}</strong>
                <strong v-else class="c-r">+{{ $filters.currencySymbol(play.winAmount) }}</strong>
              </li>
            </ol>
          </div>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="closeSettlePanel">{{ $t('global.okText') }}</nut-button>
        </div>
      </div>
    </nut-popup>

    <!--**************************************** 卡点弹框 ****************************************-->
    <nut-popup pop-class="pop-withdrawal" v-model:visible="withdrawalPopup">
      <div class="hd">{{ $t('game.pleaseWithdraw1') }}<strong style="color:#208f0a">{{ commonInfo.forceWithdrawThreshold
      }}+ </strong></div>
      <div class="bd">
        <strong>{{ $t('game.pleaseWithdraw2') }}</strong>{{ $t('game.pleaseWithdraw3') }}
      </div>
      <div class="btn">
        <nut-button type="primary" @click="withdrawClick">{{ $t('game.go') }}</nut-button>
      </div>
    </nut-popup>

    <!--**************************************** 补满提醒弹框 ****************************************-->
    <nut-popup pop-class="pop-invite" v-model:visible="invitePopup">
      <div class="hd">{{ $t('game.info1') }}</div>
      <div class="bd">
        {{ $t('game.info2') }}<br />
        <strong>{{ commonInfoAttr('registerReward') }}</strong><br />
      </div>
      <div class="app-task" v-show="appTaskStatus === 'forbid' || appTaskStatus === 'can_receive'" @click="downloadApp">
        {{ $t('global.DOWNLOAD_APP_TEXT2') }}
        <span>{{ $filters.currencySymbol(appTaskReward) }}</span>
        {{ $t('global.DOWNLOAD_APP_TEXT3') }}
      </div>
      <div class="btn">
        <nut-button type="primary" @click="invitePopup = false">{{ $t('game.gotitBtn') }}</nut-button>
      </div>

    </nut-popup>

    <!--**************************************** 游戏规则 ****************************************-->
    <nut-popup position="top" closeable round :style="{ height: '70%' }" v-model:visible="rulePopup">
      <GameRulesParity></GameRulesParity>
    </nut-popup>

    <!--**************************************** vip权益 ****************************************-->
    <nut-popup pop-class="pop-vip" v-model:visible="vipPopup">
      <VipRules :myLevel="me.vipLevel" :popTitle="popTitle" :tabActive="tabActive"></VipRules>
      <div class="login-btn m10">
        <nut-button block type="info" @click="rechargeClick">{{ $t('recharge.levelUpVip') }}</nut-button>
      </div>
    </nut-popup>

    <!--**************************************** tg 提醒 ****************************************-->
    <nut-popup pop-class="pop-vip pop-vip-lobby" v-model:visible="vipTgDialog" :z-index="100">
      <VipRules :myLevel="me.vipLevel" :popTitle="popTitle"></VipRules>
      <div class="bd">
        {{ $t('global.Prediction_Channel_Text') }}<br />
      </div>
      <div class="btn" style="margin: 0;">
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="vipTgDialog = false">{{ $t('global.Got_It')
        }}</nut-button>
        <nut-button style="width: 48%;margin: 0 1%;" type="primary" @click="rechargeClick">{{ $t('global.Upgrade')
        }}</nut-button>
      </div>
    </nut-popup>

    <!--**************************************** 结果展示 ****************************************-->
    <nut-popup pop-class="pop-top-result" position="left" :overlay="false" :style="{ height: '36px' }"
      v-model:visible="showTopResult">
      <div class="bd">
        <div class="t1">
          <strong :class="getResultClass(topResult.result)">
            {{ topResult.result }}
          </strong>
        </div>
        <div class="t2">
          <div><span>{{ $t('PARITY.PERIOD') }}</span><strong>{{ getSerialNumPostfix(topResult.serialNum) }}</strong></div>
          <div v-show="topResult.btcResultTime"><span>{{ $t('PARITY.RESULT_TIME') }}</span><strong>{{
            topResult.btcResultTime }}</strong></div>
          <div v-show="topResult.btcPriceLeft || topResult.btcPriceRight">
            <span>{{ $t('PARITY.BTC_PRICE') }}</span>
            <strong :class="'tx1 ' + 'c-' + topResult.btcPriceColor">${{ topResult.btcPriceLeft }}</strong>
            <strong :class="'tx2 ' + 'c-' + topResult.btcPriceColor">{{ topResult.btcPriceRight }}</strong>
          </div>
        </div>
      </div>
    </nut-popup>

    <!--**************************************** Big win ****************************************-->
    <nut-popup pop-class="pop-top-bigwin" v-model:visible="bigwinData.show" position="left" :closeable="true"
      :overlay="false">
      <div class="bd">
        <div class="t1"><img class="avatar" :src="require('../assets/images/bigwin.png')">
          <span>{{ bigwinData.data.mobile }}</span>
        </div>
        <div v-if="bigwinData.data.appTypeCode === 'crash'" class="t2 crash"><span>{{ bigwinData.data.crashEscapeRatio
        }}x</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'parity'" class="t2 parity"><span>{{
          bigwinData.data.parityRoomName }}</span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'wheel'" class="t2 wheel"><span> </span></div>
        <div v-else-if="bigwinData.data.appTypeCode === 'mine'" class="t2 mine"><span>{{ bigwinData.data.mineRowCount }} X
            {{ bigwinData.data.mineRowCount }}</span></div>
        <div class="t3"><span>{{ $t('PARITY.CONGRSTS_XXX_WIN_XXX_2') }}</span><strong>{{ bigwinData.data.winAmount
        }}</strong></div>
      </div>
    </nut-popup>

    <!--**************************************** Broadcast Msg ****************************************-->
    <nut-popup pop-class="pop-top-broadcast-msg" v-model:visible="broadcastMsgData.show" position="left" :closeable="true"
      :overlay="false" style="top: 5px !important;">
      <div class="bd">
        <div class="t4" v-if="broadcastMsgData.type === 'parity_prediction'">&nbsp;</div>
        <div class="t6" v-else>&nbsp;</div>
        <div class="t5"><span>{{ broadcastMsgData.message }}</span></div>
      </div>
    </nut-popup>

    <tabbar v-show="!hideMenu" :activeIndex="tabbarActiveIndex"></tabbar>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import { Toast, Dialog } from "@nutui/nutui";
import { getValue, isBlank, isNotBlank, isTrue } from "@/assets/js/commonUtil";
import router from "@/router";
import { commonEvent, createSocket, disconnectSocket, gameType } from "@/assets/js/game";
import axios from "axios";
import { getCommonInfoAttr } from "@/assets/js/commonInfo";
import GameRulesParity from "../components/GameRulesParity.vue";
import VipRules from "../components/VipRules2.vue";
import { getCountry, setLastTimeGameName } from "@/assets/js/environment";

export default {
  name: "parityHome2",
  components: {
    tabbar: TabbarMain,
    GameRulesParity: GameRulesParity,
    VipRules: VipRules
  },
  data() {
    return {
      imgUrl: '',
      commonInfo: null,
      country: '',
      template: 's1',
      tabbarActiveIndex: 2,
      tabs1: true,
      tabs2: false,
      tabs3: false,
      tabs4: false,
      showForecaster: false, //帮助说明
      vipTgDialog: false, //vip说明
      hideMenu: false, //隐藏导航菜单 防止遮挡下注
      topScroll: false,
      rookieSteps: null, //新手流程下注
      currentRoom: {
        
      },
      config: {
        doubleBetAddAmount: 0
      },
      

      lotteryNumber: '?',
      popTitle: this.$t('recharge.popTitle_1'),
      tabActive: 'parity',
      rulePopup: false,
      vipPopup: false,
      rechargeBtnBox: false,
      showBetPanel: false,
      showSettlePanel: false,
      orderTabActive: true,
      resultTabActive: 1,
      bettingPanelBG: '',
      showAnimation1: false,
      showAnimation2: false,
      keyBoard: false,
      listNoData: true,
      showTopResult: false,
      overlayShow: true,
      topResult: {
        serialNum: null,
        result: null,
        btcPrice: null,
        btcPriceLeft: null,
        btcPriceRight: null,
        btcResultTime: null,
        btcPriceColor: null,
      },
      betDeadline: Date.now() + 30 * 1000,
      userRechargeList: [],
      historyGame: {
        current: 1,
        total: null,
        hasNext: null,
        list: []
      },
      bigwinData: {
        show: false,
        start: false,
        list: [],
        data: {}
      },
      broadcastMsgData: {
        show: false,
        type: '',
        message: '',
      },
      resultNumData: [],
      resultColorData: [],
      numPositionDara: [
        [['47 20', '67 20', '90 20', '110 20', '130 20', '150 20', '171 20', '191 20', '213 20', '235 20'],
        ['47 37', '67 37', '90 37', '110 37', '130 37', '150 37', '171 37', '191 37', '213 37', '235 37']],
        [['47 57', '67 57', '90 57', '110 57', '130 57', '150 57', '171 57', '191 57', '213 57', '235 57'],
        ['47 74', '67 74', '90 74', '110 74', '130 74', '150 74', '171 74', '191 74', '213 74', '235 74']],
        [['47 94', '67 94', '90 94', '110 94', '130 94', '150 94', '171 94', '191 94', '213 94', '235 94'],
        ['47 111', '67 111', '90 111', '110 111', '130 111', '150 111', '171 111', '191 111', '213 111', '235 111']],
        [['47 131', '67 131', '90 131', '110 131', '130 131', '150 131', '171 131', '191 131', '213 131', '235 131'],
        ['47 148', '67 148', '90 148', '110 148', '130 148', '150 148', '171 148', '191 148', '213 148', '235 148']],
        [['47 168', '67 168', '90 168', '110 168', '130 168', '150 168', '171 168', '191 168', '213 168', '235 168'],
        ['47 185', '67 185', '90 185', '110 185', '130 185', '150 185', '171 185', '191 185', '213 185', '235 185']],
        [['47 205', '67 205', '90 205', '110 205', '130 205', '150 205', '171 205', '191 205', '213 205', '233 205'],
        ['47 223', '67 223', '90 223', '110 223', '130 223', '150 223', '171 223', '191 223', '213 223', '233 223']],
        [['47 242', '67 242', '90 242', '110 242', '130 242', '150 242', '171 242', '191 242', '213 242', '233 242'],
        ['47 260', '67 260', '90 260', '110 260', '130 260', '150 260', '171 260', '191 260', '213 260', '233 260']],
        [['47 279', '67 279', '90 279', '110 279', '130 279', '150 279', '171 279', '191 279', '213 279', '233 279'],
        ['47 297', '67 297', '90 297', '110 297', '130 297', '150 297', '171 297', '191 297', '213 297', '233 297']],
        [['47 316', '67 316', '90 316', '110 316', '130 316', '150 316', '171 316', '191 316', '213 316', '233 316'],
        ['47 334', '67 334', '90 334', '110 334', '130 334', '150 334', '171 334', '191 334', '213 334', '233 334']],
        [['00 00', '00 00', '00 00', '00 00', '00 00', '00 00', '00 00', '00 00', '00 00', '00 00'],
        ['00 00', '00 00', '00 00', '00 00', '00 00', '00 00', '00 00', '00 00', '00 00', '00 00']],
      ],
      colorPositionDara: [
        [['10 20', '31 20', '53 20'], ['10 37', '31 37', '53 37']],
        [['10 57', '31 57', '53 57'], ['10 74', '31 74', '53 74']],
        [['10 94', '31 94', '53 94'], ['10 111', '31 111', '53 111']],
        [['10 131', '31 131', '53 131'], ['10 148', '31 148', '53 148']],
        [['10 168', '31 168', '53 168'], ['10 185', '31 185', '53 185']],
        [['10 205', '31 205', '53 205'], ['10 223', '31 223', '53 223']],
        [['10 242', '31 242', '53 242'], ['10 260', '31 260', '53 260']],
        [['10 279', '31 279', '53 279'], ['10 297', '31 297', '53 297']],
        [['10 316', '31 316', '53 316'], ['10 334', '31 334', '53 334']],
        [['00 00', '00 00', '00 00'], ['00 00', '00 00', '00 00']],
      ],
      
      currentRoomGame: {
        pause: false,
        serialNum: null,
        betDeadline: null,
        waitDeadline: null,
        stage: null,
        result: null,
        histories: [],
        continuousList: [],
        defaultBetAmount: 20,
        minBetAmount: 10,
        maxBetAmount: 100000,
        streakExp: [],
      },
      settle: {
        serialNum: null,
        result: null,
        plays: [],
        totalWinAmount: 0,
        totalProfitAmount: 0,
        needWithdraw: false,
        btcPrice: 0,
        btcResultTime: null,
      },
      withdrawalPopup: false,
      showDialogFill: true,
      invitePopup: false,
      socket: null,
      binanceWebsocket: null,
      btcPrice: null,
      //event
      eventEnum: {
        initRoom: "s_pa_init_room", //初始化房间
        startBet: "s_pa_start_bet", //开始下注阶段
        startWait: "s_pa_start_wait", //开始等待阶段
        end: "s_pa_end", //本局结束
        playerInfo: "s_pa_player", //当前用户的玩家信息
        pause: "s_pa_pause", //游戏暂停
        betNotify: "s_pa_bet", //任意玩家的下注通知
        settle: "s_pa_settle", //结算信息
        prediction: 's_pa_prediction' //预测
      },
      //游戏阶段
      stageEnum: {
        bet: "bet",
        wait: "wait",
        pause: "pause"
      },
      //下注类型
      betTypeEnum: {
        color: "color",
        number: "number"
      },
      //下注颜色
      betColorEnum: {
        red: "Red",
        green: "Green",
        violet: "Violet"
      },
      //斩龙类型
      continuousTypeEnum: {
        rv: "Red_Violet",
        gv: "Green_Violet",
      },
      //我的信息
      me: {
        id: null,
        vipLevel: 0,
        availableAmount: 0,
        rechargeAmount: null,
        withdrawAmount: null,
        //是否处于新手流程
        inRookieProcess: null,
        //连胜
        streak: 0,
        nextStreak: 0,
        nextBonus: 0,
      },
      //下注操作
      myBet: {
        betType: null,
        betTarget: null,
        nextBetAmount: null,
        betAmount: 10,
        betting: false,
        totalBetAmount: 0,
        //本局下注成功的订单
        currentPlays: [],
      },
      players: [],  //房间玩家信息
      myPlays: [],  //我的历史订单
      usersBetList: [],  //下注列表
      appTaskStatus: '', //下载app奖励是否可用
      appTaskReward: '', //下载app奖励
    };
  },
  created() {
    this.country = getCountry()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
    //随机数
    for (var i = 0; i < 10; i++) {
      const text = this.$t('PARITY.CONGRSTS_XXX_WIN_XXX_1') + Math.round(600 + (999 - 600) * Math.random()) + '***' + Math.round(1000 + (9999 - 1000) * Math.random()) + this.$t('PARITY.CONGRSTS_XXX_WIN_XXX_2') + Math.round(10 + (2999 - 1) * Math.random())
      this.userRechargeList[i] = text
    }
  },
  setup() {
    const state = reactive({ });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      telegramClick() {
        const commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
        window.open(commonInfo.telegramChannelUrl, "_self");
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },

  // ======================================== mounted ========================================
  mounted() {

    Toast.loading('Loading...', {
      id: "loading",
      duration: 0,
      bgColor: "rgba(0, 0, 0, 0.9)",
      cover: true,
    });

    //初始化
    this.initGame_P2();

    //设置最后游玩游戏
    setLastTimeGameName('parity');

    //滚动条 隐藏页头背景
    window.addEventListener('scroll', this.handleScroll)
  },

  // ======================================== beforeRouteLeave ========================================
  beforeRouteLeave() {
    //断开游戏socket
    disconnectSocket(gameType.PARITY, this.socket);
    //断开币安
    this.closeBinance();
  },

  // ======================================== methods ========================================
  methods: {

    /**
     * 初始化游戏
     */
    initGame_P2(){
      //设置房间参数
      this.currentRoom = {
        advancedVipLevel: 1,
        betAmountMax: 10000,
        betAmountMin: 10,
        betAmountRange: "10-10000",
        enterVipLevel: 0,
        feeRatio: 5,
        gameDuration: 30,
        id: 0,
        name: "Fast",
        recommendBetAmountList: [10, 40, 100, 1000],
        recommendBetAmounts:  "10,40,100,1000",
        waitDuration: 5,
      } 
      this.appTaskReward = 10;
      this.appTaskStatus = "forbid";
      this.rookieSteps = [10, 40, 100];
      this.config.doubleBetAddAmount = 0;
      this.myBet.betAmount = this.currentRoom.betAmountMin;

      //游客id
      // if (isNotBlank(data.vtoken)) {
      //   localStorage.setItem("vtoken", data.vtoken);
      // }
      // if (localStorage.getItem('token') === null) {
      //   this.$router.push('/userRegister');
      // } 

      //设置历史游戏
      this.loadHistory();

      Toast.hide("loading");
      this.overlayShow = false
    },

    /**
     * 设置游戏历史
     */
     loadHistory() {
        this.currentRoomGame.histories = []
    },

    //连胜CLASS设置
    streakClass(i, s) {
      let className = ''
      if (this.me.streak > i) {
        className += ' win'
      }
      if (s > 0) {
        className += ' gold'
      }
      return className
    },

    //下次连胜奖金
    nextStreakBonus() {
      for (let i in this.currentRoomGame.streakExp) {
        if (i >= this.me.streak && this.currentRoomGame.streakExp[i] > 0) {
          this.me.nextBonus = this.currentRoomGame.streakExp[i]
          this.me.nextStreak = i - this.me.streak + 1
          return
        }
      }
    },

    //滚动条 隐藏页头背景
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop < 80) {
        this.topScroll = false
      } else {
        this.topScroll = true
      }
    },

    //充值按钮
    rechargeClick() {
      if (this.commonInfo.firstRechargeAvailable) {
        router.push('/firstRechargeBonus');
      } else {
        router.push('/rechargeBalance');
      }
    },

    //vip弹窗
    vipPopupClick() {
      this.vipPopup = true
    },

    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },

    // 切换房间
    changeRoom() {
      router.push('/parity');
    },

    //转化时间
    convertTime(timeSerial) {
      return timeSerial.substring(0, 2) + ':' + timeSerial.substring(2, 4)
    },

    // ======================================== 下注 ========================================
    /**
     * 显示下注面板
     * @param betTarget
     */
    callUpBetPanel(betTarget) {
      if (betTarget === this.betColorEnum.red) {
        this.myBet.betType = this.betTypeEnum.color;
        this.bettingPanelBG = 'bg-r'
      } else if (betTarget === this.betColorEnum.green) {
        this.myBet.betType = this.betTypeEnum.color;
        this.bettingPanelBG = 'bg-g'
      } else if (betTarget === this.betColorEnum.violet) {
        this.myBet.betType = this.betTypeEnum.color;
        this.bettingPanelBG = 'bg-v'
      } else {
        //下注的数字
        this.myBet.betType = this.betTypeEnum.number;
        this.bettingPanelBG = 'bg-b'
      }

      this.myBet.betTarget = betTarget;

      //新手流程
      if (isNotBlank(this.myBet.nextBetAmount)) {
        //this.myBet.betAmount = this.myBet.nextBetAmount;
        //todo 面板调整按钮灰掉
        this.myBet.betAmount = null

      }
      this.showBetPanel = true;
      this.hideMenu = true;
    },
    /**
     * 下注
     */
    bet() {
      if (isBlank(this.myBet.betAmount) || this.myBet.betAmount <= 0) {
        Toast.fail(this.$t('PARITY.PLEASE_CONFIRM_YOUR_INPUT'))
        return;
      }
      if (isNotBlank(this.myBet.nextBetAmount) && this.myBet.betAmount !== this.myBet.nextBetAmount) {
        Toast.fail(this.$t('PARITY.AS_A_ROOKIE_YOU_CAN_ONLY_INPUT') + this.myBet.nextBetAmount);
        return;
      }
      this.myBet.betting = true;
      axios.post("/api/parity/bet",
        {
          roomId: this.currentRoom.id,
          betType: this.myBet.betType,
          betTarget: this.myBet.betTarget,
          betAmount: this.myBet.betAmount
        }
      ).then((res) => {
        this.myBet.betting = false;
        let result = res.data;
        let data = result.data;
        if (result.code === 0) {
          this.showBetPanel = false;
          Toast.success(this.$t('global.successfully') + ' ' + this.myBet.betAmount);
          this.me.availableAmount = data.availableAmount;
          this.myBet.currentPlays = data.plays;

          //重新计算总下注金额
          this.myBet.totalBetAmount = 0;
          this.myBet.currentPlays.forEach(myPlay => {
            this.myBet.totalBetAmount = this.myBet.totalBetAmount + myPlay.originBetAmount;
          });
        } else if (result.bizCode === 'GAME_BET_NEED_WITHDRAW') {
          //卡点
          this.showBetPanel = false;
          this.showNeedWithdrawDialog(this.$t('game.pleaseWithdraw'))
        }
      });
      this.hideMenu = false;
    },
    //按钮下注
    btnBetClick(a) {
      if (a > this.currentRoom.betAmountMax) {
        this.myBet.betAmount = this.currentRoom.betAmountMax
      } else if (a < this.currentRoom.betAmountMin) {
        this.myBet.betAmount = this.currentRoom.betAmountMin
      } else {
        this.myBet.betAmount = a
      }
    },
    //关闭下注面板
    bettingPanelClose() {
      if (isBlank(this.myBet.betAmount)) {
        this.myBet.betAmount = this.currentRoomGame.defaultBetAmount
      }
      this.hideMenu = false;
    },
    showKeyBoard() {
      this.keyBoard = true
    },
    //下注加减
    btnAddition() {
      if (isBlank(this.myBet.betAmount)) {
        this.myBet.betAmount = this.currentRoomGame.defaultBetAmount
        return
      }
      if (this.myBet.betAmount <= 99) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 10)
      } else if (this.myBet.betAmount <= 999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 100)
      } else if (this.myBet.betAmount <= 9999) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000)
      } else if (this.myBet.betAmount <= 10000000) {
        this.myBet.betAmount = this.$math.add(this.myBet.betAmount, 1000)
      }
      if (this.myBet.betAmount > this.currentRoom.betAmountMax) {
        Toast.text("Maxmum of " + this.currentRoom.betAmountMax);
        this.myBet.betAmount = this.currentRoom.betAmountMax
      }
    },
    btnSubtract() {

      if (isBlank(this.myBet.betAmount) || this.myBet.betAmount <= this.currentRoom.betAmountMin) {
        Toast.text("Minimum of " + this.currentRoom.betAmountMin);
        return
      }
      if (this.myBet.betAmount <= 100) {
        if (this.myBet.betAmount > this.currentRoom.betAmountMin) this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 10)
      } else if (this.myBet.betAmount <= 1000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 100)
      } else if (this.myBet.betAmount <= 10000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 1000)
      } else if (this.myBet.betAmount <= 10000000) {
        this.myBet.betAmount = this.$math.subtract(this.myBet.betAmount, 10000)
      }
      if (this.myBet.betAmount < this.currentRoom.betAmountMin) {
        this.myBet.betAmount = this.currentRoom.betAmountMin
      }
    },
    //跳转到我的下注历史
    myPlayList() {
      router.push({
        path: "myPlayListParity",
        query: {
          roomId: this.currentRoom.id
        }
      });
    },
    //切换预测tabs
    tabsClick() {
      this.tabs1 = false
      this.tabs2 = false
      this.tabs3 = false
      this.tabs4 = false
    },
    // ======================================== 结算 ========================================
    // /**
    //  * 查询结算信息
    //  */
    // querySettle(serialNum) {
    //   if (this.myBet.totalBetAmount === 0) {
    //     return;
    //   }
    //   axios.get("/api/parity/query-settle?serialNum=" + serialNum)
    //       .then((res) => {
    //         let data = res.data.data;
    //
    //         this.me.availableAmount = data.availableAmount;
    //         this.settle.serialNum = data.serialNum;
    //         this.settle.result = data.result;
    //         this.settle.plays = data.plays;
    //         this.settle.totalProfitAmount = data.totalProfitAmount;
    //         this.myBet.nextBetAmount = getValue(data.nextBetAmount);
    //         this.settle.needWithdraw = data.needWithdraw;
    //
    //         this.callUpSettlePanel();
    //       });
    // },
    /**
     * 打开结算面板
     */
    callUpSettlePanel() {
      this.showSettlePanel = true
    },

    /**
     * 关闭结算面板
     */
    closeSettlePanel() {
      this.showSettlePanel = false;
      //如果到了卡点，弹框提醒
      if (this.settle.needWithdraw) {
        this.showNeedWithdrawDialog();
      } else {
        //没到卡点
        //如果低于提醒阈值，弹出补满提醒
        this.showFillUpTipsIfSuitable();
      }
    },
    
    /**
     * 到达卡点金额，强制提现
     */
    showNeedWithdrawDialog() {
      this.withdrawalPopup = true
    },
    /**
     * 提示邀请补满
     */
    showFillUpTipsIfSuitable() {
      const fillUpAlertThreshold = this.commonInfoAttr('fillUpAlertThreshold');
      if (this.me.rechargeAmount === 0 && this.me.availableAmount <= fillUpAlertThreshold) {
        //未充值过且钱包余额不足
        if (this.showDialogFill) {
          this.showDialogFill = false
          this.invitePopup = true
        }
      }
    },


    // ======================================== 第2个面板 ========================================
    /**
     * 第二个面板三个tab
     * @param n
     */
    resultTabClick(n) {
      this.resultTabActive = n;
      if (n === 1) {
        this.rechargeBtnBox = false
      } else if (n === 2) {
        if (this.me.vipLevel > 0) {
          this.refreshContinuous();

        } else {
          Toast.text(this.$t('PARITY.ONLY_FOR_VIP1'));
          this.rechargeBtnBox = true
        }
      } else if (n === 3) {
        if (this.me.vipLevel > 1) {
          this.createLineChart();
          this.rechargeBtnBox = false
        } else {
          Toast.text(this.$t('PARITY.ONLY_FOR_VIP2'));
          this.rechargeBtnBox = true
        }
      }
    },
    /**
     * 斩龙列表
     */
    refreshContinuous() {
      this.textToast(this.$t('global.loading'));
      axios.get("/api/parity/query-continuous?roomId=" + this.currentRoom.id)
        .then((res) => {
          this.currentRoomGame.continuousList = res.data.data;
          Toast.hide("loading");
        });
    },
    /**
     * 号码走势图
     */
    createLineChart() {
      this.textToast(this.$t('global.loading'));
      axios.get("/api/parity/history/paginate?roomId=" + this.currentRoom.id + "&current=" + Number(this.historyGame.current))
        .then((res) => {
          this.historyGame.list = res.data.data.records;
          this.historyGame.current = res.data.data.current;
          this.historyGame.total = res.data.data.total;
          this.historyGame.hasNext = res.data.data.hasNext;
          this.resultNumData = []
          this.resultColorData = []
          var sup_ls = []
          var sub_ls = []
          var sup_c_ls = []
          var sub_c_ls = []
          for (let k in this.historyGame.list) {
            switch (this.historyGame.list[k].result) {
              case '1':
                sup_ls.push(this.numPositionDara[k][0][0]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][0])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][0]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][0])
                }
                break;
              case '2':
                sup_ls.push(this.numPositionDara[k][0][1]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][1])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][2]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][2])
                }
                break;
              case '3':
                sup_ls.push(this.numPositionDara[k][0][2]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][2])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][0]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][0])
                }
                break;
              case '4':
                sup_ls.push(this.numPositionDara[k][0][3]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][3])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][2]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][2])
                }
                break;
              case '5':
                sup_ls.push(this.numPositionDara[k][0][4]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][4])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][1]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][1])
                }
                break;
              case '6':
                sup_ls.push(this.numPositionDara[k][0][5]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][5])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][2]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][2])
                }
                break;
              case '7':
                sup_ls.push(this.numPositionDara[k][0][6]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][6])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][0]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][0])
                }
                break;
              case '8':
                sup_ls.push(this.numPositionDara[k][0][7]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][7])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][2]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][2])
                }
                break;
              case '9':
                sup_ls.push(this.numPositionDara[k][0][8]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][8])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][0]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][0])
                }
                break;
              case '0':
                sup_ls.push(this.numPositionDara[k][0][9]);
                if (k > 0) {
                  sub_ls.push(this.numPositionDara[k - 1][1][9])
                }
                sup_c_ls.push(this.colorPositionDara[k][0][1]);
                if (k > 0) {
                  sub_c_ls.push(this.colorPositionDara[k - 1][1][1])
                }
                break;
            }
            var kk = Number(k)
            if (kk > 0) {
              this.resultNumData.push('M ' + sup_ls[kk - 1] + ' L ' + sub_ls[kk - 1] + ' z')
              this.resultColorData.push('M ' + sup_c_ls[kk - 1] + ' L ' + sub_c_ls[kk - 1] + ' z')
            }
          }
          Toast.hide("loading");
        });
    },
    //号码走势图 分页
    showMoreResult() {
      this.historyGame.current++
      this.createLineChart()
    },
    // ======================================== 第3个面板 ========================================
    /**
     * 其他玩家的订单
     */
    playersList() {
      this.orderTabActive = true
    },
    /**
     * 我的订单
     */
    myHistoryList() {
      this.orderTabActive = false
      axios({
        url: "/api/parity/play/paginate",
        method: "get",
        params: {
          roomId: this.currentRoom.id,
          current: 1,
          queryLimit: 'today'
        }
      }).then((res) => {
        const data = res.data.data;
        this.myPlays = data.records
        if (this.myPlays.length > 0) {
          this.listNoData = false;
        }
      });
    },
    // ======================================== 其他 ========================================
    /**
     * 获取游戏序号前半部分
     * @param serialNum
     * @returns {string}
     */
    getSerialNumPrefix(serialNum) {
      if (isBlank(serialNum)) {
        return '';
      }
      return serialNum.substring(0, 11);
    },
    /**
     * 获取游戏序号后半部分
     * @param serialNum
     * @returns {string}
     */
    getSerialNumPostfix(serialNum) {
      if (isBlank(serialNum)) {
        return '?';
      }
      return serialNum.substring(11, 15);
    },
    parseAmount(amount) {
      if (isBlank(amount)) {
        return null;
      }
      return this.$math.format(amount, (value) => value.toFixed(2));
    },
    /**
     * 计算手续费
     * @param betAmount
     * @returns {string}
     */
    calculateFee(betAmount) {
      if (isBlank(betAmount) || isBlank(this.currentRoom.feeRatio)) {
        return null;
      }
      return this.$math.format(
        this.$math.divide(
          this.$math.multiply(
            this.$math.bignumber(betAmount),
            this.$math.bignumber(this.currentRoom.feeRatio)
          ),
          this.$math.bignumber(100)
        ),
        (value) => value.toFixed(2));
    },
    /**
     * 计算实际下注金额
     * @param betAmount
     * @returns {string}
     */
    calculateActualBetAmount(betAmount) {
      if (isBlank(betAmount)) {
        return null;
      }
      const fee = this.calculateFee(betAmount);
      if (isBlank(fee)) {
        return null;
      }

      return this.$math.format(
        this.$math.subtract(
          this.$math.bignumber(betAmount),
          this.$math.bignumber(fee)
        ),
        (value) => value.toFixed(2));
    },
    commonInfoAttr(key) {
      return getCommonInfoAttr(key);
    },
    withdrawClick() {
      router.push('/withdrawalBalance?amountType=coin');
    },
    /**
     * 根据下注目标返回Class
     * @param betTarget
     * @returns {string}
     */
    getBetTargetClass(betTarget) {
      if (isBlank(betTarget)) {
        return 'g';
      }
      switch (betTarget) {
        case this.betColorEnum.red:
        case '2':
        case '4':
        case '6':
        case '8':
          return 'r';
        case this.betColorEnum.green:
        case '1':
        case '3':
        case '7':
        case '9':
          return 'g';
        case this.betColorEnum.violet:
          return 'v';
        case '0':
          return 'v-0';
        case '5':
          return 'v-5';
      }
    },
    /**
     * 根据下注目标返回小球显示的内容
     * @param betTarget
     * @returns {string}
     */
    getBetTargetContent(betTarget) {
      if (isBlank(betTarget)) {
        return '?';
      }
      switch (betTarget) {
        case this.betColorEnum.red:
          return 'R';
        case this.betColorEnum.green:
          return 'G';
        case this.betColorEnum.violet:
          return 'V';
        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
          return betTarget;
      }
    },
    /**
     * 根据结果返回Class
     * @param result
     * @returns {string}
     */
    getResultClass(result) {
      if (isBlank(result)) {
        return 'x';
      }
      switch (result) {
        case '0':
          return 'v v-0';
        case '5':
          return 'v v-5';
        case '1':
        case '3':
        case '7':
        case '9':
          return 'g';
        case '2':
        case '4':
        case '6':
        case '8':
          return 'r';
      }
    },
    /**
     * 斩龙的Class
     * @param type
     * @returns {string}
     */
    getContinuousClass(type) {
      if (type === this.continuousTypeEnum.rv) {
        return 'r';
      } else if (type === this.continuousTypeEnum.gv) {
        return 'g';
      } else {
        return '';
      }
    },
    /**
     * 斩龙的结果Class
     * @param result
     * @returns {string}
     */
    getContinuousHistoryClass(result) {
      if (isBlank(result)) {
        return 'x';
      }
      switch (result) {
        case '0':
          return 'x v-0';
        case '5':
          return 'x v-5';
        case '1':
        case '2':
        case '3':
        case '4':
        case '6':
        case '7':
        case '8':
        case '9':
          return 'x';
      }
    },

    // ======================================== websocket ========================================
    initSocket() {
      if (this.socket != null) {
        return;
      }

      //创建
      this.socket = createSocket(gameType.PARITY, this.bigwinData, this.broadcastMsgData);

      /**
       * 就绪
       */
      this.socket.on(commonEvent.ready, () => {
        this.socketChangeRoom();
        this.overlayShow = false
      });

      /**
       * 初始化房间
       */
      this.socket.on(this.eventEnum.initRoom, (room) => {

        if (room.stage === this.stageEnum.pause) {
          this.currentRoomGame.pause = true;
        }
        //下注阶段剩余时间
        let betRemainMillis = room.betRemainMillis;
        if (isNotBlank(betRemainMillis)) {
          this.currentRoomGame.betDeadline = Date.now() + betRemainMillis;
          this.usersBetList = []
          //显示玩家下注列表
          for (let k in room.currentPlays) {
            let t = betRemainMillis - room.currentPlays[k].betWaitMillis
            if (t > 0) {
              setTimeout(() => {
                if (this.usersBetList.length > 30) {
                  this.usersBetList.pop()
                }
                this.usersBetList.unshift(room.currentPlays[k])
              }, t)
            } else {
              this.usersBetList.unshift(room.currentPlays[k])
            }
          }

        }
        //结束阶段剩余时间
        let waitRemainMillis = room.waitRemainMillis;
        if (isNotBlank(waitRemainMillis)) {
          this.currentRoomGame.waitDeadline = Date.now() + waitRemainMillis;
        }
        this.currentRoomGame.serialNum = room.serialNum;
        this.currentRoomGame.stage = room.stage;
        //连胜奖励规则
        if (isNotBlank(room.streakExp)) {
          this.currentRoomGame.streakExp = room.streakExp.split(',')
        }

      });

      /**
       * 我的信息
       */
      this.socket.on(this.eventEnum.playerInfo, (playerInfo) => {
        //玩家信息
        this.me.vipLevel = playerInfo.vipLevel;
        this.me.availableAmount = playerInfo.availableAmount;
        this.me.rechargeAmount = playerInfo.rechargeAmount;
        this.me.withdrawAmount = playerInfo.withdrawAmount;
        this.me.inRookieProcess = playerInfo.inRookieProcess;
        this.me.streak = playerInfo.streak;
        this.nextStreakBonus()

        //是否卡点
        if (isTrue(playerInfo.needWithdraw)) {
          this.showNeedWithdrawDialog(this.$t('game.pleaseWithdraw'));
        }
        this.showFillUpTipsIfSuitable();


        //下次下注金额
        this.myBet.nextBetAmount = getValue(playerInfo.nextBetAmount);

        //我的订单
        this.myBet.currentPlays = playerInfo.currentPlays;
      });

      /**
       * 进入下注阶段
       */
      this.socket.on(this.eventEnum.startBet, (event) => {
        //清空下注信息
        this.myBet.currentPlays = [];
        this.myBet.totalBetAmount = 0;

        this.currentRoomGame.stage = this.stageEnum.bet;
        this.currentRoomGame.pause = false;
        this.currentRoomGame.serialNum = event.serialNum;
        this.currentRoomGame.betDeadline = Date.now() + event.betDuration * 1000;
        this.usersBetList = []

        //刷新玩家列表
        this.players.length = 0;
        //显示玩家下注列表
        for (let k in event.currentPlays) {
          setTimeout(() => {
            if (this.usersBetList.length > 30) {
              this.usersBetList.pop()
            }
            this.usersBetList.unshift(event.currentPlays[k])
          }, event.currentPlays[k].betWaitMillis)
        }

        //this.waitPredictionCome = true;
      });

      /**
       * 进入等待阶段
       */
      this.socket.on(this.eventEnum.startWait, (event) => {
        this.currentRoomGame.stage = this.stageEnum.wait;
        this.currentRoomGame.pause = false;
        this.currentRoomGame.waitDeadline = Date.now() + event.waitDuration * 1000;
      });

      /**
       * 进入结束阶段
       */
      this.socket.on(this.eventEnum.end, (event) => {
        const serialNum = event.serialNum;

        if (this.myBet.currentPlays.length === 0) {
          this.showTopResult = true;
          setTimeout(() => {
            this.showTopResult = false;
          }, 10000);
          this.topResult.serialNum = event.serialNum;
          this.topResult.result = event.result;
          this.topResult.btcResultTime = event.btcResultTime;
          this.topResult.btcPrice = event.btcPrice;
          if (isNotBlank(this.topResult.btcPrice)) {
            this.topResult.btcPriceLeft = this.topResult.btcPrice.substring(0, this.topResult.btcPrice.length - 1);
            this.topResult.btcPriceRight = this.topResult.btcPrice.substring(this.topResult.btcPrice.length - 1, this.topResult.btcPrice.length)
            if (this.topResult.btcPriceRight === '0' || this.topResult.btcPriceRight === '5') {
              this.topResult.btcPriceColor = 'v'
            } else if (this.topResult.btcPriceRight === '1' || this.topResult.btcPriceRight === '3' || this.topResult.btcPriceRight === '7' || this.topResult.btcPriceRight === '9') {
              this.topResult.btcPriceColor = 'g'
            } else {
              this.topResult.btcPriceColor = 'r'
            }
          }
        }
        this.currentRoomGame.pause = false;
        this.currentRoomGame.result = event.result;
        //查询结算信息
        // this.querySettle(this.currentRoomGame.serialNum);
        //刷新历史
        // this.loadHistory();
        // this.refreshContinuous()
        //历史列表
        this.currentRoomGame.histories.push({
          serialNum: serialNum,
          shortSerialNum: this.getSerialNumPostfix(serialNum),
          result: event.result
        });
        if (serialNum.substring(serialNum.length - 1, serialNum.length) === '0') {
          //开的序号结尾是0，则需要删除头10个元素
          this.currentRoomGame.histories.splice(0, 10);
        }
      });

      /**
       * 结算信息
       */
      this.socket.on(this.eventEnum.settle, (event) => {
        this.me.availableAmount = event.availableAmount;
        if (this.currentRoom.id === event.roomId) {
          //在对应的房间才弹出结算窗口
          this.settle.serialNum = event.serialNum;
          this.settle.result = event.result;
          this.settle.plays = event.plays;
          this.settle.totalWinAmount = event.totalWinAmount;
          this.settle.totalProfitAmount = event.totalProfitAmount;
          this.myBet.nextBetAmount = getValue(event.nextBetAmount);
          this.settle.needWithdraw = event.needWithdraw;
          this.settle.btcPrice = event.btcPrice;
          this.settle.btcResultTime = event.btcResultTime;
          this.me.streak = event.streak;
          this.callUpSettlePanel();
          this.nextStreakBonus();
        }
      });

      /**
       * 预测通知
       */
      // this.socket.on(this.eventEnum.prediction, (event) => {
      //   this.waitPredictionCome = false;
      //   this.prediction = event;
      // });
    },


    socketChangeRoom() {
      if (this.socket === null) {
        return;
      }
      this.socket.emit(commonEvent.join, { t: this.currentRoom.id });
    },


    closeBinance() {
      if (isNotBlank(this.binanceWebsocket)) {
        this.binanceWebsocket.close();
      }
    },
    getBtcPrice(i) {
      if (isBlank(this.btcPrice)) {
        return '...';
      } else {
        const btcPrice = this.$math.bignumber(this.btcPrice).toFixed(2)
        if (i === 'left') {
          return btcPrice.substring(0, btcPrice.length - 1)
        } else if (i === 'right') {
          return btcPrice.substring(btcPrice.length - 1, btcPrice.length)
        } else {
          return btcPrice
        }
      }
    },
    //下载app
    downloadApp() {
      window.open(getCommonInfoAttr('appDownloadUrl'))
    },
    //连胜规则
    streakRule() {
      Dialog({
        title: this.$t('gameRules.streak_rule_title'),
        content: this.$t('gameRules.streak_rule'),
        cancelText: this.$t('gameRules.btn'),
        noOkBtn: true,
      });
    }

  },
};
</script>

<style scoped src="../assets/css/game_parity.css"></style>
<style scoped></style>
<style>
.pop-inventory {
  padding: 0 !important;
  width: 280px !important;
  border-radius: 15px;
  background: none !important;
  overflow: initial !important;
}

.betPanelBox .nutui-popup__close-icon {
  color: #fff !important;
}

.pop-forecaster {
  width: 96% !important;
  height: 400px !important;
}

.pop-top-result {
  background: url(../assets/images/20230601/img23.png) 0 0 no-repeat !important;
  background-size: 100% 100% !important;
  width: 96% !important;
  top: 65px !important;
  left: 3% !important;
  right: 3% !important;
  border-radius: 5px !important;
  overflow: hidden;
}

.pop-top-result .nutui-popup__close-icon--top-right {
  top: 3px !important;
  right: 6px !important;
}

.pop-top-result .nutui-popup__close-icon--top-right .nut-icon-close {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
}

.pop-inventory li.streak-li {
  background: #fffada url(../assets/images/bg172.png) 2px 1px no-repeat;
  background-size: auto 18px;
  padding-right: 5px;
  padding-left: 25px;
}

.pop-inventory li.streak-li2 {
  background: #fffada url(../assets/images/bg194.png) 2px 2px no-repeat;
  background-size: auto 18px;
  margin-bottom: 5px;
  padding-left: 25px;
  padding-right: 5px;
}

</style>



