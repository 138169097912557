<template>
  <div class="pop-vip">
    <div class="pophd">
      <strong>
        {{popTitle}}
      </strong>
    </div>
    <div class="popbd">
      
      <div class="table-tab">
        <span :class="w_privilege ? 'a' : ''" @click="w_privilege = true; c_privilege = false, p_privilege = false">{{$t('VIP_PRIVILEGE.WITHDRAWAL')}}</span>
        <span :class="p_privilege ? 'a' : ''" @click="w_privilege = false; c_privilege = false, p_privilege = true">Parity</span>
      </div>
      <table class="table-th">
        <tr>
          <th>{{$t('VIP_PRIVILEGE.LEVEL')}}</th>
          <th v-show="w_privilege">{{$t('VIP_PRIVILEGE.TOTAL_RECHARGE')}}</th>
          <th v-show="w_privilege">{{$t('VIP_PRIVILEGE.WITHDRAWAL_QUOTA')}}</th>
          <th v-show="c_privilege || p_privilege">{{$t('VIP_PRIVILEGE.DAILY_BONUS')}}</th>
          <th v-show="c_privilege">&#128640; {{$t('VIP_PRIVILEGE.STOP_LIMIT')}}</th>
          <th v-show="c_privilege">&#128718; {{$t('VIP_PRIVILEGE.INPUT_LIMIT')}}</th>
          <th v-show="p_privilege">{{$t('VIP_PRIVILEGE.DATA_ANALYSIS')}}</th>
          <th v-show="p_privilege">&#127914; {{$t('VIP_PRIVILEGE.ROOM_LIMIT')}}</th>
        </tr>

        <tr v-for="(item, index) in vipRules" :key="index" :class="myLevel === index ? 'mylevel' : ''">
          <td>Lv{{item.level}}</td>
          <td v-show="w_privilege">
            {{ $filters.currencySymbol(item.requireRechargeAmount) }}
            <span v-show="
              (country === 'ng' && item.level === 4) || 
              (country === 'br' && item.level === 4 && s !== 's2') || 
              (country !== 'ng' && country !== 'br' && item.level === 4)
            ">(+{{registerReward}} &#10004;)</span>
          </td>
          <td v-show="w_privilege">
            {{ $filters.currencySymbol(item.withdrawLimit) }}
            <span v-show="(country === 'br' && item.level === 4)">&#128293;</span>
            <span v-show="(country === 'ng' && item.level === 4)">&#128293;</span>
            <span v-show="(country !== 'ng' && country !== 'br' && item.level === 4)">&#128293;</span>
          </td>
          <td v-show="c_privilege || p_privilege">&#127873; +{{item.extraDailySignRewardRatio}}%</td>
          <td v-show="c_privilege">{{$t('VIP_PRIVILEGE.MAX')}} {{item.crashMaxRatio}}x</td>
          <td v-show="c_privilege">{{$t('VIP_PRIVILEGE.MAX')}} &#128176;{{item.maxBetAmount}}</td>
          <td v-show="p_privilege">
            <span v-if="index === 0">{{$t('VIP_PRIVILEGE.RECORD')}}</span>
            <span v-else-if="index === 1">{{$t('VIP_PRIVILEGE.CONTINUOUS')}}</span>
            <span v-else-if="index === 2">{{$t('VIP_PRIVILEGE.PROBABILITY')}}</span>
            <span v-else>--</span>
          </td>
          <td v-show="p_privilege">
            <span v-if="index === 0">&#9889;Fast</span>
            <span v-else-if="index === 1">&#128161;Prediction</span>
            <span v-else>--</span>
          </td>
        </tr>
      </table>
      <p v-show="s !== 's2'" class="p">{{$t('VIP_PRIVILEGE.TEXT1')}}{{registerReward}}{{$t('VIP_PRIVILEGE.TEXT2')}}</p>
      <p v-show="upgradeVip === 'show'" style="margin:-5px 5px 15px 5px;text-align: center; color: #fff !important;font-size: 12px; font-weight: 600;">{{$t('VIP_PRIVILEGE.TEXT3')}}</p>
    </div>
  </div> 
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import axios from "axios";
import { getCountry } from "@/assets/js/environment";

export default {
  name: 'VipRules',
  props: {
    myLevel:{
      type: Number,
      default : 0
    },
    popTitle:{
      type: String,
      default : ''
    },
    upgradeVip:{
      type: String,
      default : ''
    },
    tabActive:{
      type: String,
      default : ''
    },
  },
  watch:{
    tabActive: function(newData){
      if(newData === 'parity'){
        this.w_privilege = false
        this.c_privilege = false
        this.p_privilege = true
      } else if(newData === 'crash') {
        this.w_privilege = false
        this.c_privilege = true
        this.p_privilege = false
      }
    }
  }, 
  data() {
    return {
      country: '',
      s:'s1',
      w_privilege: true,
      c_privilege: false,
      p_privilege: false
    };
  },
  methods: {
  },  
  created() {
    this.country = getCountry()
    this.s = document.querySelector('body').getAttribute('s')
  },
  setup() {
    const state = reactive({
      vipRules:'',
      registerReward:''
    });
    onMounted(() => {
      if (window.localStorage.getItem("commonInfo")) {
        let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
        state.vipRules = commonInfo.vipRules;
        state.registerReward = commonInfo.registerReward;
      } else {
        axios.get("/api/system/common", {}).then((response) => {
          state.vipRules = response.data.data.vipRules;
          state.registerReward = response.data.data.registerReward;
        });
      }
    });
    return {
      ...toRefs(state)
    };
  }

};
</script>

<style scoped>
.popbd .p{ color: #fff; padding:0 10px 20px 20px; text-align: center; opacity: .7; line-height: 14px; margin: 10px 0 0 0; font-size: 10px;}
.table-tab { display: flex; margin-bottom: 5px;}
.table-tab span{ opacity: .5; background: #fff; margin-right:5px; width: 50%; text-align: center; 
  padding: 5px 15px; line-height: 20px; height: 20px; border-radius:5px; color: #0a115c;}
.table-tab span.a{ opacity: 1;}
.pop-vip .pophd{height: 50px; line-height: 50px;text-align: center; }
.pop-vip .pophd strong{ color: #fff; font-size: 14px; padding-left: 22px;
   background: url(../assets/images/20230601/img15.png) 0 center no-repeat;background-size: 16px auto;}
.pop-vip .popbd{  padding: 0 10px;}
.table-th{ width: 100%;border-radius:7px; overflow: hidden;}
.table-th th{border: 1px solid #0a115c; line-height: 10px; text-align: center;line-height: 14px; 
  padding: 10px 3px; font-size: 10px; background: #fff; border-bottom:2px solid  #348fca; font-weight: 300;font-size: 14px;}
.table-th td{border: 1px solid #0a115c; padding: 0 0 0 5px; line-height: 16px; font-size: 12px;background: #fff; text-align: center;height: 26px; text-align: left;}
.table-th tr td:nth-child(3){  background:rgb(242, 255, 244); font-weight: 500; color: rgb(0, 101, 32);}

.table-th tr.mylevel td{ background-color: #f0f9bf !important;}
</style>
<style>
.pop-vip { width: 350px !important; padding: 0px !important; background:#1d2047 !important; border-radius:15px;}
</style>
