<template>
  <div class="myHome">

    <nut-navbar
      @on-click-icon="iconClick"
      fixed=true
      :left-show="false"
      :titIcon="icon"
      class="mb0 nav-icon-right nut-navbar--fixed nut-navbar-b0"
      :title="$t('home.h1')"
    >
    </nut-navbar>


    <div class="home-sl" style="display: none;">
      <SetLanguage></SetLanguage>
    </div>

    <div class="my-info-bg">
      <div class="my-info">
        <div class="my-info-t">
          <div class="p-avatar">
            <img class="avatarimg" v-show="userInfo.avatar !== ''" :src="userInfo.avatar" @click="avatarClick(userInfo.myAvatar)"/>
          </div>
          <ul>
            <li  @click="vipPopupClick">{{ userInfo.mobile }}<span>{{$t('home.vip')}} {{ userInfo.vipLevel }}</span></li>
          </ul>
        </div>
        <div class="my-info-c">
          <div class="t">
            <div class="h5">
              <span  @click="infoToast($t('home.gameAmount'))">
                {{$t('withdrawal.gameAccount')}}
                <nut-icon name="ask" class="copyicon" style="vertical-align: middle; opacity:.5;"></nut-icon>
              </span>
              <strong>{{ $filters.currencySymbol(fundAccount.coinAccount) }}</strong>
            </div>
            <div class="btn-box">
              <span class="btn-t" @click="rechargeClick('cash')">{{$t('home.recharge')}}</span>
              <span class="btn-t" @click="withdrawalClick('coin')">{{$t('home.withdrawal')}}</span>
            </div>
          </div>
          <div class="t">
            <div class="h5">
              <span @click="infoToast($t('home.agentAmount'))">
                {{$t('withdrawal.agentAmount')}}
                <nut-icon name="ask" class="copyicon" style="vertical-align: middle;opacity:.5;"></nut-icon>
              </span>
              <strong>{{ $filters.currencySymbol(fundAccount.cashAccount) }}</strong>
            </div>
            <div  :class="fundAccount.cashAccount > 0 ? 'btn-box' : 'btn-box op05'">
              <span v-show="!commonInfo.pureMode" class="btn-t" @click="transferClick">{{$t('withdrawal.transfer')}}</span>
              <span class="btn-t" @click="withdrawalClick('cash')">{{$t('home.withdrawal')}}</span>
            </div>
          </div>
        </div>


      </div>
    </div>



    <div class="my-info-menu">
      <div class="my-info-b">

        <div class="t left"  @click="showCheckIn = true">
          <h5>{{$t('taskReward.checkIn')}}</h5>
          <span>Sign In Bonus</span>
        </div>
        <div class="t" @click="copyClick(userInfo.inviteCode)">
          <h5>{{ $t('home.inviteCode') }}</h5>
          <span>{{ userInfo.inviteCode }}</span>
        </div>
        <div class="t right" @click="telegramClick">
          <h5>{{ $t('home.link') }}</h5>
          <span>{{ $t('home.link_r') }} ></span>
        </div>


      </div>
      <nut-cell-group>
        <!-- <nut-cell
          :title="$t('home.link')"
          is-link
          @click="telegramClick"
          :desc="$t('home.link_r')"
          class="icon-t1"
        ></nut-cell> -->
        <nut-cell
          :title="$t('home.promotion')"
          is-link
          @click="promotionClick"
          class="icon-t2"
        ></nut-cell>
        <nut-cell
          :title="$t('home.envelopesCenter')"
          is-link
          :desc="unreadLetterCount"
          @click="envelopesCenterClick"
          class="icon-t3"
        ></nut-cell>
        <nut-cell
          :title="$t('home.transactions')"
          is-link
          @click="transactionsClick"
          class="icon-t4"
        ></nut-cell>
        <nut-cell
          :title="$t('home.bankCard')"
          is-link
          @click="bankCardClick"
          class="icon-t5"
        ></nut-cell>
        <nut-cell
          :title="$t('home.resetPassword')"
          is-link
          @click="ResetPasswordClick"
          class="icon-t6"
        ></nut-cell>

        <nut-cell
          :title="$t('home.cs')"
          is-link
          @click="complaintsSuggestionsClick"
          class="icon-t7"
        ></nut-cell>

      </nut-cell-group>

      <nut-cell-group v-show="downloadAppShow">
        <nut-cell
          :title="$t('global.downloadApp')"
          is-link
          @click="downloadApp"
          class="icon-t9"
        ></nut-cell>
      </nut-cell-group>

      <div class="logout-btn">
          <nut-button
          :plain="booleanTrue"
          :block="booleanTrue"
          type="info"
          @click="logoutClick"
          style="margin-top:20px"
          >{{$t('home.logout')}}</nut-button
        >
      </div>
      
    </div>

    <nut-popup
      position="bottom"
      closeable
      round
      :style="{ height: '70%' }"
      v-model:visible="showRound"
    >
      <div class="vip-popup">
        <h3>{{$t('home.vipTitle')}}</h3>

        <nut-tabs v-model="tab11value" type="smile" >
          <nut-tabpane :title="$t('home.withdrawakQuoat')">
            <table>
              <tr>
                <th>{{$t('home.level')}}</th>
                <th>{{$t('home.totalRecharge')}}</th>
                <th>{{$t('home.profitRatio')}}</th>
              </tr>
              <tr>
                <td>1</td>
                <td>&ge; 200</td>
                <td>1.25</td>
              </tr>
              <tr>
                <td>2</td>
                <td>&ge; 400</td>
                <td>1.40</td>
              </tr>
              <tr>
                <td>3</td>
                <td>&ge;1000</td>
                <td>1.45</td>
              </tr>
              <tr>
                <td>4</td>
                <td>&ge;2000</td>
                <td>1.50</td>
              </tr>
              <tr>
                <td>5</td>
                <td>&ge;10000</td>
                <td>1.55</td>
              </tr>
            </table>
          </nut-tabpane>
          <nut-tabpane :title="$t('home.withdrawalServices')"> </nut-tabpane>
          <nut-tabpane :title="$t('home.vipRewards')"> </nut-tabpane>
        </nut-tabs>

        <dl>
          <dt>{{$t('home.dt')}}</dt>
          <dd>{{$t('home.dd1')}}</dd>
          <dd>{{$t('home.dd2')}}</dd>
        </dl>
      </div>
    </nut-popup>

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

    <div class="download-app" v-show="downloadAppShow">
      <a :href="appDownloadUrl">&nbsp;</a>
      <span @click="downloadAppClose"><nut-icon name="close" size="16" color="#ffffff"></nut-icon></span>
    </div>

    <nut-overlay v-model:visible="addDesktopShow" :z-index="2000" style="" class="add-Desktop" @click="downloadAppClose">
      <div class="wrapper">
        <div class="content">
          <p>{{$t('global.addDesktopTitle')}}</p>
          <p>{{$t('global.addDesktopText1a')}} <img src="../assets/images/bg64.png" /> {{$t('global.addDesktopText1b')}}</p>
          <p>{{$t('global.addDesktopText2')}}</p>
        </div>
      </div>
    </nut-overlay>


    <nut-popup pop-class="pop-checkIn" v-model:visible="showCheckIn">
      <div class="checkIn7" v-if="dailySignList.length > 0 &&  dailySignCurrent.type === 'rookie'">
        <div class="task-item">
          <div class="task-flex">
            <div class="l">
              <h5>{{$t('taskReward.checkIn7Text')}}({{dailySignCurrent.toSignDay}}/7)</h5>
              <p>{{$t('taskReward.vipCheckInText')}}</p>
            </div>
            <div class="a">
              <strong>+{{ $filters.currencySymbol(baseRewardAmount) }}</strong>
            </div>
          </div>
          <nut-row class="b2">
            <nut-col :span="24" class="l">
              <nut-indicator
                :block="true" 
                align="center"
                :size="7"
                :current="dailySignCurrent.toSignDay"
                :fill-zero="false"
                >step1</nut-indicator>
            </nut-col>
            <nut-col :span="24" class="r">
              <nut-button v-show="dailySignCurrent.canSign" type="primary" @click="taskSignClick(baseRewardAmount, extraRewardAmount)">{{$t('taskReward.checkIn')}}</nut-button>
              <nut-button v-show="!dailySignCurrent.canSign" type="primary" disabled>{{$t('taskReward.checkInLocked')}}</nut-button>
            </nut-col>
          </nut-row>
        </div>
      </div>
      <!-- 非新手签到 -->
      <div class="checkIn7" v-else-if="dailySignList.length > 0 && dailySignCurrent.type !== 'rookie'">
        <div class="task-item">
          <div class="task-flex">
            <div class="l type-1">
              <h5>{{$t('taskReward.checkInText')}}</h5>
              <p>{{$t('taskReward.vipCheckInText')}}</p>
            </div>
            <div class="a" v-show="baseRewardAmount > 0">
              <strong>+{{ $filters.currencySymbol(baseRewardAmount) }}</strong>
            </div>
          </div>
          <nut-row class="b2">
            <nut-col :span="24" class="r">
              <nut-button  v-show="dailySignCurrent.canSign" type="primary" @click="taskSignClick(baseRewardAmount, extraRewardAmount)">{{$t('taskReward.complete')}}</nut-button>
              <nut-button  v-show="!dailySignCurrent.canSign" type="primary" disabled>{{$t('taskReward.checkInLocked')}}</nut-button>
            </nut-col>
          </nut-row>
        </div>
      </div>
    </nut-popup>


    <nut-popup pop-class="pop-vip" v-model:visible="vipPopup" :z-index="100">
      <VipRules :myLevel="myLv" :popTitle="popTitle"></VipRules>
    </nut-popup>

    <nut-popup
      position="top"
      :z-index="300"
      :style="{ height: '220px', background:'#fff' }"
      @closed="topPopclose" 
      v-model:visible="taskPop"
    >
      <div class="pop-top">
        <h4>{{$t('taskReward.getBn')}}</h4>
        <p>
          <strong></strong><strong>{{ $filters.currencySymbol(taskAmount) }}</strong>
        </p>
      </div>
    </nut-popup>




  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs,getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import SetLanguage from "../components/SetLanguage.vue";
import {Dialog, Toast} from "@nutui/nutui";
import axios from "axios";
import { getClientType } from "@/assets/js/commonUtil";
import VipRules from "../components/VipRules2.vue";

export default {
  name: "MyHome",
  components: {
    tabbar: TabbarMain,
    SetLanguage: SetLanguage,
    VipRules: VipRules
  },
  data() {
    return {
      vipPopup:false,
      popTitle:'',
      clientType:'',
      downloadTitle:'',
      downloadAppShow: '',
      menuDownloadAppShow: '',
      appDownloadUrl:'',
      addDesktopShow: '',
      i18n:{
        loading: this.$t('global.loading'),
        copied: this.$t('global.Your_invitation_link_has_been_copied'),
      }
    };
  },
  methods: {
    infoToast(msg){
      Dialog({
        content: msg,
        okText: this.$t('global.okText'),
        noCancelBtn: true
      });
    },
    vipPopupClick(){
        this.vipPopup = true
        this.popTitle = this.$t('recharge.popTitle_1')
      },
    //下载app
    downloadApp(){
        window.open(this.appDownloadUrl);
    },
    downloadAppClose(){
      this.addDesktopShow = false;
      this.downloadAppShow = false;
      localStorage.setItem("downloadApp", 'close');
    },
    showDownloadTip() {
      this.clientType = getClientType();
      if (this.clientType == "ios") {
        this.addDesktopShow = true;
        this.downloadAppShow = false;
        this.downloadTitle = this.$t('global.addDesktop');
      } else if (this.clientType == "android") {
        this.addDesktopShow = false;
        this.downloadAppShow = true;
        this.menuDownloadAppShow = true;
        if (window.localStorage.getItem("commonInfo")) {
          let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
          this.appDownloadUrl = commonInfo.appDownloadUrl;
        } else {
          axios.get("/api/system/common", {}).then((response) => {
            this.appDownloadUrl = response.data.data.appDownloadUrl;
          });
        }
        if(this.appDownloadUrl === '-') {
          this.downloadAppShow = false;
          return
        }
        this.downloadTitle = this.$t('global.downloadApp');
      }
    }
  },
  created() {
    if(localStorage.getItem("downloadApp") === 'close'){
      this.downloadAppShow = false
      this.addDesktopShow = false
    }else{
      this.showDownloadTip();
    } 
  }, 
  setup() {
    const _data = getCurrentInstance();
    const switchChecked = ref(true);
    const state = reactive({
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      imgUrl: "",
      icon: "notice",
      booleanTrue: true,
      showRound: false,
      tabbarActiveIndex: 4,
      tab11value: "0",
      userInfo: {
        mobile: "",
        inviteCode: "",
        avatar: "",
        vipLevel: "",
        vipTitle: "",
        myAvatar: "",
      },
      fundAccount: {
        cashAccount: "",
        coinAccount: "",
      },
      unreadLetterCount: 0,
      dailySignList: {}, //每日签到任务列表
      dailySignCurrent: [], //每日签到任务信息
      baseRewardAmount: '',
      extraRewardAmount: '',
      taskPop: false,
      taskAmount: 0,
      startFlag: false,
      showCheckIn: false,
    });
    const router = useRouter();
    const methods = {
      telegramClick(){
        window.open(state.commonInfo.telegramChannelUrl,"_self");
      },
      //按钮-VIP
      promotionClick() {
        router.push({
          name: "agentGrowth",
        });
      },
      //按钮-充值
      rechargeClick() {
        router.push({
          name: "rechargeBalance",
        });
      },
      //按钮-提现
      withdrawalClick(t) {
        router.push({
          path: 'withdrawalBalance',
					query: {
						amountType: t
					}
				})
      },
      //转换
      transferClick() {
        router.push({
          name: "cashTransfer",
        });
      },
      //任务页
      taskRewardClick() {
        router.push({
          name: "taskReward",
        });
      },
      //按钮-领取红包
      envelopesCenterClick() {
        router.push({
          name: "envelopesCenter",
        });
      },
      //按钮-钱包
      transactionsClick() {
        router.push({
          name: "transactionsHistoryList",
        });
        router.push({
          path: "transactionsHistoryList",
          query: {
            listType: 'coin'
          }
        })
      },
      //按钮-银行卡
      bankCardClick() {
        router.push({
          name: "bankCard",
        });
      },
      //按钮-修改密码
      ResetPasswordClick() {
        router.push({
          name: "resetPassword",
        });
      },
      //按钮-投诉建议
      complaintsSuggestionsClick() {
        // router.push({
        //   name: "complaintsSuggestions",
        // });
        const msg = 'Contact us through E-mail:support@oxxowin.com for any problem,working hours: 8:00-21:00'
        Toast.text(msg, {
          center: false,
          bottom: '10%',
          duration: 30000
        });
      },

      //按钮-退出登录
      logoutClick() {
        localStorage.removeItem('token')
        localStorage.removeItem('hasPassword')
        localStorage.removeItem('firstVisit')
        router.push({
          name: "userLogin",
        });
      },
      
      iconClick() {
        router.push({
          name: "envelopesCenter",
        });
      },

      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      avatarClick(url){
        router.push({
          name: "setAvatar",
          query: {img:url}
        });
      },
      copyClick(inviteCode){
        let url = state.commonInfo.frontBaseUrl
        let cInput = document.createElement("input")
        cInput.value = url+'?invite='+inviteCode
        document.body.appendChild(cInput)
        cInput.select()
        document.execCommand("copy")
        Toast.success(_data.data.i18n.copied)
        document.body.removeChild(cInput)
      },
      taskSignClick(a1, a2){
        const amount = a2 > 0 ? a1 + a2 : a1
        axios.post("/api/task/daily-sign/sign", {})
        .then((response)=> {
              if(response.data.code === 0){
                this.showCheckIn = false,
                this.taskReceivePop(amount);
              }else{
                Toast.fail("error!");
              }
        })
        .catch(function (error) {
          console.log(error);
          Toast.fail("Network anomaly!");
        });
      },
      taskReceivePop(amount) {
        state.taskAmount = amount;
        state.taskPop = true;
        setTimeout(() => {
          state.taskPop = false;
        }, 1500);
      },
    };
    onMounted(() => {
      methods.textToast(_data.data.i18n.loading);
      state.imgUrl = localStorage.getItem('countryImgUrl')
      //请求数据
      axios
        .get("/api/user/home", {})
        .then(function (response) {
          state.userInfo.mobile = response.data.data.user.mobile;
          state.userInfo.inviteCode = response.data.data.user.inviteCode;
          state.userInfo.myAvatar = response.data.data.user.avatar;
          state.userInfo.avatar = 
            require('../assets/images/' + state.imgUrl + 'avatar/' + response.data.data.user.avatar.slice(response.data.data.user.avatar.length-6))
          state.userInfo.vipLevel = response.data.data.user.vipLevel;
          state.fundAccount.cashAccount = response.data.data.cashAccount.availableAmount;
          state.fundAccount.coinAccount = response.data.data.coinAccount.availableAmount;
          state.unreadLetterCount = response.data.data.unreadLetterCount;

          state.dailySignList = response.data.data.dailySignList;
          if(state.dailySignList.length > 0){
            state.dailySignCurrent = response.data.data.dailySignCurrent;
            state.dailySignList = response.data.data.dailySignList;
            let i = state.dailySignCurrent.toSignDay
            state.baseRewardAmount = response.data.data.dailySignList[i].baseRewardAmount
            state.extraRewardAmount = response.data.data.dailySignList[i].extraRewardAmount

          }

          if (response.data.data.unreadLetterCount === 0) {
            state.icon = "notice";
          }
          Toast.hide("loading");
          const vipRules = state.commonInfo.vipRules;
          for(var i = 0; i < vipRules.length; i ++){
            if(vipRules[i].level === state.userInfo.vipLevel){
              state.userInfo.vipTitle = vipRules[i].title
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
    });
    return {
      ...toRefs(state),
      ...methods,
      switchChecked,
    };
  },
};
</script>

<style scoped>
.download-app{ position: fixed; top:0; left: 0; right: 0; height: 44px; z-index: 501; background:#193458;}
.download-app span{ position: absolute; right:10px; top:13px; opacity:.8;}
.add-Desktop .wrapper{position: absolute;bottom: 0; left: 0; right: 0; height: 190px; text-align: center; color: #fff;
  background: url(../assets/images/bg63.png) center bottom no-repeat; background-size:40px auto; }
.add-Desktop .wrapper p{ line-height: 20px; margin: 0; padding: 5px;}
.add-Desktop .wrapper img{ vertical-align: middle; width: 14px;}

.new-icon{position: absolute; right:7px;top:12px; width: 8px; height: 8px; border-radius: 4px; background:#f30b0b;z-index: 501; }
.nut-navbar, .nut-navbar-b0, .nut-navbar-b0 .nut-navbar {
  height: 40px !important;
  box-shadow: 0 0 0 !important;
}

.dot-right {
  position: absolute;
  top: 0;
  right: 20px;
}

.nut-tabs__titles.normal .nut-tabs__titles-item {
  font-size: 12px;
}
.vip-popup h3 {
  font-size: 15px;
  padding: 10px;
}
.vip-popup table {
  width: 100%;
}
.vip-popup table th {
  border: 1px #ccc solid;
  background: #f4f1ff;
  line-height: 28px;
  font-weight: 400;
}
.vip-popup table td {
  border: 1px #ccc solid;
  line-height: 18px;
  text-align: center;
}
.vip-popup dl {
  padding: 10px;
}
.vip-popup dt {
  font-size: 14px;
  line-height: 24px;
  color: #555;
}
.vip-popup dd {
  font-size: 12px;
  color: #999;
}






.my-info-b .fc-FFC700{font-size: 18px;vertical-align: top;}
.my-info-b .nut-button--small{font-size: 14px !important;}
.my-info-b .nut-button--primary {background: linear-gradient(135deg,#ff9e0d 0%,#ffa70d 45%,#ffb60d 83%,#ffbe0d 100%) !important; min-width:70px !important;padding: 0px 10px !important;}
.my-info-b .nut-button--info {background: linear-gradient(315deg,#498ff2 0%,#4965f2 100%) !important;min-width:70px !important;padding: 0px 10px !important;}
.my-info-b .mr10{ padding:5px 0 5px 30px;background: url(../assets/images/bg194.png) 5px 3px no-repeat;background-size: 20px auto;}






/* new */
.my-info-bg{ background: #1e246d; padding: 45px 10px 20px 10px;}
.my-info {background: url(../assets/images/20230601/img06.png) 0 0px no-repeat; background-size: 100% auto;
  margin: 0px 0px 28px 0px; height: 170px; border-radius:10px;}
.my-info .my-info-t{ display: flex; padding-top: 10px;}
.my-info .p-avatar { text-align: center; width: 33px; margin-left: 15px;}
.my-info .p-avatar img { width: 30px;height: 30px; border: 3px solid #fff;}
.my-info .avatarimg{border-radius: 50%;}
.my-info .my-info-t ul { padding: 8px 0; margin-bottom: 5px; width: 165px; margin-left: 3px;
  background: url(../assets/images/20230601/img07.png) right center no-repeat; background-size: auto 22px; }
.my-info .my-info-t ul li { line-height: 22px; height: 22px; color: #9c3300; padding-left: 8px;}
.my-info .my-info-t ul li span { color: #ffebbd; font-size: 14px; float: right; margin-right: 7px; font-weight: 600;}
.my-info .my-info-t ul li label { color: #ab94ff; margin-right: 2px;  width: 85px; float: left;}
.my-info-c{ height: 112px; text-align: left;  margin-bottom: 15px;}
.my-info-c span{ color: #000; opacity: 1;line-height: 24px; font-size: 10px; margin-left: 0px; display: block;}
.my-info-c .t{ display: flex; padding-left: 12px;position: relative; z-index: 100;height: 57px;}
.my-info-c .h5{width: 40%;  margin: 0; padding: 0; color: #000; line-height: 24px; height: 45px; 
  margin-bottom: 6px; font-size: 20px; position: relative; z-index: 105;}
.my-info-c .btn-box{ width: 60%;  height: 28px; overflow: hidden; margin-top: 22px; text-align: right;}
.my-info-c .op05{ opacity: .5;}
.my-info-c .btn-t{ margin-right: 5px; background: #f6d769; color: #514005;  border-radius: 13px;font-size: 10px; 
  padding: 0px; line-height: 24px; height: 24px; overflow: hidden; display: inline-block; width: 80px; text-align: center;}

.my-info-b{background: #f6d769 url(../assets/images/20230601/img10.png) center 140px; background-size: 160%; 
  margin: 10px;margin-bottom: 10px; padding:5px;  border-radius: 10px; display: flex; line-height: 22px; height: 70px; }
.my-info-b .t{ width: 33.3%;background: url(../assets/images/20230601/img12.png) center top no-repeat !important; background-size: 30px auto !important; text-align: center;}
.my-info-b h5{ margin: 0;color: #000; line-height: 22px; padding: 30px 0 0 0; font-size: 13px; font-weight: 300;}
.my-info-b span{ color: #000; opacity: .6;line-height: 15px;font-size: 12px;display: block; }
.my-info-b .left{ background: url(../assets/images/20230601/img11.png) center top no-repeat !important; background-size: 30px auto !important;}
.my-info-b .right{ background: url(../assets/images/20230601/img13.png) center top no-repeat !important; background-size: 30px auto !important;}
.my-info-b .right span{color: #c14a10;opacity: 1;}
.my-info-menu{ margin-top: -45px; margin-bottom: 70px;}
.my-info-menu .nut-cell{background: url(../assets/images/20230601/bg47.png) 10px center no-repeat;background-size: 16px;}
.logout-btn { margin: 0 10px;}
.logout-btn .nut-button--info{ border: 0 !important; margin: 0 !important; background: #e3e9f1 !important;}

.download-app a{ display: block; text-decoration:none;padding: 0 0 0 160px; line-height: 44px; color: #fff; margin: 0;
    background:#1e246d url(../assets/images/20230601/img34.png) 0px center no-repeat; background-size:100% auto}


.pop-top {
  background: url(../assets/images/20230601/img32.png) center 50px no-repeat;
  height: auto; width: 100%; padding-top: 105px;
  background-size: 140px auto; text-align: center;
}
.pop-top h4 {
  padding-top: 0px;
  margin-bottom: 0;
  font-size: 18px;
}
.pop-top p {padding-top: 10; margin: 0;}
.pop-top p strong {
  font-size: 36px;
  color: #fb7d26;
  font-weight: 500;
}
.popup-top{background:#000 !important}
.popup-top {
  overflow: hidden;}

</style>
<style>
.my-info-menu .nut-cell-group{ margin: 0 10px;}
.my-info-menu .nut-cell-group .nut-cell__title {margin-left: 20px !important;}
.my-info-menu .nut-cell{ padding: 12px 14px;}
.nut-actionsheet-panel .nut-actionsheet-menu{padding-bottom: 75px !important}
.nut-popup .nut-dialog{padding: 28px 11px 16px !important;}
.nut-tabbar-bottom .nut-tabbar-item{border-bottom: 3px solid #a8b8ca; height: 57px;}
.nut-tabbar-bottom .nut-tabbar-item__icon--unactive{border-bottom: 0px solid #a8b8ca; height: 60px;}
.icon-t1 .nut-cell__value{background: #ffd450 url(../assets/images/bg194.png) 5px center no-repeat; background-size: 18px; 
  color: #6c1207; padding: 0px 10px 0 30px; border-radius:10px;}
.login-bd .icon-t1 .nut-cell__value{background: #fff}
</style>
