<template>
  <div class="pop-list">
    <nut-elevator :index-list="dataList" :height="460" @click-item="clickItem"></nut-elevator>
  </div> 
</template>
<script>
import { reactive, toRefs } from "vue";
export default {
  name: 'BankListNG',
  data() {
    return {
      acceptKey: 'num',
      bankInfo:{
        name: '',
        id: '',
      },
      dataList: [
        {
          title: 'A',
          list: [
            {
              name: 'Access Bank',
              id: 'NGR044'
            },
            {
              name: 'Access Bank (Diamond)',
              id: 'NGR063'
            },
            {
              name: 'Abbey Mortgage Bank',
              id: 'NGR801'
            },
            {
              name: 'ALAT by WEMA',
              id: 'NGR035A'
            },
            {
              name: 'ASO Savings and Loans',
              id: 'NGR401'
            },
            {
              name: 'AMJU Unique Microfinance Bank',
              id: 'NGR090180'
            },
            {
              name: 'AG Mortgage Bank',
              id: 'NGR100028'
            },
            {
              name: 'AMML MFB',
              id: 'NGR090116'
            },
            {
              name: 'AccessMobile',
              id: 'NGR100013'
            },
            {
              name: 'Allworkers Microfinance Bank',
              id: 'NGR090131'
            },
            {
              name: 'AL-Barakah Microfinance Bank',
              id: 'NGR090133'
            },
            {
              name: 'Accion Microfinance Bank',
              id: 'NGR090134'
            },
            {
              name: 'Apeks Microfinance Bank',
              id: 'NGR090143'
            },
            {
              name: 'Alpha Kapital Microfinance Bank',
              id: 'NGR090169'
            },
            {
              name: 'Al-Hayat Microfinance Bank',
              id: 'NGR090277'
            },
            {
              name: 'Alekun Microfinance Bank',
              id: 'NGR090259'
            },
            {
              name: 'Above Only Microfinance Bank',
              id: 'NGR090260'
            },
            {
              name: 'Addosser Microfinance Bank',
              id: 'NGR090160'
            },
            {
              name: 'Astrapolaris Microfinance Bank',
              id: 'NGR090172'
            },
            {
              name: 'ABU Microfinance Bank',
              id: 'NGR090197'
            },
            {
              name: 'Auchi Microfinance Bank',
              id: 'NGR090264'
            },
            {
              name: 'Adeyemi College Staff Microfinance Bank',
              id: 'NGR090268'
            },
            {
              name: 'AB Microfinance Bank',
              id: 'NGR090270'
            },
          ]
        },
        {
          title: 'B',
          list: [
            {
              name: 'Bosak Microfinance Bank',
              id: 'NGR090176'
            },
            {
              name: 'Baobab Microfinance Bank',
              id: 'NGR090136'
            },
            {
              name: 'Brent Mortgage Bank',
              id: 'NGR070015'
            },
            {
              name: 'Boctrust Microfinance Bank',
              id: 'NGR090117'
            },
            {
              name: 'BC Kash Microfinance Bank',
              id: 'NGR090127'
            },
            {
              name: 'Baines Credit Microfinance Bank',
              id: 'NGR090188'
            },
            {
              name: 'Bowen Microfinance Bank',
              id: 'NGR50931'
            },
            {
              name: 'BRIDGEWAY MICROFINANCE BANK',
              id: 'NGR090393'
            }
          ]
        },
        {
          title: 'C',
          list: [
            {
              name: 'CEMCS Microfinance Bank',
              id: 'NGR50823'
            },
            {
              name: 'Citibank Nigeria',
              id: 'NGR023'
            },
            {
              name: 'Coronation Merchant Bank',
              id: 'NGR559'
            },
            {
              name: 'Consumer Microfinance Bank',
              id: 'NGR090130'
            },
            {
              name: 'Covenant Microfinance Bank',
              id: 'NGR070006'
            },
            {
              name: 'Contec Global Infotech Limited (NowNow)',
              id: 'NGR100032'
            },
            {
              name: 'Chikum Microfinance Bank',
              id: 'NGR090141'
            },
            {
              name: 'Credit Afrique Microfinance Bank',
              id: 'NGR090159'
            },
            {
              name: 'CIT Microfinance Bank',
              id: 'NGR090144'
            },
            {
              name: 'Cellulant',
              id: 'NGR100005'
            },
            {
              name: 'ChamsMobile',
              id: 'NGR303'
            },
          ]
        },
        {
          title: 'D',
          list: [
            {
              name: 'Daylight Microfinance Bank',
              id: 'NGR090167'
            }
          ]
        },
        {
          title: 'E',
          list: [
            {
              name: 'Ecobank Nigeria',
              id: 'NGR050'
            },
            {
              name: 'Ekondo Microfinance Bank',
              id: 'NGR562'
            },
            {
              name: 'Eyowo',
              id: 'NGR50126'
            },
            {
              name: 'EcoMobile',
              id: 'NGR100030'
            },
            {
              name: 'Eartholeum',
              id: 'NGR100021'
            },
            {
              name: 'Enterprise Bank',
              id: 'NGR000019'
            },
            {
              name: 'Empire trust MFB',
              id: 'NGR090114'
            },
            {
              name: 'Eso-E Microfinance Bank',
              id: 'NGR090166'
            },
            {
              name: 'Esan Microfinance Bank',
              id: 'NGR090189'
            },
            {
              name: 'Emeralds Microfinance Bank',
              id: 'NGR090273'
            },
            {
              name: 'Ecobank Xpress Account',
              id: 'NGR100008'
            },
            {
              name: 'eTranzact',
              id: 'NGR100006'
            },
            {
              name: 'e-Barcs Microfinance Bank',
              id: 'NGR090156'
            },
          ]
        },
        {
          title: 'F',
          list: [
            {
              name: 'First City Monument Bank',
              id: 'NGR214'
            },
            {
              name: 'Fidelity Bank',
              id: 'NGR070'
            },
            {
              name: 'First Bank of Nigeria',
              id: 'NGR011'
            },
            {
              name: 'FINATRUST MICROFINANCE',
              id: 'NGR608'
            },
            {
              name: 'FCMB Easy Account',
              id: 'NGR100031'
            },
            {
              name: 'Fidelity Mobile',
              id: 'NGR100019'
            },
            {
              name: 'FBNQUEST Merchant Bank',
              id: 'NGR060002'
            },
            {
              name: 'Fortis Microfinance Bank',
              id: 'NGR070002'
            },
            {
              name: 'FBN Mortgages Limited',
              id: 'NGR090107'
            },
            {
              name: 'First Generation Mortgage Bank',
              id: 'NGR070014'
            },
            {
              name: 'Fidfund Microfinance Bank',
              id: 'NGR090126'
            },
            {
              name: 'Fullrange Microfinance Bank',
              id: 'NGR090145'
            },
            {
              name: 'FFS Microfinance Bank',
              id: 'NGR090153'
            },
            {
              name: 'Futo Microfinance Bank',
              id: 'NGR090158'
            },
            {
              name: 'First Royal Microfinance Bank',
              id: 'NGR090164'
            },
            {
              name: 'FAST Microfinance Bank',
              id: 'NGR090179'
            },
            {
              name: 'FET',
              id: 'NGR100001'
            },
            {
              name: 'FBNMobile',
              id: 'NGR100014'
            },
            {
              name: 'FortisMobile',
              id: 'NGR100016'
            },
            {
              name: 'Flutterwave Technology Solutions Limited',
              id: 'NGR110002'
            },
            {
              name: 'Firmus MFB',
              id: 'NGR090366'
            },
          ]
        },
        {
          title: 'G',
          list: [
            {
              name: 'Guaranty Trust Bank',
              id: 'NGR058'
            },
            {
              name: 'Globus Bank',
              id: 'NGR00103'
            },
            {
              name: 'GoMoney',
              id: 'NGR100022'
            },
            {
              name: 'Gateway Mortgage Bank',
              id: 'NGR070009'
            },
            {
              name: 'Gowans Microfinance Bank',
              id: 'NGR090122'
            },
            {
              name: 'Gashua Microfinance Bank',
              id: 'NGR090168'
            },
            {
              name: 'Greenville Microfinance Bank',
              id: 'NGR090269'
            },
            {
              name: 'GreenBank Microfinance Bank',
              id: 'NGR090178'
            },
            {
              name: 'GTMobile',
              id: 'NGR100009'
            },
            {
              name: 'Greenwich Merchant Bank',
              id: 'NGR060004'
            },
            {
              name: 'Grooming Microfinance Bank',
              id: 'NGR090195'
            },
          ]
        },
        {
          title: 'H',
          list: [
            {
              name: 'Heritage Bank',
              id: 'NGR030'
            },
            {
              name: 'Hackman Microfinance Bank',
              id: 'NGR51251'
            },
            {
              name: 'Hasal Microfinance Bank',
              id: 'NGR50383'
            },
            {
              name: 'HeritageTagPay',
              id: 'NGR100023'
            },
            {
              name: 'Haggai Mortgage Bank Limited',
              id: 'NGR070017'
            },
            {
              name: 'Hedonmark',
              id: 'NGR100017'
            },
          ]
        },
        {
          title: 'I',
          list: [
            {
              name: 'Ibile Microfinance',
              id: 'NGR51244'
            },
            {
              name: 'Infinity MFB',
              id: 'NGR50457'
            },
            {
              name: 'Intellifin',
              id: 'NGR100027'
            },
            {
              name: 'Innovectives Kesh',
              id: 'NGR100029'
            },
            {
              name: 'Imperial Homes Mortgage Bank',
              id: 'NGR100024'
            },
            {
              name: 'Infinity Trust Mortgage Bank',
              id: 'NGR070016'
            },
            {
              name: 'IRL Microfinance Bank',
              id: 'NGR090149'
            },
            {
              name: 'Imo State Microfinance Bank',
              id: 'NGR090258'
            },
          ]
        },
        {
          title: 'J',
          list: [
            {
              name: 'Jaiz Bank',
              id: 'NGR301'
            },
            {
              name: 'Jubilee-Life Mortgage Bank',
              id: 'NGR090003'
            }
          ]
        },
        {
          title: 'K',
          list: [
            {
              name: 'Keystone Bank',
              id: 'NGR082'
            },
            {
              name: 'Kuda Bank',
              id: 'NGR50211'
            },
            {
              name: 'Kegow',
              id: 'NGR100015'
            },
            {
              name: 'KCMB Microfinance Bank',
              id: 'NGR090191'
            }
          ]
        },
        {
          title: 'L',
          list: [
            {
              name: 'Lagos Building Investment Company Plc',
              id: 'NGR90052'
            },
            {
              name: 'Lovonus Microfinance Bank',
              id: 'NGR090265'
            },
            {
              name: 'La Fayette Microfinance Bank',
              id: 'NGR090155'
            },
            {
              name: 'Lapo Microfinance Bank',
              id: 'NGR090177'
            },
            {
              name: 'Lavender Microfinance Bank',
              id: 'NGR090271'
            },
            {
              name: 'Letshego MFB',
              id: 'NGR090420'
            },
          ]
        },
        {
          title: 'M',
          list: [
            {
              name: 'Mayfair MFB',
              id: 'NGR50563'
            },
            {
              name: 'MoneyBox',
              id: 'NGR100020'
            },
            {
              name: 'Money Trust Microfinance Bank',
              id: 'NGR090129'
            },
            {
              name: 'Mutual Trust Microfinance Bank',
              id: 'NGR090151'
            },
            {
              name: 'Mainstreet Microfinance Bank',
              id: 'NGR090171'
            },
            {
              name: 'Malachy Microfinance Bank',
              id: 'NGR090174'
            },
            {
              name: 'Mutual Benefits Microfinance Bank',
              id: 'NGR090190'
            },
            {
              name: 'Midland Microfinance Bank',
              id: 'NGR090192'
            },
            {
              name: 'Mkudi',
              id: 'NGR100011'
            },
            {
              name: 'MAUTECH Microfinance Bank',
              id: 'NGR090423'
            },
            {
              name: 'Manny Microfinance bank',
              id: 'NGR090383'
            },
            {
              name: 'M36',
              id: 'NGR100035'
            },
          ]
        },
        {
          title: 'N',
          list: [
            {
              name: 'Nagarta Microfinance Bank',
              id: 'NGR090152'
            },
            {
              name: 'NIRSAL Microfinance Bank',
              id: 'NGR090194'
            },
            {
              name: 'New Dawn Microfinance Bank',
              id: 'NGR090205'
            },
            {
              name: 'Navy Microfinance Bank',
              id: 'NGR090263'
            },
            {
              name: 'NIP Virtual Bank',
              id: 'NGR999999'
            },
            {
              name: 'NPF MicroFinance Bank',
              id: 'NGR070001'
            },
            {
              name: 'Nova Merchant Bank',
              id: 'NGR060003'
            },
            {
              name: 'New Prudential Bank',
              id: 'NGR090108'
            },
            {
              name: 'Ndiorah Microfinance Bank',
              id: 'NGR090128'
            },
          ]
        },
        {
          title: 'O',
          list: [
            {
              name: 'One Finance',
              id: 'NGR565'
            },
            {
              name: 'Omoluabi savings and loans',
              id: 'NGR070007'
            },
            {
              name: 'Ohafia Microfinance Bank',
              id: 'NGR090119'
            },
            {
              name: 'Okpoga Microfinance Bank',
              id: 'NGR090161'
            },
            {
              name: 'Olabisi Onabanjo University Microfinance Bank',
              id: 'NGR090272'
            },
          ]
        },
        {
          title: 'P',
          list: [
            {
              name: '9 Payment Service Bank',
              id: 'NGR000802'
            },
            {
              name: 'PalmPay',
              id: 'NGR999991'
            },
            {
              name: 'Parallex Bank',
              id: 'NGR526'
            },
            {
              name: 'Parkway - ReadyCash',
              id: 'NGR311'
            },
            {
              name: 'Paycom',
              id: 'NGR999992'
            },
            {
              name: 'Petra Mircofinance Bank Plc',
              id: 'NGR50746'
            },
            {
              name: 'Polaris Bank',
              id: 'NGR076'
            },
            {
              name: 'Providus Bank',
              id: 'NGR101'
            },
            {
              name: 'Platinum Mortgage Bank',
              id: 'NGR070013'
            },
            {
              name: 'Paga',
              id: 'NGR327'
            },
            {
              name: 'PayAttitude Online',
              id: 'NGR110001'
            },
            {
              name: 'PatrickGold Microfinance Bank',
              id: 'NGR090317'
            },
            {
              name: 'Page Financials',
              id: 'NGR070008'
            },
            {
              name: 'Parralex Microfinance bank',
              id: 'NGR090004'
            },
            {
              name: 'Personal Trust Microfinance Bank',
              id: 'NGR090135'
            },
            {
              name: 'PecanTrust Microfinance Bank',
              id: 'NGR090137'
            },
            {
              name: 'Pennywise Microfinance Bank',
              id: 'NGR090196'
            },
          ]
        },
        {
          title: 'Q',
          list: [
            {
              name: 'Quickfund Microfinance Bank',
              id: 'NGR090261'
            }
          ]
        },
        {
          title: 'R',
          list: [
            {
              name: 'Rubies MFB',
              id: 'NGR125'
            },
            {
              name: 'Rubies Microfinance',
              id: 'NGR090175'
            },
            {
              name: 'Rand merchant Bank',
              id: 'NGR502'
            },
            {
              name: 'Refuge Mortgage Bank',
              id: 'NGR070011'
            },
            {
              name: 'Regent Microfinance Bank',
              id: 'NGR090125'
            },
            {
              name: 'Richway Microfinance Bank',
              id: 'NGR090132'
            },
            {
              name: 'Royal Exchange Microfinance Bank',
              id: 'NGR090138'
            },
            {
              name: 'Reliance Microfinance Bank',
              id: 'NGR090173'
            },
            {
              name: 'RenMoney Microfinance Bank',
              id: 'NGR090198'
            }
          ]
        },
        {
          title: 'S',
          list: [
            {
              name: 'Stanbic IBTC Bank',
              id: 'NGR221'
            },
            {
              name: 'Sterling Bank',
              id: 'NGR232'
            },
            {
              name: 'SDH Merchant Bank Limited',
              id: 'NGR501'
            },
            {
              name: 'Sparkle Microfinance Bank',
              id: 'NGR51310'
            },
            {
              name: 'Standard Chartered Bank',
              id: 'NGR068'
            },
            {
              name: 'Suntrust Bank',
              id: 'NGR100'
            },
            {
              name: 'SafeTrust',
              id: 'NGR090006'
            },
            {
              name: 'Seed Capital Microfinance Bank',
              id: 'NGR090112'
            },
            {
              name: 'Sagamu Microfinance Bank',
              id: 'NGR090140'
            },
            {
              name: 'Stanford Microfinance Bak',
              id: 'NGR090162'
            },
            {
              name: 'Stellas Microfinance Bank',
              id: 'NGR090262'
            },
            {
              name: 'Stanbic IBTC @ease wallet',
              id: 'NGR100007'
            },
            {
              name: 'Safe Haven MFB',
              id: 'NGR090286'
            },
          ]
        },
        {
          title: 'T',
          list: [
            {
              name: 'TAJ Bank',
              id: 'NGR302'
            },
            {
              name: 'TCF MFB',
              id: 'NGR51211'
            },
            {
              name: 'Titan Bank',
              id: 'NGR102'
            },
            {
              name: 'Trident Microfinance Bank',
              id: 'NGR090146'
            },
            {
              name: 'Trustbond Mortgage Bank',
              id: 'NGR090005'
            },
            {
              name: 'Trustfund Microfinance Bank',
              id: 'NGR090276'
            },
            {
              name: 'TeasyMobile',
              id: 'NGR100010'
            },
            {
              name: 'Tangerine Bank',
              id: 'NGR090426'
            },
          ]
        },
        {
          title: 'U',
          list: [
            {
              name: 'United Bank For Africa',
              id: 'NGR033'
            },
            {
              name: 'Union Bank of Nigeria',
              id: 'NGR032'
            },
            {
              name: 'Unity Bank',
              id: 'NGR215'
            },
            {
              name: 'Unical Microfinance Bank',
              id: 'NGR090193'
            },
            {
              name: 'UNN MFB',
              id: 'NGR090251'
            },
            {
              name: 'Uniben Microfinance Bank',
              id: 'NGR090266'
            },
          ]
        },
        {
          title: 'V',
          list: [
            {
              name: 'VFD Microfinance Bank',
              id: 'NGR566'
            },
            {
              name: 'Verite Microfinance Bank',
              id: 'NGR090123'
            },
            {
              name: 'Visa Microfinance Bank',
              id: 'NGR090139'
            },
            {
              name: 'Virtue Microfinance Bank',
              id: 'NGR090150'
            },
            {
              name: 'VTNetworks',
              id: 'NGR100012'
            },
          ]
        },
        {
          title: 'W',
          list: [
            {
              name: 'Wema Bank',
              id: 'NGR035'
            },
            {
              name: 'Wetland Microfinance Bank',
              id: 'NGR090120'
            }
          ]
        },
        {
          title: 'X',
          list: [
            {
              name: 'Xslnce Microfinance Bank',
              id: 'NGR090124'
            }
          ]
        },
        {
          title: 'Y',
          list: [
            {
              name: 'Yes Microfinance Bank',
              id: 'NGR090142'
            }
          ]
        },
        {
          title: 'Z',
          list: [
            {
              name: 'Zenith Bank',
              id: 'NGR057'
            },
            {
              name: 'Zinternet Nigera Limited',
              id: 'NGR100025'
            },
            {
              name: 'ZenithMobile',
              id: 'NGR100018'
            },
          ]
        }
      ]
    };
  },
  methods: {
    clickItem(key, item) {
      this.bankInfo.name = item.name
      this.bankInfo.id = item.id
      this.$emit("getDataNG", this.bankInfo);
    },
  },
  setup() {
    const state = reactive({
    });
    return { ...toRefs(state)};
  }
};
</script>
<style scoped>
</style>
<style>
.pop-list { padding: 10px !important; text-align: left; height: 461px;}
.nut-elevator__bars__inner__item{ padding: 0px 5px !important;}
.nut-elevator__list__item__code:after{    height: 2px !important;background-color: rgb(79, 11, 138) !important;}
</style>ß