<template>
  <div class="pop-list">
    <nut-elevator :index-list="dataList" :height="460" @click-item="clickItem"></nut-elevator>
  </div> 
</template>
<script>
import { reactive, toRefs } from "vue";
export default {
  name: 'BankListMX',
  data() {
    return {
      acceptKey: 'num',
      bankInfo:{
        name: '',
        id: '',
      },
      dataList: [
        {
          title: 'CLABE',
          list: [
            {
              name: 'CLABE',
              id: 'CLABE'
            },
          ]
        },
        {
          title: 'A',
          list: [
            {
              name: 'AKALA',
              id: 'MXNAKALA'
            },
            {
              name: 'AZTECA',
              id: 'MXNAZTECA'
            },
            {
              name: 'AFIRME',
              id: 'MXNAFIRME'
            },
            {
              name: 'AUTOFIN',
              id: 'MXNAUTOFIN'
            },
            {
              name: 'ACTINVER',
              id: 'MXNACTINVER'
            },
            {
              name: 'ACCENDO BANCO',
              id: 'MXNACCENDOBANCO'
            },
            {
              name: 'ABC CAPITAL',
              id: 'MXNABCCAPITAL'
            },
            {
              name: 'ASP INTEGRA OPC',
              id: 'MXNASPINTEGRAOPC'
            },
            {
              name: 'AMERICAN EXPRES',
              id: 'MXNAMERICANEXPRES'
            },
          ]
        },
        {
          title: 'B',
          list: [
            {
              name: 'BAJIO',
              id: 'MXNBAJIO'
            },
            {
              name: 'BANSI',
              id: 'MXNBANSI'
            },
            {
              name: 'BBASE',
              id: 'MXNBBASE'
            },
            {
              name: 'BMONEX',
              id: 'MXNBMONEX'
            },
            {
              name: 'BANAMEX',
              id: 'MXNBANAMEX'
            },
            {
              name: 'BANORTE',
              id: 'MXNBANORTE'
            },
            {
              name: 'BANCREA',
              id: 'MXNBANCREA'
            },
            {
              name: 'BANSEFI',
              id: 'MXNBANSEFI'
            },
            {
              name: 'BANXICO',
              id: 'MXNBANXICO'
            },
            {
              name: 'BANKAOOL',
              id: 'MXNBANKAOOL'
            },
            {
              name: 'BANOBRAS',
              id: 'MXNBANOBRAS'
            },
            {
              name: 'BARCLAYS',
              id: 'MXNBARCLAYS'
            },
            {
              name: 'BANREGIO',
              id: 'MXNBANREGIO'
            },
            {
              name: 'BBVA BANCOMER2',
              id: 'MXNBBVABANCOMER2'
            },
            {
              name: 'BANCO FINTERRA',
              id: 'MXNBANCOFINTERRA'
            },
            {
              name: 'BBVABANCOMER',
              id: 'MXNBBVABANCOMER'
            },
            {
              name: 'BANCOPPEL',
              id: 'MXNBANCOPPEL'
            },
            {
              name: 'BANCOMEXT',
              id: 'MXNBANCOMEXT'
            },
            {
              name: 'BANJERCITO',
              id: 'MXNBANJERCITO'
            },
            {
              name: 'BANK OF AMERICA',
              id: 'MXNBANKOFAMERICA'
            },
          ]
        },
        {
          title: 'C',
          list: [
          {
            name: 'CIBANCO',
            id: 'MXNCIBANCO'
          },
          {
            name: 'CLS',
            id: 'MXNCLS'
          },
          {
            name: 'CREDICAPITAL',
            id: 'MXNCREDICAPITAL'
          },
          {
            name: 'CI BOLSA',
            id: 'MXNCIBOLSA'
          },
          {
            name: 'CB INTERCAM',
            id: 'MXNCBINTERCAM'
          },
          {
            name: 'CoDi Valida',
            id: 'MXNCoDiValida'
          },
          {
            name: 'COMPARTAMOS',
            id: 'MXNCOMPARTAMOS'
          },
          {
            name: 'CREDIT SUISSE',
            id: 'MXNCREDITSUISSE'
          },
          {
            name: 'CAJA POP MEXICA',
            id: 'MXNCAJAPOPMEXICA'
          },
          {
            name: 'CAJA TELEFONIST',
            id: 'MXNCAJATELEFONIST'
          },
          {
            name: 'CRISTOBAL COLON',
            id: 'MXNCRISTOBALCOLON'
          },
          ]
        },
        {
          title: 'D',
          list: [
            {
              name: 'DEUTSCHE',
              id: 'MXNDEUTSCHE'
            },
            {
              name: 'DONDE',
              id: 'MXNDONDE'
            },
          ]
        },
        {
          title: 'E',
          list: [
            {
              name: 'EVERCORE',
              id: 'MXNEVERCORE'
            },
            {
              name: 'ESTRUCTURADORES',
              id: 'MXNESTRUCTURADORES'
            },           
          ]
        },
        {
          title: 'F',
          list: [
            {
              name: 'FOMPED',
              id: 'MXNFOMPED'
            },
            {
              name: 'FINAMEX',
              id: 'MXNFINAMEX'
            },
            {
            name: 'FINCOMUN',
              id: 'MXNFINCOMUN'
            },
            {
              name: 'FONDO (FIRA)',
              id: 'MXNFONDO(FIRA)'
            },
          ]
        },
        {
          title: 'G',
          list: [
            {
              name: 'GBM',
              id: 'MXNGBM'
            },
          ]
        },
        {
          title: 'H',
          list: [
            {
              name: 'HSBC',
              id: 'MXNHSBC'
            },
            {
              name: 'HDI SEGUROS',
              id: 'MXNHDISEGUROS'
            },
            {
              name: 'HIPOTECARIA FED',
              id: 'MXNHIPOTECARIAFED'
            },
          ]
        },
        {
          title: 'I',
          list: [
          {
            name: 'ICBC',
            id: 'MXNICBC'
          },
          {
            name: 'INVEX',
            id: 'MXNINVEX'
          },
          {
            name: 'INBURSA',
            id: 'MXNINBURSA'
          },
          {
            name: 'INDEVAL',
            id: 'MXNINDEVAL'
          },
          {
            name: 'INVERCAP',
            id: 'MXNINVERCAP'
          },
          {
            name: 'INMOBILIARIO',
            id: 'MXNINMOBILIARIO'
          },
          {
            name: 'INTERCAM BANCO',
            id: 'MXNINTERCAMBANCO'
          },  
          ]
        },
        {
          title: 'J',
          list: [
            {
              name: 'JP MORGAN',
              id: 'MXNJPMORGAN'
            },
          ]
        },
        {
          title: 'K',
          list: [
            {
              name: 'KUSPIT',
              id: 'MXNKUSPIT'
            },
          ]
        },
        {
          title: 'L',
          list: [
            {
              name: 'LIBERTAD',
              id: 'MXNLIBERTAD'
            },
          ]
        },
        {
          title: 'M',
          list: [
            {
              name: 'MIFEL',
              id: 'MXNMIFEL'
            },
            {
              name: 'MASARI',
              id: 'MXNMASARI'
            },
            {
              name: 'MONEXCB',
              id: 'MXNMONEXCB'
            },
            {
              name: 'MUFG',
              id: 'MXNMUFG'
            },
            {
              name: 'MIZUHO BANK',
              id: 'MXNMIZUHOBANK'
            },
            {
              name: 'MULTIVA BANCO',
              id: 'MXNMULTIVABANCO'
            },
            {
              name: 'MULTIVA CBOLSA',
              id: 'MXNMULTIVACBOLSA'
            },
          ]
        },
        {
          title: 'N',
          list: [
            {
              name: 'NAFIN',
              id: 'MXNNAFIN'
            },
          ]
        },
        {
          title: 'P',
          list: [
            {
              name: 'PAGATODO',
              id: 'MXNPAGATODO'
            },
            {
              name: 'PROFUTURO',
              id: 'MXNPROFUTURO'
            },
          ]
        },
        {
          title: 'R',
          list: [
            {
              name: 'REFORMA',
              id: 'MXNREFORMA'
            },
          ]
        },
        {
          title: 'S',
          list: [
            {
              name: 'STP',
              id: 'MXNSTP'
            },
            {
              name: 'SHINHAN',
              id: 'MXNSHINHAN'
            },
            {
              name: 'SABADELL',
              id: 'MXNSABADELL'
            },

            {
              name: 'SANTANDER',
              id: 'MXNSANTANDER'
            },
            {
              name: 'SCOTIABANK',
              id: 'MXNSCOTIABANK'
            },
            {
              name: 'SANTANDER2',
              id: 'MXNSANTANDER2'
            },
          ]
        },
        {
          title: 'T',
          list: [
            {
              name: 'TRANSFER',
              id: 'MXNTRANSFER'
            },
          ]
        },
        {
          title: 'U',
          list: [
            {
              name: 'UNAGRA',
              id: 'MXNUNAGRA'
            },
          ]
        },
        {
          title: 'V',
          list: [
            {
              name: 'VALUE',
              id: 'MXNVALUE'
            },
            {
              name: 'VALMEX',
              id: 'MXNVALMEX'
            },
            {
              name: 'VECTOR',
              id: 'MXNVECTOR'
            },
            {
              name: 'VE POR MAS',
              id: 'MXNVEPORMAS'
            },
            {
              name: 'VOLKSWAGEN',
              id: 'MXNVOLKSWAGEN'
            },
          ]
        }
      ]
    };
  },
  methods: {
    clickItem(key, item) {
      this.bankInfo.name = item.name
      this.bankInfo.id = item.id
      this.$emit("getDataMX", this.bankInfo);
    },
  },
  setup() {
    const state = reactive({
    });
    return { ...toRefs(state)};
  }
};
</script>
<style scoped>
</style>
<style>
.pop-list { padding: 10px !important; text-align: left; height: 461px;}
.nut-elevator__bars__inner__item{ padding: 0px 5px !important;}
.nut-elevator__list__item__code:after{    height: 2px !important;background-color: rgb(79, 11, 138) !important;}
</style>ß