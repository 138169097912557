<template>
  <div>
    <nut-navbar
    :left-show="false"
      :title="$t('withdrawalList.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>
    <div class="pt50 scroll-body">
      <div class="p20">

          <ListEmpty v-show="listNoData"></ListEmpty>

          <div class="csls" id="scroll">
            <nut-infiniteloading
                containerId = 'scroll'
                :use-window='false'
                :has-more="hasNext"
                :load-txt='$t("global.loading")'
                :load-more-txt='$t("global.noMore")'
                :load-icon= 'null'
                @load-more="loadMore"
            >
              <div
                class="csls-item"
                v-for="(item, index) in list"
                :key="index">
                <h4>
                  <span :class="item.warning ? 'fc-red' : 'fc-green'">{{ item.orderNum }}</span>
                  <span :class="item.warning ? 'tx-right-r' : 'tx-right-g'">{{ item.statusDesc }}</span>
                </h4>
                <div class="bd">{{$t("withdrawalList.receiptAmount")}} : {{ item.receiptAmount }}</div>
                <p>
                  {{$t("withdrawalList.applyAmount")}} : {{ item.applyAmount }} 
                  <span class="ml20">{{$t("withdrawalList.withdrawFee")}} : {{ item.withdrawFee }}</span>
                  <nut-button v-if="item.status === 'wait_audit'" type="primary" class="btn-s" @click="cancelWithdraw(item.orderNum)">{{ $t('global.cancelText') }}</nut-button>
                </p>
                <p></p>
                <p v-if="item.status === 'failed'" style="color: red">{{$t("withdrawalList.pleaseCheckBank")}}</p>
                
              </div>
            </nut-infiniteloading>
          </div>
      </div>
    </div>

    <nut-drag direction="y" :style="{ top: '350px', right:'5px', zIndex: '1000 !important'}" @click="showPopUps = true">
      <div class="drag-wb-history">
        <img :src="require('../assets/images/bg303.png')"/>
      </div>
    </nut-drag>

    <nut-popup pop-class="pop-demo" v-model:visible="showPopUps" :style="{height: '200px'}">
        <div class="hd">{{ $t('withdrawal.tx_contact_us') }}</div>
        <div class="bd">
          {{$t('withdrawal.tx5b')}}<strong style="color:brown">{{ $t('bankCard.email') }}: {{ eMail }} </strong>{{$t('withdrawal.tx5_2')}} 
        </div>
        <div class="btn">
          <nut-button type="primary" @click="showPopUps = false">{{ $t('game.gotitBtn') }}</nut-button>
        </div>
      </nut-popup>

  </div>
</template>

<script>
import { reactive, toRefs, onMounted} from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import ListEmpty from "../components/ListEmpty.vue"
import { isBlank } from '@/assets/js/commonUtil';

export default {
  components: {
    ListEmpty: ListEmpty,
  },
  data() {
    return {
      amountType:'',
      listNoData: true,
      showPopUps: false,
      list: [],
      current: 1,
      hasNext:''
    };
  },
  methods: {
    loadMore(done){
      if(this.hasNext){
        this.getCoinList(this.current + 1)
        setTimeout(() => {
          done()
        }, 500);
      }
    },
    filters(s) {
      let txt = "";
      switch (s) {
        case 'wait_audit':
          txt = this.$t("filtersText.wait_audit");
          break;
        case 'audit_accept':
          txt = this.$t("filtersText.audit_accept");
          break;
        case 'audit_reject':
          txt = this.$t("filtersText.audit_reject");
          break;
        case 'processing':
          txt = this.$t("filtersText.processing");
          break;
        case 'success':
          txt = this.$t("filtersText.success");
          break;
        case 'failed':
          txt = this.$t("filtersText.failed");
          break;
      }
      return txt;
    },

    getCoinList(c) {
      axios({
        url: "/api/fund/withdraw/paginate",
        method: "get",
        params: { current: c, fundAccountType: this.amountType },
      }).then((res) => {
        for (let i = 0; i < res.data.data.records.length; i++) {
          this.list.push(res.data.data.records[i]);
        }
        this.current = res.data.data.current;
        this.hasNext = res.data.data.hasNext;
        if (this.list.length > 0) {
          this.listNoData = false;
        }
        Toast.hide("loading")
      });
    },
    textToast(msg) {
      Toast.loading(msg, {
        id:"loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover:true,
      });
    },
  },
  created() {
    this.textToast(this.$t('global.loading'))
    this.amountType = this.$route.query.amountType;
    this.getCoinList(1);
  },

  setup() {
    const state = reactive({
      eMail:'',
    });
    onMounted(() => {
      let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
      state.eMail = isBlank(commonInfo) ? ' ': commonInfo.serviceEmail
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      cancelWithdraw(o){
        axios
        .post("/api/fund/withdraw/cancel", {
          orderNum: o
        })
        .then((res) => {
          if (res.data.code === 0) {
            router.go(0);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>
.btn-s{margin-right: 5px;
    border: 0;
    background: #ffe477;
    color: #fff;
    border-radius: 10px;
    font-size: 10px;
    padding: 0px 8px;
    line-height: 20px;
    opacity: .8;
    height: 20px;
    overflow: hidden;
    display: inline-block; float:right;}


.ml20{ margin-left: 20px;}
.fc-red{ font-weight: 300;}
.fc-green{font-weight: 300;}
.scroll-body{
  position: fixed;
  top:0;
  left: 0;
  right:0;
  bottom: 0;
  overflow: auto
}
.nut-pagination {
  margin-top: 10px;
  justify-content: center;
}
.csls{
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 10px;right: 10px;top: 60px; bottom: 10px;
}

</style>

<style>
.nav-icon-right .nut-navbar__title .nut-icon {
  position: absolute !important;
  right: 10px !important;
}
</style>
