//import { createI18n } from 'vue-i18n'
import {isBlank, isNotBlank} from "@/assets/js/commonUtil";

/**
 * 是否debug模式
 * @returns {string}
 */
export function getDebugMode() {
    const debugMode = document.querySelector('body').getAttribute('dm');
    if (debugMode === 'DEBUG_MODE') {
        return 'true';
    }
    return debugMode;
}
/**
 * 获取国家
 *
 * 印度    in
 * 菲律宾  ph
 * 巴西    br
 * 尼日利亚 ng
 * 墨西哥   mx
 * @returns {string}
 */
export function getCountry() {
    const country = document.querySelector('body').getAttribute('c');
    if (country === 'COUNTRY') {
        return 'in';
    }
    return country;
}

/**
 * 设置页面标题
 */
export function setAppTitle() {
    switch (getCountry()) {
        case 'in':
            if(document.querySelector('body').getAttribute('s') === 's1'){
                document.title = 'OXXOWIN'
            }else if(document.querySelector('body').getAttribute('s') === 's2'){
                document.title = 'WheelRich'
            }
            break;
        case 'vn':
            document.title = 'WINGO100'
            break;
    }
}
/**
 * 设置皮肤模版
 */
 export function setSkinTemplate() {
    let country = document.querySelector('body').getAttribute('c');
    if (country === 'COUNTRY') country = 'in'

    let template = document.querySelector('body').getAttribute('s');
    if (template === 'TEMPLATE') template = 's1'
    
    import("@/assets/css/" + country + "_" + template +".css");
}
/**
 * 获取默认语言
 *
 * 印度   in_EN 
 * 越南  vn_VI
 * @returns {string}
 */
export function getDefaultLanguage() {
    if(localStorage.getItem("SET_LANG")){
        return localStorage.getItem("SET_LANG")
    }else{
        const country = getCountry();
        const jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
        if (country === 'in') {
            if(jsSrc.indexOf('hi') != -1){
                localStorage.setItem("SET_LANG",'in_HI');
                localStorage.setItem("ACCEPT_LANGUAGE",'hi');
                localStorage.setItem("countryImgUrl",'in/HI/');
                return 'in_HI'
            }else{
                localStorage.setItem("SET_LANG",'in_EN');
                localStorage.setItem("ACCEPT_LANGUAGE",'en');
                localStorage.setItem("countryImgUrl",'in/EN/');
                return 'in_EN'
            }
        }else if(country === 'vn'){
            localStorage.setItem("SET_LANG",'vn_VI');
            localStorage.setItem("ACCEPT_LANGUAGE",'vi');
            localStorage.setItem("countryImgUrl",'vn/VI/');
            return 'vn_VI'
        }else{
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            return 'in_EN'
        }
    }
}
/**
 * 获取语言选项
 *
 * 印度   English , हिन्दी
 * 越南  Tiếng Việt
 * @returns {string}
 */
 export function getLanguage() {
    const country = getCountry();
    if (country === 'in') {
        return [{name: 'English'},{name: 'हिन्दी'}]
    }else if(country === 'vn'){
        return [{name: 'Tiếng Việt'}]
    }else{
        return []
    }
}
/**
 * 获取当前语言文本 
 * @returns {string}
 */
 export function getLanguageText(language) { //TODO
    switch(language){
        case 'in_EN':
            return 'English'
        case 'in_HI':
            return 'हिन्दी'
        case 'vn_VI':
            return 'Tiếng Việt'
    }
}

/**
 * 设置语言
 * 英语    'English'
 * 印地语   'हिन्दी'
 * 越南语  'Tiếng Việt'
 * @returns {string}
 */
 export function setLanguage(country, language) {
    switch(language){
        case 'English':
            localStorage.setItem("SET_LANG",country + '_EN');
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            break;
        case 'हिन्दी':
            localStorage.setItem("SET_LANG",country + '_HI');
            localStorage.setItem("ACCEPT_LANGUAGE",'hi');
            break;
        case 'Tiếng Việt':
            localStorage.setItem("SET_LANG",country + '_VI');
            localStorage.setItem("ACCEPT_LANGUAGE",'vi');
            break;
        default:
            break; 
    }
}
/**
 * 设置 ACCEPT_LANGUAGE
 * 印度    'en', 'hi'
 * 越南  'vn'
 * @returns {string}
 */
 export function setAcceptLanguage() {
    if(!localStorage.getItem('ACCEPT_LANGUAGE')){
        const jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
        const country = getCountry();
        if (country === 'in') {
            if(jsSrc.indexOf('en') != -1){
                localStorage.setItem("SET_LANG",'in_EN');
                localStorage.setItem("ACCEPT_LANGUAGE",'en');
                localStorage.setItem("countryImgUrl",'in/EN/');
            }else if(jsSrc.indexOf('hi') != -1){
                localStorage.setItem("SET_LANG",'in_HI');
                localStorage.setItem("ACCEPT_LANGUAGE",'hi');
                localStorage.setItem("countryImgUrl",'in/HI/');
            }else{
                localStorage.setItem("SET_LANG",'in_EN');
                localStorage.setItem("ACCEPT_LANGUAGE",'en');
                localStorage.setItem("countryImgUrl",'in/EN/');
            }
        }else if(country === 'vn'){
            localStorage.setItem("SET_LANG",'vn_VI');
            localStorage.setItem("ACCEPT_LANGUAGE",'vi');
            localStorage.setItem("countryImgUrl",'vn/VI/');
        }else{
            localStorage.setItem("ACCEPT_LANGUAGE",'en');
            return
        }
    }
}
/**
 * 设置图片路径
 * @returns {string}
 */
 export function setImgUrl() {
    const currentLanguage = getDefaultLanguage()
    const template = document.querySelector('body').getAttribute('s');
    switch (currentLanguage) {
        case "in_EN":
            if(template === 's1'){
                localStorage.setItem("countryImgUrl","in/EN/S1/");
            }else if(template === 's2'){
                localStorage.setItem("countryImgUrl","in/EN/S2/");
            }
            break;
        case "in_HI":
            if(template === 's1'){
                localStorage.setItem("countryImgUrl","in/EN/S1/");
            }else if(template === 's2'){
                localStorage.setItem("countryImgUrl","in/EN/S2/");
            }
            break;
        case "vn_VI":
            localStorage.setItem("countryImgUrl","vn/VI/S1/");
            break;
        default:
            break;
    }
}
/**
 * 获取最后一次游戏页面名称
 * 如果参数是 'recharge'  默认返回 'platform'  否则返回推广中的游戏
 */
 export function getLastTimeGameName(t) {
    if(localStorage.getItem('myLastTimeGame')){
        return localStorage.getItem('myLastTimeGame')
    }else if(t === 'recharge'){
        //return 'lobby'
        return 'parity'
    }else{
        //const commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
        //return commonInfo.promoteGame
        return 'parity'
    }   
}
/**
 * 设置最后一次游戏页面名称
 */
 export function setLastTimeGameName(name) {
    if(name === 'lobby' && isNotBlank(localStorage.getItem('myLastTimeGame'))){
        return
    }else{
        localStorage.setItem("myLastTimeGame", name);
    }
    if(isBlank(localStorage.getItem('firstVisit'))) {
      localStorage.setItem("firstVisit", name)
    }  
}
/**
 * 限制试玩次数初始化
 */
export function demoRestrictionsDate() {
    let t = new Date();
    let d = t.getDate()
    if(d !== parseInt(localStorage.getItem('demoTimes'))){
        localStorage.setItem('demoTimes', d);
        localStorage.setItem('crashDemoNumber', 0);
        localStorage.setItem('parityDemoNumber', 0);
        localStorage.setItem('mineDemoNumber', 0);
        localStorage.setItem('wheelDemoNumber', 0);
    }
}
/**
 * 限制试玩次数
 */
export function demoRestrictions(gameName) {
    let name = gameName + 'DemoNumber'
    let times = parseInt(localStorage.getItem(name)) + 1
    if(times > 10){
        return times
    }else{
        localStorage.setItem(name, times);
        return times
    }
}
/**
 * 初始化设置
 */
export function init() {
    //设置页面标题
    setAppTitle()
    //设置图片路径
    setImgUrl()
    //设置 ACCEPT_LANGUAGE
    setAcceptLanguage()
    //设置皮肤模版
    //setSkinTemplate()
}