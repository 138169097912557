<!-- <template>
  <nut-tabbar v-model:visible="active"  :bottom="true" :safeAreaInsetBottom="true" :unactiveColor="unactiveColor" :activeColor="activeColor">
     @tab-switch="tabSwitch"  
    
    <nut-tabbar-item :tab-title="$t('global.recharge')" to="./rechargeBalance"  :img="tabbarImg" :activeImg="tabbarActiveImg"></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.invite')"   to="./agentPromotion"  icon="link"  ></nut-tabbar-item>

    <nut-tabbar-item tab-title=" " :img="lobbyBtn" :activeImg="lobbyBtn" @click="lobbyBtnClick" class="show-list"></nut-tabbar-item>

    <nut-tabbar-item :tab-title="$t('global.reward')"   to="./taskReward"      icon="date"  ></nut-tabbar-item>
    <nut-tabbar-item :tab-title="$t('global.my')"       to="./myHome"          icon="my" ></nut-tabbar-item>
    
  </nut-tabbar>
</template> -->


<template>
  <nut-tabbar v-model:visible="active" :bottom="true" :safeAreaInsetBottom="true"  
    @tab-switch="tabSwitch" :unactiveColor="unactiveColor" :activeColor="activeColor">
    <nut-tabbar-item
      :tab-title="$t('global.recharge')"
      :img="linkA"
      :activeImg="linkA_active"
      to="./rechargeBalance"
    ></nut-tabbar-item>
    <nut-tabbar-item
      :tab-title="$t('global.invite')"
      :img="linkB"
      :activeImg="linkB_active"
      to="./agentPromotion"
    ></nut-tabbar-item>
    <nut-tabbar-item
      :tab-title="$t('PARITY.H1')"
      :img="linkC"
      :activeImg="linkC_active"
      @click="lobbyBtnClick"
    ></nut-tabbar-item>
    <nut-tabbar-item
      :tab-title="$t('global.reward')"
      :img="linkD"
      :activeImg="linkD_active"
      to="./taskReward"
    ></nut-tabbar-item>
    <nut-tabbar-item
      :tab-title="$t('global.my')"
      :img="linkE"
      :activeImg="linkE_active"
      to="./myHome"
    ></nut-tabbar-item>
  </nut-tabbar>
</template>


<script>
  import { reactive, toRefs, onMounted } from "vue";
  import {useRouter} from "vue-router";
  export default{
    props:[
      'activeIndex'
    ],
    data() {
      return {};
    },
    created() {},
    methods: {},
    setup(props) {
      function tabSwitch(item, index) {
        console.log(item, index);
      }
      const data = reactive({
        commonInfo: '',
        homeUrl: '',
        unactiveColor:"#898989",
        activeColor:"#193458",
        active: props.activeIndex,
        linkA:require('../assets/images/20230601/img01.png'),
        linkB:require('../assets/images/20230601/img02.png'),
        linkC:require('../assets/images/20230601/img03.png'),
        linkD:require('../assets/images/20230601/img04.png'),
        linkE:require('../assets/images/20230601/img05.png'),
        linkA_active:require('../assets/images/20230601/img01a.png'),
        linkB_active:require('../assets/images/20230601/img02a.png'),
        linkC_active:require('../assets/images/20230601/img03a.png'),
        linkD_active:require('../assets/images/20230601/img04a.png'),
        linkE_active:require('../assets/images/20230601/img05a.png'),
        
      });
      const router = useRouter();
      const methods = {
        goToGame(n){
          router.push({name: n});
        },
        lobbyBtnClick(){
          // if(data.commonInfo.games.length > 1){
          //   router.push({name: 'gameLobby'});
          // }else{
          //   router.push({path: data.homeUrl});
          // }
          router.push({name: 'parityHome'});
        },
      }
      onMounted(() => {
        data.commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"))
        data.homeUrl = '/' + data.commonInfo.games[0].name
      })
      return {
        ...toRefs(data),
        ...methods,
        tabSwitch,
      };
    },
  }
  
</script>

<style scoped>
  .nut-tabbar{
    padding: 0px;
    border-top:0;
    background:#fff;
    height: 60px !important;
    bottom: 0;
    position: fixed;
  }
  .nut-tabbar-bottom.nut-tabbar-safebottom{
    height: 60px !important;
  }

</style>
<style>
  .nut-tabbar .nut-tabbar-item_icon-box .nut-tabbar-item_icon-box_icon{width: 24px !important;height: 24px !important;}
</style>