<template>
  <div class="language-btn" @click="languageVisible = true">
    <span>{{$t('global.language')}}</span>
    <nut-icon name="setting" class="copyicon"></nut-icon>
  </div>
  <nut-actionsheet
    v-model:visible="languageVisible"
    :menu-items="menuItems"
    :choose-tag-value="currentLanguage"
    @choose="chooseItem">
  </nut-actionsheet>
</template>

<script>
import { getCountry, setLanguage, getLanguage, getLanguageText } from "@/assets/js/environment";
export default {
  name: 'SetLanguage',
  props: {
  },
  data() {
    return {
      currentLanguage: '',
      languageVisible: false,
      menuItems: [],
    };
  },
  created() {
    this.getLanguageList()
  },
  methods: {
      //显示语言选项
      languageOptions(){
        this.languageVisible = true
      },
      //设置语言
      chooseItem(itemParams){
        setLanguage(getCountry(), itemParams.name)
        this.$i18n.locale = localStorage.getItem("SET_LANG")
        this.currentLanguage = itemParams.name
        this.$router.go(0)
      },
      //获取语言选项 & 选中当前语言
      getLanguageList(){
        this.menuItems =  getLanguage()
        if(localStorage.getItem("SET_LANG")){
          this.currentLanguage = getLanguageText(localStorage.getItem("SET_LANG"))
        }else{
          this.currentLanguage = getLanguageText(this.menuItems[0].name)
        }
      },
  },
  setup() {
  }
};
</script>

<style scoped>
  .language-btn{ position: absolute; top:10px; right: 10px; padding: 3px 20px;  color: #fff;
     border-radius:20px; line-height: 20px; height: 20px; opacity: .8;}
  .language-btn .span{ display: inline-block;vertical-align: top; margin-right: 10px;}
  .language-btn i{ display: inline;width: 30px; margin-left: 14px; font-size: 14px;}
  .language-btn .copyicon{vertical-align: middle; margin-left: 15px; opacity: .7;font-size: 12px !important; margin-top: -2px;}
</style>
<style>
  .lobby-sl .language-btn { top:30px;  right: -12px !important;  background: #333; opacity: 0.7 !important;color: #fff !important;}
  .home-sl .language-btn {  top: 50px; right: -12px !important;  background: #333; opacity: 0.7 !important;color: #fff !important;}
</style>