import CryptoJS from 'crypto-js'

export function isBlank(obj) {
    return obj === undefined || obj === null || obj === '' || obj === 'null';
}

export function isNotBlank(obj) {
    return !isBlank(obj);
}

export function getValue(obj) {
    return isBlank(obj) ? null : obj;
}

export function delSpace(obj) {
    return obj.replace(/(^s*)|(s*$)/g, "");
}

export function isTrue(obj) {
    return isNotBlank(obj) && obj === true;
}

export function isFalse(obj) {
    return isNotBlank(obj) && obj === false;
}

/**
 * 获取当前根Url
 */
export function getRootUrl() {
    return window.location.protocol + "//" + window.location.host;
}

let crashKey = CryptoJS.enc.Utf8.parse('f6mkrrawg2f4hdeji1tdxt21xogy1t1w')

export function aesDecrypt(encrypted) {
    let decrypted = CryptoJS.AES.decrypt(encrypted, crashKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
    return decrypted;
}

export function getClientType() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("micromessenger") > 0) {
        return "wechat";
    } else if (ua.indexOf("android") > 0) {
        return "android";
    } else if (ua.indexOf("iphone") > 0 || ua.indexOf("ipad") > 0) {
        return "ios";
    } else {
        return "pc";
    }
}
/** 
 * 计算两个整数之间的百分比
 *  curNum：当前数据，
 *  totalNum：总数据，
 *  isHasPercentStr：是否返回%字符
**/
export function getPercent(totalNum, curNum, isHasPercentStr){
    curNum = parseFloat(curNum);
    totalNum = parseFloat(totalNum);
    if (isNaN(curNum) || isNaN(totalNum)) {
      return '-';
    }
    //是否显示 ‘%’
    return isHasPercentStr ?
      totalNum <= 0 ? '0%' : (Math.round(curNum / totalNum * 10000) / 100.00 + '%') :
      totalNum <= 0 ? 0 : (Math.round(curNum / totalNum * 10000) / 100.00);
  }
