<template>
  <h2 class="h2">
    <img :src="require('../assets/images/' + imgUrl + 'bg_p120.jpg')" />
  </h2>
  <div class="plan-main">
    <div class="p1">
      <h3><strong>Join OXXOWIN, Win together!</strong></h3>
      <img :src="require('../assets/images/' + imgUrl + 'bg_p115.jpg')" />
      <ul>
        <li>&#128142; Real payment, quick withdrawal;</li>
        <li>&#128142; Honest platform, easy to promote; </li>
        <li>&#128142; 4-level agent system, total <strong class="f18">60%</strong> rebate, the most in the industry.</li>
      </ul>
    </div>

    <div class="p5">
      <p>If you are a YouTuber or blogger, if you have fans, if you are an influencer, if you want to promote app to earn, join OXXOWIN and start making money right away!</p>
    </div>
    
    <h3 class="title">Agent Bonus</h3>
    <div class="p3">
      <img :src="require('../assets/images/' + imgUrl + 'bg_p117.jpg')" />
      <h4>For Example:</h4>
      <div class="example">
        <div class="img100">
          <img src="../assets/images/bg121.png" />
          <span>Salim</span>
        </div>
        <p class="f12">
          Our new agent Salim come from a small village and joined OXXOWIN last week. 
          He invited 111 users in the first week by sharing the invite link,
          51 of them became effective users, and he share the lifafa link in telegram channel once a day.
          Salim’s first week total income is ₹6341
        </p>
      </div>
      
      <table>
        <tr>
          <th colspan="2">Salim's first week's income</th>
          <th>Total Number</th>
          <th>Income</th>
        </tr>
        <tr>
          <td rowspan="3" style="width:75px">Instant Income</td>
          <td style="width:auto">Invitation bonus</td>
          <td style="width:auto">111</td>
          <td style="width:auto">₹455</td>
        </tr>
        <tr>
          <td>Effective user bonus</td>
          <td>51</td>
          <td>₹612</td>
        </tr>
        <tr>
          <td>Task bouns</td>
          <td style="color: #666;">/</td>
          <td>₹618</td>
        </tr>
        <tr>
          <td rowspan="2">Continuing Income</td>
          <td>Share Lifafa</td>
          <td style="color: #666;">/</td>
          <td>₹3123</td>
        </tr>
        <tr>
          <td>Commisson rebate</td>
          <td style="color: #666;">/</td>
          <td>₹1533</td>
        </tr>
        <tr>
          <td style="background: #e7b89b;">Total Income</td>
          <td style="background: #e7b89b; color: #666;">/</td>
          <td style="background: #e7b89b; color: #666;">/</td>
          <td style="background: #e7b89b;"><strong>₹6341</strong></td>
        </tr>
      </table>
    </div>

    <div class="p2">
      <dl class="dl1">
        <dt>Get now!</dt>
          <dd style="margin-left:30px"><h5>&#128176;5rs</h5><p>Invitation bonus: per user max 5rs</p></dd>
          <dd style="width:30%"><h5>&#128176;12rs</h5><p>Effective user bonus: per user,12rs</p></dd>
          <dd style="width:36%"><h5 style="font-size:18px">&#128176;10-25rs</h5><p>Task bonus: per effective user,10-25rs</p></dd>
      </dl>
      <dl class="dl2">
        <dt>Continuing Income!</dt>
          <dd>&#128176;Commission rebate:4-level agent system,total <strong class="f14">60%</strong> commission rebate</dd>
          <dd>&#128176;Daily Telegram channel lifafa: User clicks lifafa link to receive bonus(<strong class="f14">3-21rs</strong>),</dd>
          <dd>&#128176;<strong class="f14">5%</strong> rebate for registered old users, <strong class="f14">100%</strong> rebate for new registered users</dd>
      </dl>
      <dl class="dl3">
          <dd><strong style="font-size:14px">Friendly to new agents:</strong></dd>
          <dd>Users has not visited OXXOWIN for 21 days,</dd>
          <dd>You will receive full commission income. Even if you are a new agent,</dd>
          <dd>Don't worry about not being able to invite users.</dd>
      </dl>

      <div class="btnbox">
        <nut-button size="large" type="primary" class="regbtn" @click="copyClick">
          Business Email
        </nut-button>

        <nut-button size="large" type="primary" class="regbtn" @click="whatsappClick('https://wa.me/916366071367')">
          <img style="width:16px;vertical-align: middle; margin-right: 4px;margin-top: -4px;" src="../assets/images/bg124.png" /> Rupa
        </nut-button>

        <nut-button size="large" type="primary" class="regbtn" @click="whatsappClick('https://wa.me/447721564554')">
          <img style="width:16px;vertical-align: middle; margin-right: 4px;margin-top: -4px;" src="../assets/images/bg124.png" /> Ada
        </nut-button>



        
      </div>

    </div>

    <h3 class="title">How to do</h3>
    <div class="p4">
      <li class="b1"><strong>1</strong>Register OXXOWIN account</li>
      <li class="b2"><strong>2</strong>Share exclusive invitation link</li>
      <li class="b3"><strong>3</strong>Share daily lifafa link in telegram channel</li>
      <h4>&#128161; Share invitation link:</h4>
      <img :src="require('../assets/images/' + imgUrl + 'bg_p118.jpg')" />
      <nut-button size="large" type="primary" class="regbtn" @click="telegramClick">
        <img style="width:30px;vertical-align: middle; margin-right: 10px;" src="../assets/images/bg24.png" />Telegram Channel
      </nut-button>

      <h4>&#128161; How to share Bonus:</h4>
      <img :src="require('../assets/images/' + imgUrl + 'bg_p119.jpg')" />

      <h4>&#128640; Withdrawal = Fast + Easy!</h4>
      <img :src="require('../assets/images/' + imgUrl + 'bg_p123.jpg')" />
      
    </div>
    <h6>www.OXXOWIN.com</h6>
</div>

</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";
export default {
  data() {
    return {
      commonInfo: '',
      imgUrl:''
    }
  },
  methods: {
    telegramClick(){
      window.open(this.commonInfo.telegramChannelUrl,"_self");
    },
    whatsappClick(url){
      window.open(url,"_self");
    },
    copyClick(){
      let url = 'business@oxxowin.com' //this.commonInfo.serviceEmail
      let cInput = document.createElement("input")
      cInput.value = url
      document.body.appendChild(cInput)
      cInput.select()
      document.execCommand("copy")
      Toast.success('Email link copied!')
      document.body.removeChild(cInput)
    }
  },
  mounted() {
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.commonInfo = JSON.parse(localStorage.getItem('commonInfo'))
  },
  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {

    };
    return {
      router,
      state,
      ...methods,
    };
  }
};
</script>

<style scoped>
  .plan-main{ max-width: 600px; background: #1f3b62; padding: 0px 10px 20px 10px;margin:0px auto -10px auto; }
  .plan-main p, .plan-main h4{ margin: 10px 0;}
  .plan-main .f12{ font-size: 12px;}
  .plan-main .f18{ font-size: 18px;}
  .plan-main h3.title{ margin: 0; padding: 0 50px 0 15px; line-height: 26px; height: 22px; color: #1f3b62; display: inline-block; font-size: 14px; border-radius: 3px 0 0 0;
    background:#fff url(../assets/images/bg122.jpg) top right no-repeat;}
  .h2{ text-align: center; margin: 0; padding: 0; background: #1f3b62; }
  .p1, .p2, .p3, .p4{ background: #fff; padding: 10px; border-radius:0px 10px 10px 10px; margin-bottom: 10px;}
  .p1{padding: 10px 10px 20px 10px;border-radius:10px}
  .p1 img, .p3 img, .p4 img , .p5 img, .h2 img { width: 100%; margin: 0;}
  .p1 h3{ text-align: center;font-size: 16px;}
  .p1 h3 strong{ border-bottom:2px solid #1f3b62; padding-bottom: 6px;color: #1f3b62;}
  .p1 ul{ margin-top: 10px;}
  .p1 li{ margin-bottom: 5px;font-size: 14px; background: #eef4fb; border-radius:5px; padding: 5px;}
  .p2 dl{position: relative;padding: 10px 0px 5px 0; overflow: hidden; margin-bottom: 5px;}
  .p2 dl.dl1{ border: 1px solid #7b47a7; min-height: 60px; display: flex; }
  .p2 dl.dl1 dd{ width: 33%; display: inline-block; margin: 0;}
  .p2 dl.dl1 dd h5{ height: 40px; line-height: 50px; margin: 0; font-size: 20px; text-align: left; padding-left: 5px; color: #c57313;}
  .p2 dl.dl1 dd p{ padding: 0 5px; margin: 0;}
  .p2 dl.dl2{ border: 1px solid #8ba747; min-height: 90px;}
  .p2 dl.dl3{  min-height: 90px;}
  .p2 dl dt{ position: absolute; top:0; left: 0; bottom: 0; writing-mode:vertical-rl; width: 20px; height: 100%; line-height: 15px; 
      vertical-align: middle; text-align: center; background:#7b47a7; color: #fff; padding-right: 5px;}
  .p2 dl.dl2 dt{background:#8ba747;}
  .p2 dl.dl3 dt{background:#c57313;}
  .p2 dl dd{ margin-left: 35px; line-height: 18px; margin-bottom: 10px; font-size: 12px;}
  .p2 dl.dl3{padding: 10px;   border: 1px solid #ccc;}
  .p2 dl.dl3 dd{ margin-left: 0px;margin-bottom: 0px;}
  .p2 {border-radius:10px}
  .p3 table{ border: 1px solid #fff; font-size: 12px;border-radius:5px; }
  .p3 table tr{border: 1px solid #fff;}
  .p3 table th{border: 1px solid #fff; font-weight: 300; background: #2c5082; line-height: 14px; padding: 5px 0; color: #fff; border-radius:5px;}
  .p3 table td{border: 1px solid #fff;line-height: 14px;text-align: center;background: #c3d9f7; border-radius:5px; padding: 5px;}
  .p3 .example{ display: flex; margin-bottom: 10px;}
  .p3 .img100{ width: 80px; text-align: center; color: #666;}
  .p3 .img100 img{ width: 80px; border-radius:10px; }
  .p3 p{ margin: 0 0 0 10px;}
  .p4 {background:#fff;padding-bottom: 20px;}
  .p4 li{ background: #8c51ad; color:#fff; line-height: 14px; padding: 10px 10px 10px 5px; margin-bottom: 5px; border-radius:5px;}
  .p4 li.b1{ background: #8c51ad; }
  .p4 li.b2{ background: #774c90; }
  .p4 li.b3{ background: #614372; }
  .p4 li strong{ background: #fff; color: #614372;border-radius:10px; display: inline-block; width: 20px; height: 20px; line-height: 20px; text-align: center; font-size: 16px; margin-right: 8px;}
  .p4 h3{ text-align: center; font-size: 20px; color: #b64e09; margin: 10px 0;}
  .p4 p{ padding: 10px; background: #fbfbe2; border-radius:10px; border: 1px solid #eaeac1; color: #ac6e1d;}
  .regbtn{  background:#1278b5 !important; color:#fff; font-size: 20px; 
  border-radius:10px !important; border-bottom: #2d6586 5px solid;}
  .btnbox { display:flex;}
  .btnbox .regbtn{ width: 32%; margin: 10px 2px; font-size: 14px; font-size:12px; }
  .btnbox .regbtn:nth-child(2) {  background:#3ec250 !important;border-bottom: #1da333 5px solid;}
  .btnbox .regbtn:last-child{  background:#3ec250 !important;border-bottom: #1da333 5px solid;}

  .p5 p{ color: #fff; opacity: .5; line-height: 14px; text-align: center; padding: 0 10px; font-size: 12px;}
  .plan-main h6{ text-align: center; color: #fff; margin: 10px 0 0px 0; opacity: .3; font-size: 20px; font-weight: 300;}
</style>
