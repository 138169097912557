<template>
  <div>
    <nut-navbar
    :left-show="false"
        @on-click-icon="iconClick"
        titIcon="horizontal"
        :title="$t('withdrawal.h1')"
        fixed=true
        class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

    <div class="recharge-bd balanceVue">
      <div class="top-text">
        <strong>{{$t('withdrawal.availableBalance')}}</strong>
        <br/>
        <span class="f-24">{{ availableAmount }}</span>
      </div>
      <div class="top-text-b">
        {{ amountType === 'cash' ? $t('withdrawal.agentAmount') : $t('withdrawal.gameAccount') }}
      </div>

      <div class="recharge-ls">
        <div
            class="recharge-btn"
            @click="rechargeBtnClick(item.withdrawAmount, index, item.enable)"
            v-for="(item, index) in plans"
            :key="index"
            :class="{ active: isActive === index, btnDisabled: !item.enable }"
        >
          <strong>{{ $filters.currencySymbol(item.withdrawAmount) }}</strong>
        </div>
      </div>

      <nut-form class="label-w150">
        <nut-form-item :label="$t('withdrawal.withdrawAmount')">
          <input
              class="nut-input-text"
              type="text"
              v-model="withdrawAmount"
              v-on:input="inputChange"
              :readonly="!allowCustomAmount"
          />
        </nut-form-item>
        <div v-show="inpShow">
          <nut-form-item :label="$t('withdrawal.handlingFee')">
            <input
                class="nut-input-text"
                type="text"
                v-model="feeAmount"
                readonly="true"
                style="width:40%"
            />
          </nut-form-item>

          <nut-form-item :label="$t('withdrawal.receiptAmount')">
            <input
                class="nut-input-text"
                type="text"
                v-model="receiptAmount"
                readonly="true"
            />
          </nut-form-item>

          <nut-form-item :label="$t('withdrawal.bankCard')">
            <nut-radiogroup direction="horizontal" v-model="radio">
              <nut-radio
                  :label="item.id"
                  :id= "index === 0 ? 'radioaActive' : ''"
                  v-for="(item, index) in bankList"
                  :key="index"
                  @click="bankIdRadio(item.id)"
                  :checked="{ checked: index === 1 }"
              >
                <span v-if="country === 'ph'">{{ item.bankInfo.gCashMobile.slice(0, 3) }} ***{{ item.bankInfo.gCashMobile.slice(-4) }}</span>
                <span v-else-if="country === 'in'">{{ item.bankInfo.bankName }} ( ***{{ item.bankInfo.bankAccount.slice(-4) }} )</span>
                <span v-else-if="country === 'ng' || country === 'mx' || country === 'vn'">( ***{{ item.bankInfo.bankAccount.slice(-4) }} )</span>
                <span v-else-if="country === 'br'">
                  <span v-if="item.bankInfo.email">{{ item.bankInfo.email }}</span>
                  <span v-else-if="item.bankInfo.phone">{{ item.bankInfo.phone }}</span>
                  <span v-else-if="item.bankInfo.cpf">{{ item.bankInfo.cpf.slice(0, 6) }}***</span>
                  <span v-else-if="item.bankInfo.cnpj">{{ item.bankInfo.cnpj.slice(0, 6)  }}***</span>
                </span>
              </nut-radio>
            </nut-radiogroup>
          </nut-form-item>
        </div>
      </nut-form>

      <div class="login-btn m10">
        <nut-button block type="info" @click="withdrawalSubmit"
                    :disabled="!inpShow || btnDisabled"
        >{{$t('withdrawal.withdrawalBtn')}}
        </nut-button
        >
      </div>

      <nut-noticebar 
          direction='vertical' 
          :list="othersInfo" 
          :speed='10' 
          :standTime='2000' 
          :closeMode="true" 
          :color="`#ff7e00`">
      </nut-noticebar>

      <div class="bt-tx">
        <p><strong>{{$t('withdrawal.description')}}</strong></p>
        <p>{{$t('withdrawal.tx12')}}<strong>{{$t('bankCard.email')}}: {{ eMail }} </strong> {{$t('withdrawal.tx13')}}</p>  
        <p>{{$t('withdrawal.tx14')}}<strong style="color:#1e246d" class="text-color" @click="vipPopupClick">{{$t('withdrawal.tx8')}}</strong>.</p>
        <p>{{$t('withdrawal.tx15')}}</p>
      </div>
    </div>

    <nut-popup pop-class="pop-vip" v-model:visible="vipPopup" :z-index="100">
      <VipRules :myLevel="myLv" :popTitle="popTitle" upgradeVip="show"></VipRules>
      <div class="login-btn m10">
        <nut-button block type="info" @click="toRecharge" >{{$t('recharge.levelUpVip')}}</nut-button>
      </div>
    </nut-popup>

    <nut-popup pop-class="pop-withdrawal bg85" v-model:visible="rechargeSuccessAndNoWithdraw" :z-index="100">
      <div v-show="country === 'br'">
        <h6>Descrição da retirada</h6>
        <table>
          <tr>
            <th style="width:65px">Montante</th>
            <th style="width:85px">Taxa de manuseio</th>
            <th style="width:95px">Velocidade</th>
          </tr>
          <tr>
            <td>100</td>
            <td>-20 por100</td>
            <td>Rápido</td>
          </tr>
          <tr>
            <td>200</td>
            <td>-15 por100</td>
            <td>Super Rápido</td>
          </tr>
          <tr class="bgc-r">
            <td>300 🔥</td>
            <td>-3.5 por100</td>
            <td>Super Rápido</td>
          </tr>
          <tr>
            <td>500</td>
            <td>-3.5 por100</td>
            <td>Super Rápido</td>
          </tr>
        </table>
      </div>
      <div class="hd">{{ $t('withdrawal.tx10') }}</div>
      <div class="bd">
        {{ $t('withdrawal.tx11') }}
      </div>
      <div class="btn" v-if="country === 'br'">
        <nut-button type="primary" @click="toPlay">{{ $t('global.goWin') }}</nut-button>
        <nut-button type="primary" @click="rechargeSuccessAndNoWithdraw = false">{{ $t('game.gotitBtn') }}</nut-button>
        <span class="link" @click="rechargeSuccessAndNoWithdraw = false">×</span>
      </div>
      <div class="btn" v-else>
        <nut-button type="primary" @click="toPlay">{{ $t('withdrawal.btntx') }}</nut-button>
        <span class="link" @click="rechargeSuccessAndNoWithdraw = false">×</span>
      </div>
      
    </nut-popup>

    <nut-popup pop-class="pop-bankList" v-model:visible="showBankList" :z-index="100" style="border-radius:5px;" >
      <div class="pop-bankLis">
        <div class="hd">{{ $t('withdrawal.popBankList') }}</div>
        <div class="bd">
          <ul class="ls-ul">
            <li v-for="(item, index) in availableBankList" :key="index">{{ item }}</li>
          </ul>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="showBankList = false">{{ $t('global.cancelText') }}</nut-button>
        </div>
      </div>
    </nut-popup>

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>
 
    <div class="tg-btn">
      <img src="../assets/images/20230601/img41.png"  @click="telegramClick" />
    </div>

  </div>
</template>

<script>
import {reactive, toRefs, onMounted, getCurrentInstance} from "vue";
import {useRouter, useRoute} from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import {Dialog, Toast} from "@nutui/nutui";
import VipRules from "../components/VipRules2.vue";
import { isBlank } from '@/assets/js/commonUtil';
import { getCountry, getLastTimeGameName } from "@/assets/js/environment";

export default {
  components: {
    VipRules: VipRules,
    tabbar: TabbarMain,
  },
  data(){
    return{
      country:'',
      telegramChannelUrl:'',
      eMail:'',
      i18n:{
        loading: this.$t('global.loading'),
        okText: this.$t('global.okText'),
        errInfoBankCard: this.$t('withdrawal.errInfoBankCard'),
        errInfoRecharge: this.$t('withdrawal.errInfoRecharge'),
        errInfopPlayMore: this.$t('withdrawal.errInfopPlayMore'),
        submittedSuccess: this.$t('global.submittedSuccess'),
        popTitle_1: this.$t('recharge.popTitle_1'),
        popTitle_2: this.$t('recharge.popTitle_2'),
        successfully: this.$t('global.successfully'),
        rechargeBtnText: this.$t('global.withdrawalBtnText')
      }
    }
  },
  created() {
    this.country = getCountry()
    if(window.localStorage.getItem('commonInfo')){
      this.commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
      this.eMail = this.commonInfo.serviceEmail
      this.telegramChannelUrl = this.commonInfo.telegramChannelUrl
    }else{
      axios.get("/api/system/common", {}).then((response)=> {
        this.eMail = response.data.data.serviceEmail
        this.telegramChannelUrl = response.data.data.telegramChannelUrl
      })
    }
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      eMail:'',
      vipPopup:false,
      popTitle:'',
      allowCustomAmount:true,
      rechargeSuccessAndNoWithdraw:false,
      tabbarActiveIndex: 2,
      amountType: '',
      radio: 1,
      isActive: "",
      withdrawAmount: 0,
      feeAmount: 0,
      receiptAmount: 0,
      availableAmount: 0,
      availableAmount2: 0,
      plans: [],
      myLv: "",
      bankList: [],
      showBankList:false,
      availableBankList: [],
      bankId: "",
      inpShow: false,
      btnDisabled: false,
      othersInfo: [],
      othersInfoList: [],
    });

    const router = useRouter();
    const route = useRoute();
    const methods = {
      vipPopupClick(){
        state.vipPopup = true; 
        state.popTitle = _data.data.i18n.popTitle_1
      },
      telegramClick(){
        window.open(_data.data.telegramChannelUrl, "_self");
      },
      toPlay() {
        router.push({
          path: '/'+ getLastTimeGameName(),
        });
      },

      toRecharge() {
        router.push({
          name: "rechargeBalance",
        });
      },
      backClick() {
        router.go(-1);
      },
      iconClick() {
        router.push({
          path: "withdrawalHistoryList",
          query: {
            amountType: state.amountType
          }
        })
      },
      rechargeBtnClick(t, i, d) {
        if(!d){return}
        if (i >= 0) {
          state.isActive = i;
        } else {
          state.isActive = ''
        }
        methods.textToast(_data.data.i18n.loading);
        state.withdrawAmount = t;
        axios({
          url: "/api/fund/withdraw/prepare",
          method: "get",
          params: {withdrawAmount: state.withdrawAmount}
        })
        .then((res) => {
          state.feeAmount = res.data.data.feeAmount;
          state.receiptAmount = res.data.data.receiptAmount;
          Toast.hide("loading");
          state.inpShow = true;
          setTimeout(() => {
            var e = document.createEvent("MouseEvents");
            e.initEvent("click", true, true);
            document.getElementById("radioaActive").dispatchEvent(e);
          }, 2000);
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      inputChange() {
        if(state.withdrawAmount <= 0 || state.withdrawAmount === null || state.withdrawAmount === ''){
          return
        }
        this.rechargeBtnClick(state.withdrawAmount, -1)
      },
      bankIdRadio(id) {
        state.bankId = id;
      },
      getBankList() {
        axios.get("/api/user/bank/list", {}).then(function (res) {
          if (res.data.data.length === 0) {
            Dialog({
              content: _data.data.i18n.errInfoBankCard,
              okText: _data.data.i18n.okText,
              noCancelBtn: true,
              onOk: () => {
                router.push({
                  path: "/addBankCard",
                  query:{target:'withdrawalBalance'}
                });
              }
            });
            state.rechargeSuccessAndNoWithdraw = false
            return;
          }
          state.bankList = res.data.data
          state.bankId = res.data.data[0].id
        });
      },
      withdrawalSubmit() {
        state.btnDisabled = true;
        axios
            .post("/api/fund/withdraw/apply", {
              fundAccountType: state.amountType,
              applyAmount: state.withdrawAmount,
              userBankId: state.bankId,
            })
            .then(function (res) {
              let data = res.data;
              if (data.code === 2000) {
                if (data.bizCode === 'FUND_WITHDRAW_NEED_LEVEL_UP_VIP') {
                  state.vipPopup = true
                  state.popTitle= _data.data.i18n.popTitle_2
                } else if (data.bizCode === 'FUND_WITHDRAW_BANK_NOT_SUPPORTED') {
                  state.availableBankList = data.data
                  state.showBankList = true
                }else if (data.bizCode === 'FUND_WITHDRAW_NEED_BET') {
                  //需要下注更多
                  Dialog({
                    content: _data.data.i18n.errInfopPlayMore,
                    closeOnPopstate: true,
                    cancelText: 'OK',
                    onOk: () => {
                      router.push({ path: '/'+ getLastTimeGameName() });
                    }
                  });
                }
              }

              if (data.code === 0) {
                Toast.success(_data.data.i18n.submittedSuccess);
                setTimeout(() => {
                  router.push({
                    path: 'withdrawalHistoryList',
                    query: {
                      amountType: state.amountType
                    }
                  })
                }, 1000);
              }
              state.btnDisabled = false;
            })
            .catch(function (error) {
              state.btnDisabled = false;
              console.log(error);
            });
      },
    };

    onMounted(() => {
      let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
      state.eMail = isBlank(commonInfo) ? ' ': commonInfo.serviceEmail

      methods.textToast(_data.data.i18n.loading);
      state.amountType = route.query.amountType;
      axios({
        url: "/api/fund/withdraw/page",
        method: "get",
        params: {fundAccountType: state.amountType}
      })
          .then((res) => {
            state.allowCustomAmount = res.data.data.allowCustomAmount
            state.rechargeSuccessAndNoWithdraw = res.data.data.rechargeSuccessAndNoWithdraw
            state.plans = res.data.data.plans;
            state.availableAmount = res.data.data.fundAccount.availableAmount;
            state.availableAmount2 = state.availableAmount <= 100 ? 0 : state.availableAmount - 100
            state.myLv = res.data.data.vip.level;
            for(let v of res.data.data.othersInfo){	
              let text = v.mobile + ' ' + _data.data.i18n.successfully + ' ' + _data.data.i18n.rechargeBtnText+ ': ' + v.amount
              state.othersInfoList.push(text.toString())
            }
            state.othersInfo = state.othersInfoList
            methods.getBankList();
            Toast.hide("loading");
          })
          .catch(function (error) {
            console.log(error);
            Toast.hide("loading");
          })
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>




.f-24 {
  color: #fd0;
  font-size: 24px;
  font-weight: 500;
  margin-left: 3px;
}

.recharge-bd {
  background: url(../assets/images/bg05.png) 0 0px no-repeat;
  background-size: 100% 350px;
  min-height: 500px;
  margin-top: 0px;
  padding-top: 60px;
  padding-bottom: 25px;
}

.top-text {
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #a5bad1;
  margin: 0 6% 60px 6%;
  padding: 10px 0;
}

.top-text strong {
  font-weight: 300;
  text-align: center;
  padding-top: 5px;
  opacity: 0.5;
}

.top-text .nut-countup {
  display: flex;
  align-items: center;
  width: auto !important;
  color: #ffdd00;
  font-size: 24px;
}

.top-text-b {
  margin: -50px 6% 10px 6%;
  text-align: center;
  height: 12px;
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  line-height: 12px;
  vertical-align: middle;
}

.top-text-b i {
  vertical-align: middle;
  margin-bottom: 3px;
}

.recharge-ls {
  padding: 5px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.recharge-btn {
  width: 29%;
  margin: 5px;
  background: #fff;
  align-items: flex-start;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 1px 5px #000;
  position: relative;
  border: 2px solid #040a57;
}
.recharge-btn strong {
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #9f6c1a;
}


.recharge-btn.btnDisabled{
  background:#eee !important;
  border: 1px solid #e4e4e4 !important;
}
.recharge-btn.btnDisabled strong{
  color: #ccc !important;
}
.recharge-btn.active {
  background: linear-gradient(315deg, #3a013b 0%, #d0ae76 100%);
  font-weight: 600; border: 2px solid #eccc80;
}

.recharge-btn.active strong {
  color: #feff88;
}

</style>

<style>
.recharge-bd .nut-cell-group{ margin: 10px 5px !important;}
.nut-form-item__body__slots .nut-input-text{ line-height: 30px !important;}
.label-w150 .nut-form-item__label {width: 120px !important;}
.label-w150 .nut-cell-group__warp{box-shadow: 0 0 5px #040a57;}

.horseLamp_list li{ font-size: 14px !important; color: #ff7e00 !important; padding-left:30px !important;
    background: url(../assets/images/bg194.png) 0px center no-repeat !important; background-size:20px !important;}
    .nut-noticebar-vertical{ margin: 0 15px !important;}
  .pop-bankLis{
    padding: 20px 20px 10px 20px;
    width: 260px !important;
    border-radius: 15px;
  }
  .pop-bankLis .hd{margin-bottom: 5px; line-height: 16px; margin-bottom:10px; color: rgb(214, 86, 0);}
  .pop-bankLis .bd{
    min-height: 200px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    background: #eee;
  }
  .pop-bankLis .ls-ul li{ line-height: 14px; padding: 5px 0px; }
  .pop-bankLis .btn{ text-align: center;}
</style>
