<template>
  <div>
    <nut-navbar
    :left-show="false"
        :title="$t('transfer.h1')"
        fixed=true
        class="mb0 nav-icon-right nut-navbar--fixed"
      >
      <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>

    <div class="recharge-bd">
      
      <div class="transfer-box">
        <div class="l">
          <div class="w150">
            <span>{{$t('withdrawal.agentAmount')}}</span>
            <h5>{{fundAccount.cashAccount}}</h5>
          </div>
        </div>
        <div class="c">

        </div>
        <div class="r">
          <div class="w150">
            <span>{{$t('withdrawal.gameAccount')}}</span>
            <h5>{{fundAccount.coinAccount}}</h5>
          </div>
        </div>
      </div>

      <nut-form class="label-w150">
        <nut-form-item :label="$t('transfer.transferAmount')">
          <input
              class="nut-input-text"
              type="text"
              v-model="transferAmount"
              v-on:input="inputChange"
              :readonly="!allowCustomAmount"
          />
        </nut-form-item>
        <span class="allbtn" @click="allClick">ALL</span>
      </nut-form>

      <div class="login-btn m10">
        <nut-button block type="info" @click="transferSubmit" :disabled="btnDisabled">{{$t('transfer.btntx')}}</nut-button>
      </div>
      <div class="bt-tx">
        <p><strong>{{$t('withdrawal.description')}}</strong></p>
        <p>{{$t('transfer.tx1')}}</p>
        <p>{{$t('transfer.tx2')}}</p>
      </div>    
    </div>

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

  </div>
</template>

<script>
import {reactive, toRefs, onMounted, getCurrentInstance} from "vue";
import {useRouter} from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import {Toast} from "@nutui/nutui";
import { isBlank } from '@/assets/js/commonUtil';
import { getCountry } from "@/assets/js/environment";

export default {
  components: {
    tabbar: TabbarMain,
  },
  data(){
    return{
      country:'',
      i18n:{
        loading: this.$t('global.loading'),
        failEnter: this.$t('recharge.failEnter'),
        submittedSuccess: this.$t('transfer.success'),
      }
    }
  },
  created() {
    this.country = getCountry()
    this.textToast(this.$t('global.loading'))
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      eMail:'',
      allowCustomAmount: true,
      tabbarActiveIndex: 2,
      btnDisabled: true,
      transferAmount: 0,
      fundAccount:{
        cashAccount:'',
        coinAccount:''
      }
    });
    const router = useRouter();
    
    const methods = {
      backClick() {
        router.go(-1);
      },
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      inputChange() {
        if(state.transferAmount <= 0 || state.transferAmount === null || state.transferAmount === ''){
          return
        }
        state.btnDisabled = false;
      },
      allClick(){
        state.transferAmount = state.fundAccount.cashAccount
        this.inputChange()
      },
      transferSubmit() {
        state.btnDisabled = true;
        if(state.transferAmount > state.fundAccount.cashAccount){
          Toast.fail(_data.data.i18n.failEnter)
          return
        }
        axios
          .post("/api/fund/transfer/cash-to-coin", {
            amount: state.transferAmount
          })
          .then(function (res) {
            if (res.data.code === 0) {
              Toast.success(_data.data.i18n.submittedSuccess);
              methods.getFundAccount()
            }
            state.btnDisabled = false;
          })
          .catch(function (error) {
            state.btnDisabled = false;
            console.log(error);
          });
      },
      getFundAccount(){
        axios
          .get("/api/user/home", {})
          .then(function (response) {
            state.fundAccount.cashAccount = response.data.data.cashAccount.availableAmount;
            state.fundAccount.coinAccount = response.data.data.coinAccount.availableAmount;
            Toast.hide("loading");
          })
          .catch(function (error) {
            console.log(error);
            Toast.hide("loading");
          })
      }
    };
    onMounted(() => {
      
      let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
      state.eMail = isBlank(commonInfo) ? ' ': commonInfo.serviceEmail
      methods.getFundAccount()
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>

<style scoped>

.f-24 {
  color: #fd0;
  font-size: 24px;
  font-weight: 500;
  margin-left: 3px;
}

.recharge-bd {
  background: url(../assets/images/bg05.png) 0 -80px no-repeat;
  background-size: 100% 300px;
  min-height: 500px;
  margin-top: 0px;
  padding-top: 60px;
}

.top-text {
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #520150;
  margin: -5px 6% 50px 6%;
  padding: 10px 0;
}

.top-text strong {
  font-weight: 300;
  text-align: center;
  padding-top: 5px;
  opacity: 0.5;
}

.top-text .nut-countup {
  display: flex;
  align-items: center;
  width: auto !important;
  color: #ffdd00;
  font-size: 24px;
}

.top-text-b {
  margin: -50px 6% 10px 6%;
  text-align: center;
  height: 12px;
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  line-height: 12px;
  vertical-align: middle;
}

.top-text-b i {
  vertical-align: middle;
  margin-bottom: 3px;
}

.recharge-ls {
  padding: 5px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.recharge-btn {
  width: 29%;
  margin: 5px;
  background: #fff;
  align-items: flex-start;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 1px 5px #eee;
  position: relative;
  border: 1px solid #f0cda0;
}

.recharge-btn strong {
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #ff7800;
}

.recharge-btn.btnDisabled{
  background:#eee !important;
  border: 1px solid #e4e4e4 !important;
}
.recharge-btn.btnDisabled strong{
  color: #ccc !important;
}

.recharge-btn.active {
  background: linear-gradient(315deg, #3a013b 0%, #a7039a 100%);
  border: 1px solid #4c014b;
}

.recharge-btn.active strong {
  color: #feff88;
}

.transfer-box{ height: 125px; display: flex;}

.transfer-box .l{ width: 40%; margin-left: 5%;}
.transfer-box .r{ width: 40%; margin-right: 5%;}
.transfer-box .c{width: 10%;background: url(../assets/images/bg147.png) center 25px no-repeat; background-size:30px;}


.transfer-box .w150{background: url(../assets/images/bg84.png); width: 85%; height: 70px; margin: 0 auto; border-radius:10px; text-align: center; padding-top: 10px;}
.transfer-box .w150 span{ color: #fff;font-size: 12px; line-height: 30px;}
.transfer-box .w150 h5{ margin: 0; padding: 0; color: #ffd908; font-size: 20px;}
.allbtn{ position: absolute; right: 20px; top:15px;  color: #32044f; border-radius:14px;}
.label-w150 {position: relative;}
</style>

<style>
.top-text .nut-countup .run-number {
  font-weight: 500 !important;
}

.label-w150 .nut-form-item__label {
  width: 120px !important; 
}
.horseLamp_list li{ font-size: 14px !important; color: #6e4817 !important; padding-left:30px !important;
  background: url(../assets/images/bg194.png) 0px center no-repeat !important; background-size:20px !important;}
  .nut-noticebar-vertical{ margin: 0 15px !important;}
.label-w150 .nut-cell{ background: none !important;}
.label-w150 .nut-cell-group__warp{ margin: 10px;}
.label-w150 .nut-form-item__body__slots .nut-input-text{ font-size: 18px !important;}
</style>
