<template>
  <div class="">
    <nut-navbar
      :left-show="false"
      :title="$t('envelopes.h1')"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed"
    >
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
    </nut-navbar>

    <div class="pt50">
      <div class="csls">
        <div class="csls-item">
          <h4>{{ dataList.title }}</h4>
          <p>{{ dataList.sendTime }}</p>
          <div class="bd"><pre style="white-space:pre-wrap;word-wrap:break-word"> {{ dataList.content }}</pre></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
export default {
  data() {
    return {
      id:'',
      dataList:{},
      content:''
    }
  },
  methods: {
    gettingData() {
      var data = this.$route.query.details
      this.id = data
      axios({
        url: "/api/system/letter/detail",
        method: "get",
        params: { id: this.id },
      }).then((res) => {
        this.dataList = res.data.data;
        this.content = this.dataList.content.replace(/\r/ig,'').replace(/\n/ig,'<br/>')
      });
    }
  },
  watch: {
    //'$route': 'gettingData'
  },
  created() {
    this.gettingData()
  },
  setup() {
    const state = reactive({});
    const router = useRouter();
    const methods = {
      backClick() {
        router.push({
          name: "envelopesCenter",
        });
      },
    };
    return {
      ...toRefs(state),
      ...methods,
    };
  }
};
</script>

<style scoped>




</style>