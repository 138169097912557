import {createApp} from 'vue';
import router from './router/index.js';
import App from './App.vue';
import {create, all} from 'mathjs'
import NutUI from '@nutui/nutui';
import {Locale} from '@nutui/nutui';
import enUS from '@nutui/nutui/dist/packages/locale/lang/en-US';
import VueSocialSharing from 'vue-social-sharing';
import VueI18n from './language';
import "es6-promise/auto";
import './assets/css/main.css';
import '../http';
import "@nutui/nutui/dist/style.css";
import {withCurrencySymbol} from './filters'
import CryptoJS from 'crypto-js'
import { init } from "@/assets/js/environment";

//mathjs
export const math = create(all, {})

const app = createApp(App)
app.config.globalProperties.$filters = withCurrencySymbol()
app.config.globalProperties.$filters = {
    currencySymbol(v) {
        //console.log(v)
        return withCurrencySymbol(v)
    }
}
//app.config.globalProperties.$http = http
app.config.globalProperties.$math = math;
app.config.globalProperties.$encrypt = CryptoJS;
app.use(NutUI).use(router).use(VueSocialSharing).use(VueI18n).mount('#app');
Locale.use('en-US', enUS);

//初始化设置
init()

