<template>
  <div>
    <nut-navbar
      :left-show="false"
      @on-click-icon="iconClick"
      :title="$t('invite.h1')"
      titIcon="horizontal"
      fixed=true
      class="mb0 nav-icon-right nut-navbar--fixed nut-navbar-b0"
    >
    </nut-navbar>

    <div class="agent-main">
      <div class="agent-top">
        <div class="agent-info">
          <div class="agent-info-l">
            <div>
              <span>{{$t('invite.agentAmount')}}</span>
              <strong>
                {{ $filters.currencySymbol(inviteInfo.availableAmount) }}
              </strong>
              <nut-button plain type="info" size="mini" @click="withdrawClick('cash')">{{$t('invite.withdraw')}}</nut-button>
              <nut-button v-show="!commonInfo.pureMode" plain type="info" size="mini" @click="transferClick">{{$t('withdrawal.transfer')}}</nut-button>
            </div>
            <div class="flbd">
              <div class="fl">
                <span @click="infoToast($t('invite.infoToast'))">
                  {{$t('invite.totalPeople')}} 
                  <nut-icon name="ask" class="copyicon" style="vertical-align: bottom; font-size: 14px;height: 16px;"></nut-icon>
                </span>
                <strong>{{ inviteInfo.inviteCount }}</strong>
              </div>
              <div class="fl">
                <span>{{$t('invite.effectiveNum')}}</span>
                <strong>{{effectiveCount}}</strong>
              </div>
            </div>
          </div>
          <div class="agent-lv">
            <strong>{{ inviteInfo.vipLevel }}</strong>
          </div>
        </div>
        <div class="agent-vip" @click="growthPlan">
          <strong>100,000,000</strong>
          <span>Crore agent growth plan ></span>
        </div>
      </div>

      <div class="agent-lk">
        <div class="left">
          <img @click="showRound = true" :src="require('../assets/images/20230601/img19.png')" />
        </div>
        <div class="right">
          <img @click="toTelegram" :src="require('../assets/images/20230601/img20.png')" />
        </div>
      </div>
      
      

      <!-- <img v-show="country === 'in'" @click="toAgentSupportPlan" class="img100 ap-bn" :src="require('../assets/images/' + imgUrl + 'bg67b.png')" /> -->

      <div class="agent-table" v-show="domShow">
        
        <!-- h4>{{$t('invite.contribution')}}<span class="r-tx" v-show="asList.length > 4">{{$t('invite.more')}} ></span></h4> -->
        <!-- <ul class="tab-hd">
          <li :class="tabActive ? 'active' : ' '" @click="tabToggle('active')"><span>{{$t('invite.rules')}}</span></li>
          <li :class="!tabActive ? 'active' : ' '" @click="tabToggle()"><span>{{$t('invite.bonus')}}</span></li>
        </ul> -->

        
        <!--
        <div class="tg-btn" v-else-if="country === 'br' && template === 's1'" style="float: right; margin-top: -34px;z-index: 200;position: inherit;">
          <img src="../assets/images/bg313.png" style="height: 34px !important;" @click="toTelegram" />
        </div> -->

        <div class="tab-bd"> 

          <h2 @click="shareBonusTask">
            <span>{{$t('taskReward.shareLifafaTitle')}}</span>
            <div><strong>{{$t('taskReward.completeTask')}}</strong></div>
          </h2>
          
          <div class="tab-bd-img">
            
            <!--
            <dl v-for="(item, index) in asList" :key="index">
              <dt>{{$t('invite.level')}}:{{item.affinity}}</dt>
              <dd><span>{{$t('invite.label1')}}:</span><strong>{{item.subBetCommissionRatio}}%</strong></dd>
            </dl>
            -->
            <dl>
              <dt>{{$t('invite.Grade')}}:1</dt>
              <dd><span>{{$t('invite.Bonuss')}}:</span><strong>30%</strong></dd>
            </dl>
            <dl>
              <dt>{{$t('invite.Grade')}}:2</dt>
              <dd><span>{{$t('invite.Bonuss')}}:</span><strong>20%</strong></dd>
            </dl>
            <dl>
              <dt>{{$t('invite.Grade')}}:3</dt>
              <dd><span>{{$t('invite.Bonuss')}}:</span><strong>7%</strong></dd>
            </dl>
            <dl>
              <dt>{{$t('invite.Grade')}}:4</dt>
              <dd><span>{{$t('invite.Bonuss')}}:</span><strong>3%</strong></dd>
            </dl>
          </div>


          <table class="table-th">
            <tr>
              <th class="title">{{$t('invite.bonus')}}</th>
              <td></td>
            </tr>
            <tr>
              <th >{{$t('invite.inviteCount')}}</th>
              <td></td>
            </tr>
            <tr>
              <th >{{$t('invite.inviteCommissionAmount')}}</th>
              <td></td>
            </tr>
            <tr>
              <th>{{$t('invite.effectiveCount')}}</th>
              <td></td>
            </tr>
            <tr>
              <th>{{$t('invite.effectiveBonus')}}</th>
              <td></td>
            </tr>
            <tr>
              <th>{{$t('invite.subBetCommissionAmount')}}</th>
              <td></td>
            </tr>
            <tr>
              <th>{{$t('invite.totalCommissionAmount')}}</th>
              <td></td>
            </tr>
          </table>
          <div class="table-td">
            <table>
              <tr>
                <th v-for="(item, index) in asList" :key="index">{{$t('invite.affinity')}}{{item.affinity}}</th>
              </tr>
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ index > 0 ? '--' : item.inviteCount }}</td>
              </tr>
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ index > 0 ? '--' : $filters.currencySymbol(item.inviteCommissionAmount) }}</td>
              </tr>
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ index > 0 ? '--' : item.effectiveCount }}</td>
              </tr>
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ index > 0 ? '--' : $filters.currencySymbol(item.effectiveInviteCommissionAmount) }}</td>
              </tr>

              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ $filters.currencySymbol(item.subBetCommissionAmount) }}</td>
              </tr> 
              <tr>
                <td v-for="(item, index) in asList" :key="index">{{ $filters.currencySymbol(item.totalCommissionAmount) }}</td>
              </tr>
            </table>
          </div>



          <!-- <div class="tab-bd-p">
            <h5>{{$t('invite.rulesTitle2')}}</h5>
            <p>{{$t('invite.rulesText21')}}</p>
            <p>{{$t('invite.rulesText22')}}</p>
          </div>

          <div class="tab-bd-p">
            <h5>{{$t('invite.rulesTitle3')}}</h5>
            <p>{{$t('invite.rulesText31')}}</p>
            <p>{{$t('invite.rulesText32')}}</p>
          </div>

          <div class="tab-bd-p">
            <h5>{{$t('invite.rulesTitle1')}}</h5>
            <p>{{$t('invite.rulesText11')}}</p>
            <p>{{$t('invite.rulesText12')}}</p>
          </div>

          <div class="tab-bd-p">
            <h5>{{$t('invite.rulesTitle4')}}</h5>
            <p>{{$t('invite.rulesText41')}}</p>
          </div> -->

        </div>

      </div>

      

      <div class="youTitle">{{$t('invite.youTitle')}}</div>
      <div class="shares-btn" v-show="domShow">
        <p>{{$t('invite.info1')}}{{ registerReward }}{{$t('invite.info2')}}</p>
      </div>

    <!--**************************************** 近期邀请奖励弹框 ****************************************-->
    <nut-popup pop-class="pop-reward" v-model:visible="rewardPopup">
      <div class="hd"></div>
      <div class="bd">
        <h4>{{ $t('invite.NewAgencyIncome') }}</h4>
        <h5>+{{ $filters.currencySymbol(inviteInfo.recentCommissionAmount) }}</h5>
        <p>
          {{ $t('invite.NewInvites') }} 
          <strong v-if="effectiveCount <= inviteInfo.recentInviteCount">+{{effectiveCount}}</strong>
          <strong v-else>+{{inviteInfo.recentEffectiveCount}}</strong>
        </p>
        <div class="c666">{{ $t('invite.Last30Days') }}</div>
      </div>
      <div class="btn">
        <nut-button type="primary" @click="showRound = true">{{ $t('invite.EarnMoreNow') }}</nut-button>
        
      </div>
    </nut-popup>


      <nut-popup position="bottom" closeable round style="height: '53%';z-index:3000" v-model:visible="showRound">
        <div class="copy-link">
          <h4>{{$t('invite.h4')}}</h4>
          <strong class="ft30">{{ inviteInfo.inviteCode }}</strong>
          <p>{{$t('invite.h4Title')}}</p>
          <nut-button block type="info" @click="copyLink(inviteInfo.inviteCode)">{{$t('invite.btn')}}</nut-button>
          <p>{{$t('invite.to')}}</p>
        </div>

        <div class="copy-link-dl">
          <dl>
            <ShareNetwork v-for="network in networks" :key="network.network"
                :network="network.network"
                :url="sharing.url"
                :title="sharing.title"
                :description="sharing.description"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
                :twitterUser="sharing.twitterUser"
              >
              <i class="fab fah fa-lg fa-twitter"></i>
              <dd :class="network.class"><span>{{ network.name }}</span></dd>
            </ShareNetwork>
          </dl>
        </div>
      </nut-popup>
    </div>

    <!--**************************************** 活动弹框 ****************************************-->
    <nut-popup pop-class="pop-ab" v-model:visible="abPopup">
      <div class="bd" >

        <nut-swiper v-if="abPopupIN && startShow" :init-page="1" :pagination-visible="true"  pagination-color="#426543" auto-play="6000" :is-preventDefault="false">
          <nut-swiper-item @click="toAgentSupportPlan">
            <img v-if="s === 's1'" :src="require('../assets/images/in/EN/S1/bg902.jpg')" />
            <!--<img v-else :src="require('../assets/images/in/EN/S2/bg902.jpg')" /> -->
          </nut-swiper-item>
        </nut-swiper>
        
      </div>
      <nut-button type="primary" @click="abPopup = false">{{ $t('game.gotitBtn') }}</nut-button>
    </nut-popup>



    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted,getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
import axios from "axios";
import {Dialog, Toast} from "@nutui/nutui";
import { getCountry } from "@/assets/js/environment";

export default {
  components: {
    tabbar: TabbarMain,
  },
  data() {
    return {
      country: '',
      template: '',
      s: '',
      imgUrl: '',
      dateMonth: '6', //月
      startDay: '1',
      endDay: '2',
      startShow: false,
      abPopup: false,
      abPopupIN: false,
      sharing: {
        url: this.$t('invite.url'),
        title: this.$t('invite.title'),
        description: this.$t('invite.description'),
        quote: this.$t('invite.quote'),
        hashtags: this.$t('invite.hashtags'),
        twitterUser: this.$t('invite.twitterUser')
      },
      i18n:{
        loading: this.$t('global.loading'),
        copied: this.$t('global.Your_invitation_link_has_been_copied'), 
      }
    };
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.template = document.querySelector('body').getAttribute('s')
    this.s = document.querySelector('body').getAttribute('s');
    const d = new Date()
    this.startShow = ((d.getMonth() +1) >= this.dateMonth) && (d.getDate() >= this.startDay) && (d.getDate() < this.endDay)

    this.country = getCountry()
    switch (this.country) {
      case 'in':
        if(this.s === 's1'){
          this.abPopupIN = false
        }else if(this.s === 's2'){
          this.abPopupIN = false
        }
        this.abPopup = (this.abPopupIN && this.startShow)  ? true : false
        break;
    }
  },
  methods: {
    infoToast(msg){
      Dialog({
        content: msg,
        okText: this.$t('global.okText'),
        noCancelBtn: true
      });
    },
    
  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      tabActive:true,
      showRound: false,
      rewardPopup: false,
      domShow:false,
      tabbarActiveIndex: 1,
      effectiveCount: "",
      registerReward:'',
      dailyShareBonusCode:'',
      asList:[],
      inviteInfo: {
        availableAmount: "",
        inviteCode: "",
        inviteCount: "",
        inviteeRewardAmount: "",
        maxInviteRewardAmount: "",
        vipLevel: "",
        recentInviteCount: 0,
        recentEffectiveCount: 0,
        recentCommissionAmount: 10,
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', class: 'i1' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', class: 'i2' },
        { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', class: 'i3' },
        { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', class: 'i4' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', class: 'i5' }
      ]
    });
    const router = useRouter();
    const iconClick = () => {
      router.push({
        path: "transactionsHistoryList",
        query: {
          listType: 'cash'
        }
      })
    };
    
    const tabToggle = (cls) => {
      state.tabActive = cls === 'active' ? true : false
    };
    const withdrawClick = (t) => {
      router.push({
        path: 'withdrawalBalance',
				query: {
					amountType: t
				}
			})
    };
    const growthPlan = () => {
      router.push({
        name: "agentGrowth",
      });
    };
    const transferClick = () => {
        router.push({
          name: "cashTransfer",
        });
    };
    const toAgentSupportPlan = () => {
        router.push({
          name: "agentSupportPlan",
        });
    };

    const copyLink = () => {
      var cInput = document.createElement("input")
      cInput.value = _data.data.sharing.url
      document.body.appendChild(cInput)
      cInput.select()
      document.execCommand("copy")
      Toast.success(_data.data.i18n.copied)
      document.body.removeChild(cInput)
    };
    const methods = {
      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      toTelegram(){
        window.open(state.commonInfo.telegramChannelUrl,"_self");
      },
      shareBonusTask(){
        router.push("/receiveBonus?c="+state.dailyShareBonusCode);
      }
    };

    onMounted(() => {
      methods.textToast(_data.data.i18n.loading);
      axios
        .get("/api/invite", {})
        .then(function (response) {
          state.asList = response.data.data.affinityStatisticsList;
          state.inviteInfo.availableAmount =response.data.data.fundAccount.availableAmount;
          state.inviteInfo.inviteCode = response.data.data.inviteCode;
          state.inviteInfo.inviteCount = response.data.data.inviteCount;
          state.inviteInfo.inviteeRewardAmount = response.data.data.inviteeRewardAmount;
          state.inviteInfo.maxInviteRewardAmount = response.data.data.maxInviteRewardAmount;
          state.inviteInfo.vipLevel = response.data.data.vipLevel;
          state.effectiveCount = response.data.data.effectiveCount

          state.dailyShareBonusCode = response.data.data.dailyShareBonusCode

          Toast.hide("loading");
          state.domShow = true
          state.registerReward = state.commonInfo.registerReward;
          _data.data.sharing.url = state.commonInfo.frontBaseUrl + '?invite=' + state.inviteInfo.inviteCode
          state.inviteInfo.recentInviteCount = response.data.data.recentInviteCount
          state.inviteInfo.recentEffectiveCount = response.data.data.recentEffectiveCount
          state.inviteInfo.recentCommissionAmount = response.data.data.recentCommissionAmount
          //设置近期奖励显示
          if(parseInt(state.inviteInfo.recentCommissionAmount) > 0){
            const d = new Date()
            if(localStorage.getItem('recentCommissionAmountPopup') === null){
              state.rewardPopup = true
              localStorage.setItem("recentCommissionAmountPopup", d.getDate().toString());
            }else if(localStorage.getItem('recentCommissionAmountPopup') === d.getDate().toString()){
              state.rewardPopup = false
            }else{
              state.rewardPopup = true
              localStorage.setItem("recentCommissionAmountPopup", d.getDate().toString());
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
        
    });
    return {
      ...toRefs(state),
      ...methods,
      iconClick,
      tabToggle,
      withdrawClick,
      growthPlan,
      copyLink,
      transferClick,
      toAgentSupportPlan,
      
    };
  },
};
</script>

<style scoped>

.youTitle{ padding: 0px 10px; color: #e65c00; text-align: center;}
.img100{ width:96%; margin:0 2%;  }
.agent-table h4{ margin-top:0;}
.tab-hd{ height: 34px;position: relative; z-index: 105;}
.tab-hd li{ float: left;height: 30px; line-height: 30px; padding: 0 30px;border: 1px solid #ccc; border-top: 5px solid #ccc; border-bottom: 0;border-radius: 5px 20px 0 0; margin-right:3px;}
.tab-hd li.r{float: right;padding: 0px;border: 0px;border-radius:0;}
.tab-hd li.active{ border: 1px solid #0a115c; border-top: 5px solid #0a115c;  border-bottom: 0; height: 31px; background: #fff; font-weight: 600;  }
.tab-hd li span{ font-size: 14px; color: #333;}
.tab-hd li.active span{ color: #0a115c;}
.tab-bd {position: relative; z-index: 103; 
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1); padding-bottom:10px;}
.tab-bd h2{ text-align: center;  padding: 10px 0; 
  background: url(../assets/images/20230601/img21.png) left top no-repeat; background-size: 800px auto; 
  border-radius: 3px; margin: 0 3px 5px 3px; color: #fff; height: 95px;}
  .tab-bd h2 span{ padding-top: 52px; display: block;font-size: 15px;line-height: 18px;
    text-shadow: 0 1px #7e3e00, 1px 0  #7e3e00, -1px 0  #7e3e00, 0 -1px #7e3e00; }
  .tab-bd h2 strong{ color: #fff; width: 100px; text-align: center; display: inline-block; border-radius:15px; font-size: 14px;
     height: 24px; line-height: 24px; background: #d76832; margin-top:3px;}
.tab-bd-img{ display: flex; height: 70px; }
.tab-bd-img dl{ height: 60px; font-size: 14px; width: 25%;margin: 0 2px; border-radius: 3px; text-align: center; color: #fff;}
.tab-bd-img dl dt{ padding-top: 8px;}
.tab-bd-img dd span{ margin-right: 2px;font-size: 12px;}
.tab-bd-img dd strong{ color: #fcff00;}
.tab-bd-img dl:nth-child(1){ background: #09689f;}
.tab-bd-img dl:nth-child(2){ background: #222eab;}
.tab-bd-img dl:nth-child(3){ background: #010856;}
.tab-bd-img dl:nth-child(4){ background: #271300;}

.tab-bd-p{border-radius: 5px; margin: 10px 5px; padding:5px 10px;
  background:#e5e7ff }
.tab-bd-p h5 { margin: 5px 0; padding-left:20px; height: 20px; line-height: 20px;
  background: url(../assets/images/20230601/bg47.png) 0px center no-repeat;background-size: 16px;}
.tab-bd-p p{ margin: 8px 0;line-height: 16px; opacity:.8;}
.agent-main { margin-top: 40px;}
.nut-navbar, .nut-navbar-b0, .nut-navbar-b0 .nut-navbar {
  box-shadow: 0 0 0 !important;
}
.agent-top {
  margin-top: -1px;
  padding: 10px;
  background: url(../assets/images/bg05.png) 0 -100px no-repeat;
  background-size: 100% 325px;
}
.agent-info {
  height: 160px;
  position: relative;
  z-index: 2002;
  color: #e6deff;
  z-index: 320;
  text-align: center;
}
.agent-info .agent-lv {
  position: absolute;
  top: 0px;
  left: 10px;
  width: 60px;
  height: 60px;
  text-align: center;
  background: url(../assets/images/bg17-2.png) center center no-repeat;
  background-size: 60px;
}
.agent-info .agent-lv strong {
  line-height: 50px;
  display: block;
  padding-top: 3px;
  font-size: 26px;
  color: #581600;
  margin-bottom: 15px;
}
.agent-info .agent-lv span {
  font-size: 16px;
}
.agent-info .nut-button--plain.nut-button--info {
  color: #1e246d !important;
  background: #e6deff !important;
  border-color: #1e246d !important;
  margin-left: 5px;
  vertical-align: middle;
  font-weight: 300;
  font-size: 10px;
  padding: 0 10px;
  min-width: 100px;
}
.agent-info-l strong {
  line-height: 40px;
  display: block;
  font-size: 22px;
  font-weight: 400;
  color: #ffba14;
}
.flbd{height: 42px;background: #262c82 url(../assets/images/20230601/img10.png) center 240px; background-size: 160%;
   padding: 10px; display: flex; border-radius:10px; margin-top: 8px;}
.flbd .fl { width: 50%;
}
.flbd .fl strong {
  font-size: 16px;
}
.agent-vip {
  background: url(../assets/images/20230601/img18.png) 0 0 no-repeat; background-size: 100% auto;
  height: 55px;
  text-align: center;
  padding: 0;
}
.agent-vip strong{color: #fff68f; display: block; font-size: 20px; line-height: 20px; padding-top: 10px;}
.agent-vip span{color: #fff;font-size: 16px; line-height: 20px;}

.shares-btn {
  padding: 10px;
  position: relative;
  padding-bottom: 80px;
  text-align: center; 
}
.shares-btn .icon {
  position: absolute;
  background: url(../assets/images/bg11.png) no-repeat;
  width: 60px;
  height: 60px;
  z-index: 99;
  left: 10px;
  top: 0px;
}
.shares-btn p {
  padding: 0 10px 10px 10px;
  margin: 0;
  color: #666;
}
.r-tx{ float: right; color: #ccc !important; font-weight: 300 !important;}
.agent-table {
  margin: 0 10px 10px 10px;
  overflow: hidden;
  position: relative;
  
}
.agent-table h4 {
  margin-bottom: 5px;
  color: #333;
}
.agent-table table {
  width: 100%;
  border:3px solid #080e50;
}
.agent-table table th {
  border: 1px #fff solid;
  background: #080e50;
  line-height: 16px;
  height: 25px;
  padding: 0 5px;
  font-weight: 400;
  color: #fff; text-align: left; width: 150px;

}

.agent-table table th.title{ background: #222eab; color: #ffe1a1;border: 1px #222eab solid; text-align: center; font-size: 14px;}
.agent-table table td {
  border: 1px #fff solid;
  line-height: 16px;
  height: 25px;
  padding: 0 5px;
  text-align: center;
  background: #eaecff;
}

.agent-table .table-th{
  position: absolute;
  width: calc(100% - 5px);
  left:3px;
  z-index: 100;
}
.agent-table .table-th th{
  width: 84px;
}
.agent-table .table-td{
  position: relative;
  z-index: 110;
  margin-left: 99px;
  overflow-x:scroll;
  overflow-y: hidden;
}
.agent-table .table-td table{
  width: auto;
}
.agent-table .table-td td{ min-width:45px;}
.copy-link {
  padding: 20px;
  padding-bottom: 0;
  text-align: center;
  background: #fff url(../assets/images/20230601/img10.png) right top no-repeat !important; background-size: 150% auto !important;
}
.copy-link h4 {
  margin: 5px 0 30px 0;
  font-weight: 300;
}
.copy-link .ft30 {
  font-size: 40px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 10px;
  background: #1d2047;
  color: #fff154;
  letter-spacing:4px; 
}
.copy-link p {
  margin: 20px 0;
  color: #783b12;
}
.copy-link-dl dl {
  margin-top: 0px;
}
.copy-link-dl dl {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.copy-link-dl dl dd {
  align-items: flex-start;
  text-align: center;
  margin: 5px;
  font-size: 10px;
  padding-top: 50px;
  color: #666;
  text-decoration:none;
}
.copy-link-dl dl dd.i1 {
  background: url(../assets/images/bg21.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i2 {
  background: url(../assets/images/bg22.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i3 {
  background: url(../assets/images/bg23.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i4 {
  background: url(../assets/images/bg24.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i5 {
  background: url(../assets/images/bg25.png) center top no-repeat;
  background-size: 40px 40px;
}
.copy-link-dl dl dd.i6 {
  background: url(../assets/images/bg26.png) center top no-repeat;
  background-size: 40px 40px;
}

.agent-lk{ display: flex;}
.agent-lk div img{ width: 100%;border-radius:10px;}
.agent-lk .left, .agent-lk .right{text-align: center;width: calc(50% - 15px); margin-left: 10px;  overflow: hidden;}

</style>

<style>
.pop-ab {
  padding: 0px !important;
  width: 100% !important;
  min-height: 100px;
  top: 50%;
  border-radius: 0px;
  background: transparent !important;  text-align: center;overflow-y: hidden !important;
}
.pop-ab .nut-button--round{height: 30px !important; margin-top:5px;}
.pop-ab .bd{ margin: 0; padding: 3px 0px !important; background: #000;}
.pop-ab img{ width: 100% !important; height: auto; margin: 0; max-width: 400px !important;}
.pop-ab .nut-swiper-inner{ height: auto !important; }

.pop-reward{ height: 426px;  width: 330px;text-align: center;
  background:transparent url(../assets/images/in/EN/S1/bg300.png) 0px 0px no-repeat !important ;background-size: 100% auto !important;}
.in .pop-reward{background:transparent url(../assets/images/in/EN/S1/bg300.png) 0px 0px no-repeat !important ;background-size: 100% auto !important;}
.pop-reward .hd{height: 172px;}
.pop-reward .bd {height: 180px;}
.pop-reward .bd h4{ margin: 0; line-height: 40px; font-size: 18px;color: #000;}
.pop-reward .bd h5{ margin: 5px 0 10px 0;line-height: 50px;font-size: 40px; color: #ff8a00; font-weight: 600;}
.pop-reward .bd p{ margin: 8px 0; line-height: 20px;font-size: 16px;color: #333;}
.pop-reward .bd p strong{font-size: 18px;color: #ff8a00; margin-right: 5px;}
.pop-reward .nut-button--primary{ background: none !important; color: #fff; font-weight: 600;font-size: 16px;}
.pop-reward .c666{ margin: 30px 0 0 0; color: #666; opacity: .5;font-size: 12px;}
</style>
